import { cloneDeep } from "lodash-es";
import * as React from "react";
import Utils from "./utils";

import { InjectedIntlProps, injectIntl } from "react-intl";
import "./projects-messages";

import authService from "../../services/API/auth-service";
import { IProject, IProjectTree, ProjectStatus } from "../../store/projects/types";

import { Alert, Button, Col, Divider, Dropdown, Icon, Menu, PageHeader, Popconfirm, Row, Tooltip } from "antd";
import { RouteComponentProps, withRouter } from "react-router";
import projectService from "../../services/API/project-service";

interface IOwnProps {
    project?: IProject;
    tree?: IProjectTree;
    loading: boolean;
    calculationOngoing: boolean;
    editSinceLoad: boolean;

    onRecalculateButtonClick: (fast: boolean) => void;
    onEditProjectButtonClick: () => void;
    onDeleteProjectButtonClick: () => void;
    onRecoverProjectButtonClick: () => void;
    onDownloadZipButtonClick: () => void;
}

type AllProps = IOwnProps & RouteComponentProps<{}> & InjectedIntlProps;

interface IState {
    moreMenuVisible: boolean;
}

class ProjectHeader extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            moreMenuVisible: false
        };
    }

    render() {
        const {
            project,
            onRecalculateButtonClick,
            onEditProjectButtonClick,
            onDeleteProjectButtonClick,
            onRecoverProjectButtonClick,
            onDownloadZipButtonClick
        } = this.props;

        if (!project) {
            return <div />;
        }

        const labelStyle: React.CSSProperties = {
            textAlign: "left",
            fontWeight: 500,
            paddingLeft: "20px",
            paddingRight: "5px"
        };

        const statusDone = project.status === ProjectStatus.Done && !this.props.editSinceLoad;
        const calculationTextColor = statusDone ? "#309000" : "#fba700";
        const calculationIcon = statusDone ? "check" : "exclamation-circle";
        const calculateFastButtonTooltip = this.props.intl.formatMessage({
            id:
                project.status === ProjectStatus.Calculating
                    ? "project.calculate.ongoing"
                    : statusDone
                        ? "project.calculate.unnecessary"
                        : "project.calculateFast"
        });

        const calculationFailedBox = project.errorMessage && (
            <Alert
                message={this.props.intl.formatMessage({ id: "project.error" })}
                description={project.errorMessage}
                type="error"
                showIcon
                closable
                onClose={this.onErrorClose.bind(this)}
            />
        );

        const content = (
            <div style={{ display: "block" }}>
                <div style={{ float: "left", paddingLeft: "20px" }}>
                    <div style={{ float: "left" }}>
                        <Col>
                            <Row style={labelStyle}>{this.props.intl.formatMessage({ id: "project.age" })}:</Row>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({ id: "project.maxCalculationAge" })}:
                            </Row>
                            <Row style={labelStyle}>
                                <span>
                                    {this.props.intl.formatMessage({ id: "project.failureProbability" })}
                                    {" P"}
                                    <sub>f crit CO₂</sub>
                                </span>:
                            </Row>
                            <Row style={labelStyle} />
                        </Col>
                    </div>
                    <div style={{ float: "left" }}>
                        <Col>
                            <Row>
                                {Utils.isDecimal(project.age)
                                    ? Utils.formatNumberUnit(
                                          Math.round(project.age * 365),
                                          this.props.intl.formatMessage({
                                              id: "days"
                                          })
                                      )
                                    : Utils.formatNumberUnit(
                                          project.age,
                                          this.props.intl.formatMessage({
                                              id: "years"
                                          })
                                      )}
                            </Row>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.maxCalculationAge || 100,
                                    this.props.intl.formatMessage({
                                        id: "years"
                                    })
                                )}
                            </Row>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.failureProbability,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    }),
                                    100
                                )}
                            </Row>
                            <Row />
                        </Col>
                    </div>
                    <div style={{ float: "left" }}>
                        <Col>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({ id: "project.coverdepthThreshold" })}:
                            </Row>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({ id: "project.coverdepthQualityThreshold" })}:
                            </Row>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({ id: "project.coverdepthOverlay" })}:
                            </Row>
                            <Row style={labelStyle} />
                        </Col>
                    </div>
                    <div style={{ float: "left" }}>
                        <Col>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.coverdepthThreshold,
                                    this.props.intl.formatMessage({
                                        id: "mm"
                                    })
                                )}
                            </Row>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.coverdepthQualityThreshold,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    })
                                )}
                            </Row>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.coverdepthOverlay,
                                    this.props.intl.formatMessage({
                                        id: "mm"
                                    })
                                )}
                            </Row>
                            <Row />
                        </Col>
                    </div>
                    <div style={{ float: "left" }}>
                        <Col>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({
                                    id:
                                        project.chloridesBetaStd === 0
                                            ? "project.chloridesThreshold"
                                            : "project.chloridesBeta"
                                })}:
                            </Row>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({ id: "project.chloridesBetaStd" })}:
                            </Row>
                            <Row style={labelStyle}>
                                {this.props.intl.formatMessage({ id: "project.chloridesAgingFactor" })}:
                            </Row>
                            <Row style={labelStyle}>
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: "project.failureProbabilityChlorides"
                                    })}
                                    {" P"}
                                    <sub>f crit Cl</sub>
                                </span>:
                            </Row>
                        </Col>
                    </div>
                    <div style={{ float: "left" }}>
                        <Col>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.chloridesThreshold,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    })
                                )}
                            </Row>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.chloridesBetaStd,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    })
                                )}
                            </Row>
                            <Row>{project.chloridesAgingFactor}</Row>
                            <Row>
                                {Utils.formatNumberUnit(
                                    project.failureProbabilityChlorides || 0.1,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    }),
                                    100
                                )}
                            </Row>
                        </Col>
                    </div>
                </div>
                <div style={{ float: "right", paddingRight: "30px" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td style={labelStyle}>
                                    {this.props.intl.formatMessage({ id: "project.createdAt" })}:
                                </td>
                                <td>
                                    {this.props.intl.formatDate(project.createdAt) +
                                        " " +
                                        this.props.intl.formatTime(project.createdAt)}
                                </td>
                                <td style={labelStyle}>{this.props.intl.formatMessage({ id: "project.by" })}</td>
                                <td>{project.created_by}</td>
                            </tr>
                            <tr />
                            <tr style={{ verticalAlign: "top" }}>
                                <td style={labelStyle}>
                                    {this.props.intl.formatMessage({ id: "project.updatedAt" })}:
                                </td>
                                <td>
                                    {this.props.intl.formatDate(project.updatedAt) +
                                        " " +
                                        this.props.intl.formatTime(project.updatedAt)}
                                </td>
                                <td style={labelStyle}>{this.props.intl.formatMessage({ id: "project.by" })}</td>
                                <td>{project.updated_by}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );

        const getProjectCalculationProgress = () => {
            if (project && project.calculationProgress !== undefined && project.calculationSteps !== undefined) {
                return " (" + project.calculationProgress + "/" + project.calculationSteps + ")";
            }

            return "";
        };

        const calculateButton = (
            <span key="calculateButtons">
                <Tooltip mouseEnterDelay={0.8} title={calculateFastButtonTooltip} key="tooltipCalculateFast">
                    <Button
                        icon={calculationIcon}
                        style={{ color: calculationTextColor, borderColor: calculationTextColor, marginLeft: "8px" }}
                        onClick={() => onRecalculateButtonClick(true)}
                        loading={this.props.calculationOngoing}
                        key="tooltipCalculateFastButton"
                        disabled={this.props.loading}
                    >
                        {this.props.calculationOngoing
                            ? this.props.intl.formatMessage({
                                  id: "project.details.calculationOngoing"
                              })
                            : this.props.intl.formatMessage({ id: "project.details.recalculateFast" })}
                    </Button>
                </Tooltip>
            </span>
        );

        const editButton = (
            <Button icon="edit" onClick={onEditProjectButtonClick} key="editButton" disabled={this.props.loading}>
                {this.props.intl.formatMessage({ id: "project.details.edit" })}
            </Button>
        );

        const deleteButtonConfirm = (
            <Popconfirm
                icon={<Icon style={{ color: "#a94442" }} type="warning" key="DeleteIcon" />}
                title={this.props.intl.formatMessage({ id: "project.delete.confirm" })}
                onConfirm={() => {
                    this.showMoreMenu(false);
                    onDeleteProjectButtonClick();
                }}
                okText={this.props.intl.formatMessage({
                    id: "yes"
                })}
                cancelText={this.props.intl.formatMessage({ id: "no" })}
                key="deletePopConfirm"
                placement="top"
            >
                <div>
                    <Icon type="delete" /> {this.props.intl.formatMessage({ id: "project.delete" })}
                </div>
            </Popconfirm>
        );

        const recoverButton = (
            <Tooltip title={this.props.intl.formatMessage({ id: "project.recover.tooltip" })} key="tooltipRecover">
                <Popconfirm
                    icon={<Icon style={{ color: "#a94442" }} type="warning" key="RecoverIcon" />}
                    title={this.props.intl.formatMessage({ id: "project.recover.confirm" })}
                    onConfirm={() => {
                        this.showMoreMenu(false);
                        onRecoverProjectButtonClick();
                    }}
                    okText={this.props.intl.formatMessage({
                        id: "yes"
                    })}
                    cancelText={this.props.intl.formatMessage({ id: "no" })}
                    key="recoverPopConfirm"
                    placement="bottom"
                >
                    <div>
                        <Icon type="issues-close" /> {this.props.intl.formatMessage({ id: "project.recover" })}
                    </div>
                </Popconfirm>
            </Tooltip>
        );

        const downloadGraphZipButton = (
            <div key="downloadZipButton" onClick={onDownloadZipButtonClick}>
                <Icon type="download" /> {this.props.intl.formatMessage({ id: "project.details.downloadZip" })}
            </div>
        );

        const moreMenu = (
            <Menu key="moreMenu">
                <Menu.Item key="deleteMenu">{deleteButtonConfirm}</Menu.Item>
                <Menu.Item key="recoverMenu">{recoverButton}</Menu.Item>
                <Menu.Item
                    key="downloadMenu"
                    disabled={
                        !(
                            this.props.project &&
                            this.props.project.status === ProjectStatus.Done &&
                            this.props.tree &&
                            this.props.tree.archiveURL
                        )
                    }
                >
                    {downloadGraphZipButton}
                </Menu.Item>
            </Menu>
        );

        const dropdownMoreMenu = (
            <Dropdown overlay={moreMenu} trigger={[]} visible={this.state.moreMenuVisible} key="moreMenu">
                <Button icon={"down"} onClick={this.onMoreButtonClick.bind(this)} key="moreDownButton">
                    {this.props.intl.formatMessage({ id: "project.more" })}
                </Button>
            </Dropdown>
        );

        return (
            <div>
                <PageHeader
                    onBack={() => {
                        this.props.history.push("/projects");
                    }}
                    title={project.name}
                    subTitle={`${project.description} | ${project.customer} | ${project.location}`}
                    style={{ padding: "8px 0px 0px 0px" }}
                    extra={[calculateButton, editButton, authService.hasScope("delete:projects") && dropdownMoreMenu]}
                >
                    <div className="wrap">
                        <div>{calculationFailedBox}</div>
                        <div className="content padding">{content}</div>
                    </div>
                </PageHeader>
                <Divider style={{ display: "inline-block", margin: "4px 0 0 0" }} />
            </div>
        );
    }

    onErrorClose(e: React.MouseEvent) {
        if (!this.props.project) {
            return;
        }
        const project = cloneDeep(this.props.project);

        project.errorMessage = "";
        projectService.editProject(project);
    }

    showMoreMenu(show: boolean) {
        this.setState({
            ...this.state,
            moreMenuVisible: show
        });
    }

    onMoreButtonClick(e: (React.MouseEvent<HTMLElement>) | undefined) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.showMoreMenu(!this.state.moreMenuVisible);
    }
}

export default withRouter(injectIntl(ProjectHeader));
