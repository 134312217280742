import { some } from "lodash-es";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./carbonatation-messages";
import "./node-messages";
import "./projects-messages";
import Utils from "./utils";

import { Alert, Button, Dropdown, Empty, Icon, Input, Menu, Table, Tag, Tooltip, notification } from "antd";
import ConfigProvider from "antd/es/config-provider";
import { FilterDropdownProps } from "antd/lib/table";
import {
    CalculationResult,
    ICarbonatationMeasurement,
    IMeasurement,
    INode,
    IProject,
    IPropagatedValue,
    NodeStatus,
    ProjectStatus
} from "../../store/projects/types";
interface IState {
    error?: string;
    loading: boolean;
    visibleColumns: Set<string>;
    nameFilterText: string;
}
interface IOwnProps {
    project?: IProject;
    currentNode: INode;
    expandedNodes: string[];
}

type AllProps = IOwnProps & InjectedIntlProps;
interface IRowEntry {
    name: string;
    namePath: string[];
    numberOfRepititions: number[];
    count: number;
    min: number;
    max: number;
    average: number;
    standardDeviation: number;
    failureProbability: number;
    failureProbabilityChlorides: number;
    calculatedChlorideFailureProbability?: number;
    calculatedCarbonatationFailureProbability?: number;
    calculatedLifetimeExpectancy?: number;
    D?: number;
    Cs?: number;
    status?: NodeStatus;
}

interface IPreparedData {
    flattenedNodes: any[];
    maxDepth: number;
}

interface IFlatNode {
    name: string;
    namePath: string[];
    nodePath: string[];
    uuid: string;
    propagatedAge?: IPropagatedValue;
    numberOfRepititions: number[];
    nodeStatistics?: IMeasurement;
    carbonatationStatistics?: ICarbonatationMeasurement;
    failureProbability: number;
    failureProbabilityChlorides: number;
    calculatedCarbonatationFailureProbability?: number;
    calculatedChlorideFailureProbability?: number;
    calculatedLifetimeExpectancy?: number;
    calculatedChlorideLifetimeExpectancy?: number;
    carbonationCalculationResultMessage?: CalculationResult;
    chlorideCalculationResultMessage?: CalculationResult;
    Cs?: number;
    D?: number;
    status?: NodeStatus;
}

class Overview extends React.PureComponent<AllProps, IState> {
    nameFilterRef = React.createRef<Input>();

    // Dataindex is used for visible columns
    availableColumns = [
        {
            title: this.props.intl.formatMessage({
                id: "node.concreteCover"
            }),
            className: "overview-table-header-cell-first",
            children: [
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.count"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "node.count.short"
                            })}
                            <sub>CC</sub>
                        </Tooltip>
                    ),
                    dataIndex: "count",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.count"
                    })
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.min"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "node.min.short"
                            })}
                            <sub>CC </sub>
                            {this.props.intl.formatMessage({
                                id: "node.unit.mm"
                            })}
                        </Tooltip>
                    ),
                    dataIndex: "min",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.min"
                    })
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.max"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "node.max.short"
                            })}
                            <sub>CC </sub>
                            {this.props.intl.formatMessage({
                                id: "node.unit.mm"
                            })}
                        </Tooltip>
                    ),
                    dataIndex: "max",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.max"
                    })
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.avg"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "node.avg.short"
                            })}
                            <sub>CC </sub>
                            {this.props.intl.formatMessage({
                                id: "node.unit.mm"
                            })}
                        </Tooltip>
                    ),
                    dataIndex: "average",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.avg"
                    }),
                    render: (value: string | number) => {
                        return {
                            props: {
                                style: {
                                    backgroundColor: "lightgrey"
                                }
                            },
                            children: (
                                <span>
                                    <b>{value}</b>
                                </span>
                            )
                        };
                    }
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.sd"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "node.sd.short"
                            })}
                            <sub>CC </sub>
                            {this.props.intl.formatMessage({
                                id: "node.unit.mm"
                            })}
                        </Tooltip>
                    ),
                    dataIndex: "standardDeviation",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.sd"
                    })
                }
            ]
        },
        {
            title: this.props.intl.formatMessage({
                id: "node.carbonatation"
            }),
            className: "overview-table-header-cell-first",
            children: [
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.pf"
                            })}
                        >
                            P<sub>f crit CO₂</sub> [%]
                        </Tooltip>
                    ),
                    dataIndex: "failureProbability",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.pf"
                    }),
                    className: "overview-table-cell-failure-probability"
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "carbonatation.avg"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "carbonatation.avg.short"
                            })}
                            <sub>CO₂ </sub>
                            {this.props.intl.formatMessage({
                                id: "carbonatation.unit"
                            })}
                        </Tooltip>
                    ),
                    dataIndex: "carbAverage",
                    checkboxName: this.props.intl.formatMessage({
                        id: "carbonatation.avg"
                    }),
                    render: (value: string | number) => {
                        return {
                            props: {
                                style: {
                                    backgroundColor: "lightgrey"
                                }
                            },
                            children: (
                                <span>
                                    <b>{value}</b>
                                </span>
                            )
                        };
                    }
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "carbonatation.sd"
                            })}
                        >
                            {this.props.intl.formatMessage({
                                id: "carbonatation.sd.short"
                            })}
                            <sub>CO₂ </sub>
                            {this.props.intl.formatMessage({
                                id: "carbonatation.unit"
                            })}
                        </Tooltip>
                    ),
                    dataIndex: "carbSd",
                    checkboxName: this.props.intl.formatMessage({
                        id: "carbonatation.sd"
                    })
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.calculatedFailureProbabilityCarbonation"
                            })}
                        >
                            P<sub>f CO₂</sub> [%]
                        </Tooltip>
                    ),
                    dataIndex: "calculatedCarbonatationFailureProbability",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.calculatedFailureProbabilityCarbonation"
                    }),
                    render: (value: string | number) => {
                        return {
                            props: {
                                style: {
                                    backgroundColor: this.getBackgroundColor(value)
                                }
                            },
                            children: this.renderCellByTypeAndBackgroundColor(value)
                        };
                    }
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.calculatedLifetimeExpectancyCarbonation"
                            })}
                        >
                            t<sub>SL,CO₂</sub> {this.props.intl.formatMessage({ id: "node.unit.year" })}
                        </Tooltip>
                    ),
                    dataIndex: "calculatedLifetimeExpectancy",
                    checkboxName: this.props.intl.formatMessage({ id: "node.calculatedLifetimeExpectancyCarbonation" }),
                    render: (value: string | number) => {
                        return this.renderCellByType(value);
                    }
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.calculatedRemainingLifetimeExpectancyCarbonation"
                            })}
                        >
                            t<sub>SL,REM,CO₂</sub> {this.props.intl.formatMessage({ id: "node.unit.year" })}
                        </Tooltip>
                    ),
                    dataIndex: "calculatedRemainingLifetimeExpectancy",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.calculatedRemainingLifetimeExpectancyCarbonation"
                    }),
                    render: (value: string | number) => {
                        return this.renderCellByType(value);
                    }
                }
            ]
        },
        {
            title: this.props.intl.formatMessage({
                id: "node.chlorides"
            }),
            className: "overview-table-header-cell-first",
            children: [
                {
                    title: (
                        <Tooltip title={this.props.intl.formatMessage({ id: "node.pfChlorides" })}>
                            P<sub>f crit Cl</sub> [%]
                        </Tooltip>
                    ),
                    dataIndex: "failureProbabilityChlorides",
                    checkboxName: this.props.intl.formatMessage({ id: "node.pfChlorides" })
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({ id: "node.calculatedFailureProbabilityChlorides" })}
                        >
                            P<sub>f Cl</sub> [%]
                        </Tooltip>
                    ),
                    dataIndex: "calculatedChlorideFailureProbability",
                    checkboxName: this.props.intl.formatMessage({ id: "node.calculatedFailureProbabilityChlorides" }),
                    render: (value: string | number) => {
                        return {
                            props: {
                                style: {
                                    backgroundColor: this.getBackgroundColor(value)
                                }
                            },
                            children: this.renderCellByTypeAndBackgroundColor(value)
                        };
                    }
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({ id: "node.calculatedLifetimeExpectancyChlorides" })}
                        >
                            t<sub>SL,Cl</sub> {this.props.intl.formatMessage({ id: "node.unit.year" })}
                        </Tooltip>
                    ),
                    dataIndex: "calculatedChlorideLifetimeExpectancy",
                    checkboxName: this.props.intl.formatMessage({ id: "node.calculatedLifetimeExpectancyChlorides" }),
                    render: (value: string | number) => {
                        return this.renderCellByType(value);
                    }
                },
                {
                    title: (
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: "node.calculatedRemainingLifetimeExpectancyChlorides"
                            })}
                        >
                            t<sub>SL,REM,Cl</sub> {this.props.intl.formatMessage({ id: "node.unit.year" })}
                        </Tooltip>
                    ),
                    dataIndex: "calculatedRemainingChlorideLifetimeExpectancy",
                    checkboxName: this.props.intl.formatMessage({
                        id: "node.calculatedRemainingLifetimeExpectancyChlorides"
                    }),
                    render: (value: string | number) => {
                        return this.renderCellByType(value);
                    }
                },
                {
                    title: (
                        <Tooltip title={this.props.intl.formatMessage({ id: "node.d" })}>
                            D {this.props.intl.formatMessage({ id: "node.unit.d" })}
                        </Tooltip>
                    ),
                    dataIndex: "D",
                    checkboxName: this.props.intl.formatMessage({ id: "node.d" }),
                    render: (value: string | number) => {
                        return this.renderCellByType(value);
                    }
                },
                {
                    title: (
                        <Tooltip title={this.props.intl.formatMessage({ id: "node.cs" })}>
                            C<sub>s</sub> {this.props.intl.formatMessage({ id: "node.unit.cs" })}
                        </Tooltip>
                    ),
                    dataIndex: "Cs",
                    checkboxName: this.props.intl.formatMessage({ id: "node.cs" }),
                    render: (value: string | number) => {
                        return this.renderCellByType(value);
                    }
                }
            ]
        }
    ];

    constructor(props: AllProps) {
        super(props);
        this.state = {
            error: undefined,
            loading: false,
            visibleColumns: new Set([
                "name",
                "count",
                "min",
                "max",
                "average",
                "standardDeviation",
                "carbAverage",
                "carbSd",
                "calculatedCarbonatationFailureProbability",
                "calculatedChlorideFailureProbability",
                "calculatedLifetimeExpectancy",
                "calculatedRemainingLifetimeExpectancy",
                "calculatedChlorideLifetimeExpectancy",
                "calculatedRemainingChlorideLifetimeExpectancy"
            ]),
            nameFilterText: ""
        };
    }

    renderCellByType(value: string | number) {
        let style: React.CSSProperties = {};
        if (value === "-") {
            style = { color: "#bbb" };
        } else if (Number(value) < 0) {
            style = { color: "#f00" };
        }

        return <span style={style}>{value}</span>;
    }

    renderCellByTypeAndBackgroundColor(value: string | number) {
        let style: React.CSSProperties = {};
        if (value === "-") {
            style = { color: "#bbb" };
        } else if (Number(value) < 0) {
            style = { color: "#f00" };
        } else if (Number(value) > 50) {
            style = { color: "#fff" };
        }

        return <span style={style}>{value}</span>;
    }

    getBackgroundColor(value: string | number, override = false) {
        if (override) {
            return "rgb(200,200,200)";
        }
        if (typeof value === "string") {
            return "";
        } else if (Number(value) > 50) {
            return "rgb(255,0,0)"; // red
        } else if (Number(value) > 30) {
            return "rgb(255,192,0)"; // orange
        } else if (Number(value) > 10) {
            return "rgb(255,255,0)"; // yellow
        } else {
            return "rgb(146,208,80)"; // green
        }
    }

    flattenNodes(
        node: INode,
        flatList: IFlatNode[] = [],
        fullNamePath: string[] = [],
        fullNodePath: string[] = [],
        forExport: boolean
    ): IFlatNode[] {
        if (!node) {
            return [];
        }

        const nodeName = node.name;
        const newNamePath = [...fullNamePath, nodeName];

        const nodeId = node.uuid;
        const newFullNodePath = [...fullNodePath, nodeId];

        const flatNode = {
            name: nodeName,
            namePath: newNamePath,
            nodePath: newFullNodePath,
            uuid: node.uuid,
            propagatedAge: node.propagatedAge,
            numberOfRepititions: [],
            nodeStatistics: node.nodeStatistics,
            carbonatationStatistics: node.carbonatationStatistics,
            failureProbability: node.propagatedFailureProbability!.value,
            failureProbabilityChlorides: node.propagatedFailureProbabilityChlorides!.value,
            calculatedCarbonatationFailureProbability: node.calculatedCarbonatationFailureProbability,
            calculatedChlorideFailureProbability: node.calculatedChlorideFailureProbability,
            calculatedLifetimeExpectancy: node.calculatedLifetimeExpectancy,
            calculatedChlorideLifetimeExpectancy: node.calculatedChlorideLifetimeExpectancy,
            carbonationCalculationResultMessage: node.carbonationCalculationResultMessage,
            chlorideCalculationResultMessage: node.chlorideCalculationResultMessage,
            Cs: node.Cs,
            D: node.D,
            status: node.status
        };

        if (node === this.props.currentNode) {
            flatNode.namePath = [
                "‑‑‑" +
                    this.props.intl.formatMessage({
                        id: "overview.summaryLine"
                    }) +
                    "‑‑‑"
            ];
        }
        // console.log(this.props.expandedNodes, newFullNodePath);

        if (
            // ((node.files && node.files.length) || node.useManualInput || node === this.props.currentNode) &&
            (!this.state.nameFilterText ||
                some(flatNode.namePath, chunk =>
                    // maches filter
                    chunk.toLowerCase().includes(this.state.nameFilterText.toLowerCase())
                )) &&
            // parent is expanded
            ((newFullNodePath.length - 2 >= 0 &&
                this.props.expandedNodes.includes(newFullNodePath[newFullNodePath.length - 2])) ||
                node === this.props.currentNode)
        ) {
            // if there are files and the filter matches or it is the current node, add it to the list (if not for export and disabled)
            if (!forExport || node.status !== NodeStatus.DISABLED) {
                flatList.push(flatNode);
            }
        }

        for (const child of node.children) {
            this.flattenNodes(child, flatList, newNamePath, newFullNodePath, forExport);
        }

        return flatList;
    }

    countRepeatingPaths(nodes: any[], maxDepth: number): any[] {
        if (!nodes.length) {
            return nodes;
        }
        // keep track of which element was the first of a repeating list - we want to update this guy's counter if we find more duplicates
        const lastNewElementPerPath = Array(maxDepth).fill(0);
        // initialize the very first row
        nodes[0].numberOfRepititions = Array(maxDepth).fill(1);

        for (let i = 1; i < nodes.length; i++) {
            // as soon as one difference if found, also don't repeat following elements
            // e.g. el1/sub1, followed by el2/sub1 should NOT be a repitition
            let differenceFound = false;
            for (let j = 0; j < maxDepth; j++) {
                if (nodes[i].namePath[j] === nodes[i - 1].namePath[j] && !differenceFound) {
                    nodes[i].numberOfRepititions.push(0);
                    nodes[lastNewElementPerPath[j]].numberOfRepititions[j] += 1;
                } else {
                    nodes[i].numberOfRepititions.push(1);
                    lastNewElementPerPath[j] = i;
                    differenceFound = true;
                }
            }
        }

        return nodes;
    }

    customizeRenderEmpty = () => (
        <Empty
            image="https://img.icons8.com/clouds/500/000000/calculator.png"
            description={this.props.intl.formatMessage({
                id: "overview.calculationNeeded"
            })}
        />
    );

    getColumns = (preparedData: IPreparedData, forExport: boolean) => {
        const visibleColumns = this.state.visibleColumns;
        const maxDepth = preparedData.maxDepth;

        const columns = [];

        if (visibleColumns.has("name")) {
            for (let i = 0; i < maxDepth; i++) {
                let levelColumn;
                if (i === 0) {
                    levelColumn = {
                        title: (
                            <Tooltip
                                title={this.props.intl.formatMessage({
                                    id: "node.name"
                                })}
                            >
                                {this.props.intl.formatMessage({ id: "node.name.short" })}
                            </Tooltip>
                        ),
                        className: "overview-table-header-cell-name",
                        colSpan: maxDepth,
                        dataIndex: "path" + i,
                        render: (value: string[], row: IRowEntry) => {
                            const obj = {
                                children: row.namePath && i < row.namePath.length ? row.namePath[i] : "",
                                props: { rowSpan: row.numberOfRepititions[i] }
                            };

                            return obj;
                        },
                        filterDropdown: ({
                            setSelectedKeys,
                            selectedKeys,
                            confirm,
                            clearFilters
                        }: FilterDropdownProps) => (
                            <div style={{ padding: 8 }}>
                                <Input
                                    ref={this.nameFilterRef}
                                    placeholder={this.props.intl.formatMessage({
                                        id: "node.name.filter"
                                    })}
                                    value={selectedKeys ? selectedKeys[0] : ""}
                                    onChange={e => {
                                        if (setSelectedKeys) {
                                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                                        }
                                    }}
                                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                                    style={{ marginBottom: 8, display: "block" }}
                                />
                                <div style={{ display: "flex" }}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                                        icon="search"
                                        size="small"
                                        style={{ marginRight: 8, flexGrow: 1 }}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: "node.name.filter.confirm"
                                        })}
                                    </Button>
                                    <Button
                                        onClick={() => this.handleReset(clearFilters)}
                                        size="small"
                                        style={{ flexGrow: 1 }}
                                    >
                                        {this.props.intl.formatMessage({ id: "node.name.filter.reset" })}
                                    </Button>
                                </div>
                            </div>
                        ),
                        filterIcon: (filtered: boolean) => (
                            <Icon
                                type="search"
                                style={{ color: filtered ? "#f19113" : undefined, fontSize: filtered ? 18 : undefined }}
                            />
                        ),
                        onFilterDropdownVisibleChange: (visible: boolean) => {
                            if (visible) {
                                setTimeout(() => {
                                    if (this.nameFilterRef.current) {
                                        this.nameFilterRef.current.select();
                                    }
                                });
                            }
                        }
                    };
                } else {
                    levelColumn = {
                        title: "",
                        colSpan: 0,
                        dataIndex: "path" + i,
                        render: (value: string[], row: IRowEntry) => {
                            const obj = {
                                children: row.namePath && i < row.namePath.length ? row.namePath[i] : "",
                                props: { rowSpan: row.numberOfRepititions[i] }
                            };

                            // console.log("row span for path " + row.path + ", element " + i + ": " + obj.props.rowSpan + "");

                            return obj;
                        }
                    };
                }
                columns.push(levelColumn);
            }
        }

        const filteredColumns: any[] = [];
        this.availableColumns.forEach(c => {
            const filteredChildren: any[] = [];
            c.children.forEach(child => {
                child.className = undefined;
                if (visibleColumns.has(child.dataIndex)) {
                    filteredChildren.push(child);
                }
            });
            const newAvailableColumn = {
                ...c,
                children: filteredChildren
            };
            if (filteredChildren.length > 0) {
                filteredChildren[0].className = "overview-table-cell-first";
                filteredColumns.push(newAvailableColumn);
            }
        });
        columns.push(...filteredColumns);

        return columns;
    };

    prepareData = (forExport: boolean) => {
        const flattenedNodes = this.flattenNodes(this.props.currentNode, undefined, undefined, undefined, forExport);

        const depths = flattenedNodes.map(n => n.namePath.length);
        const maxDepth = Math.max(...depths, 1);

        return {
            flattenedNodes,
            maxDepth
        };
    };

    getData = (preparedData: IPreparedData, forExport: boolean) => {
        let data: any[] = [];

        if (this.props.project && this.props.project.status === ProjectStatus.Done) {
            const flattenedNodes = preparedData.flattenedNodes;
            const maxDepth = preparedData.maxDepth;

            const nodes = this.countRepeatingPaths(flattenedNodes, maxDepth);

            data = nodes.map(node => {
                return {
                    key: node.uuid,
                    name: node.name,
                    namePath: node.namePath,
                    numberOfRepititions: node.numberOfRepititions,
                    count: node.status !== NodeStatus.DISABLED && node.nodeStatistics ? node.nodeStatistics.count : "-",
                    min:
                        node.status !== NodeStatus.DISABLED && node.nodeStatistics
                            ? Utils.formatNumber(node.nodeStatistics.min, 0)
                            : "-",
                    max:
                        node.status !== NodeStatus.DISABLED && node.nodeStatistics
                            ? Utils.formatNumber(node.nodeStatistics.max, 0)
                            : "-",
                    average:
                        node.status !== NodeStatus.DISABLED && node.nodeStatistics
                            ? Utils.formatNumber(node.nodeStatistics.average, 0)
                            : "-",
                    failureProbability:
                        node.status !== NodeStatus.DISABLED
                            ? Utils.formatNumber(100 * node.failureProbability, 0)
                            : "-",
                    failureProbabilityChlorides:
                        node.status !== NodeStatus.DISABLED
                            ? Utils.formatNumber(100 * node.failureProbabilityChlorides, 0)
                            : "-",
                    standardDeviation:
                        node.status !== NodeStatus.DISABLED && node.nodeStatistics
                            ? Utils.formatNumber(node.nodeStatistics.standardDeviation, 0)
                            : "-",
                    carbAverage:
                        node.status !== NodeStatus.DISABLED && node.carbonatationStatistics
                            ? Utils.formatNumber(node.carbonatationStatistics.average, 0)
                            : "-",
                    carbSd:
                        node.status !== NodeStatus.DISABLED && node.carbonatationStatistics
                            ? Utils.formatNumber(node.carbonatationStatistics.standardDeviation, 0)
                            : "-",
                    calculatedCarbonatationFailureProbability:
                        node.status !== NodeStatus.DISABLED && node.calculatedCarbonatationFailureProbability !== null
                            ? Utils.formatNumber(100 * node.calculatedCarbonatationFailureProbability, 0)
                            : "-",
                    calculatedChlorideFailureProbability:
                        node.status !== NodeStatus.DISABLED && node.calculatedChlorideFailureProbability !== null
                            ? Utils.formatNumber(100 * node.calculatedChlorideFailureProbability, 0)
                            : "-",
                    calculatedLifetimeExpectancy:
                        node.status !== NodeStatus.DISABLED
                            ? Utils.renderCalculatedLifetimeExpectancy(node, this.props.project)
                            : "-",
                    calculatedRemainingLifetimeExpectancy:
                        node.status !== NodeStatus.DISABLED
                            ? Utils.renderCalculatedRemainingLifetimeExpectancy(node, this.props.project)
                            : "-",
                    calculatedChlorideLifetimeExpectancy:
                        node.status !== NodeStatus.DISABLED
                            ? Utils.renderCalculatedChlorideLifetimeExpectancy(node, this.props.project)
                            : "-",
                    calculatedRemainingChlorideLifetimeExpectancy:
                        node.status !== NodeStatus.DISABLED
                            ? Utils.renderCalculatedRemainingChlorideLifetimeExpectancy(node, this.props.project)
                            : "-",
                    Cs: node.status !== NodeStatus.DISABLED && node.Cs ? Utils.formatNumber(node.Cs, 2) : "-",
                    D:
                        node.status !== NodeStatus.DISABLED && node.D
                            ? Utils.formatNumberUnit(node.D / 0.000001, undefined, undefined, 2) + "E-6"
                            : "-",
                    status: node.status
                };
            });
        }

        return data;
    };

    render() {
        const { error, loading } = this.state;

        const preparedData = this.prepareData(false);

        return (
            <div>
                {error && (
                    <Alert
                        style={{ marginBottom: "10px" }}
                        message={this.props.intl.formatMessage({
                            id: "error"
                        })}
                        description={error}
                        type="error"
                        showIcon
                    />
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ flex: "1 1 auto" }}>
                        <div style={{ marginBottom: "16px" }}>
                            <Button icon="export" onClick={this.handleExport.bind(this)}>
                                {this.props.intl.formatMessage({ id: "export" })}
                            </Button>
                            <Button
                                icon="download"
                                onClick={this.handleDownloadChloridesSCurveCSV.bind(this)}
                                style={{ marginLeft: "8px" }}
                            >
                                {this.props.intl.formatMessage({ id: "overview.downloadChloridesSCurveCSV" })}
                            </Button>
                            <Button
                                icon="download"
                                onClick={this.handleDownloadCarbonatationSCurveCSV.bind(this)}
                                style={{ marginLeft: "8px" }}
                            >
                                {this.props.intl.formatMessage({
                                    id: "overview.downloadCarbonatationSCurveCSV"
                                })}
                            </Button>
                            <Dropdown
                                overlay={this.getVisibleColumnsMenu()}
                                trigger={["hover"]}
                                key="visibleColumnsMenuDropdown"
                            >
                                <Button icon="edit" style={{ float: "right" }}>
                                    {this.props.intl.formatMessage({ id: "project.overview.visibleColumns" })}
                                </Button>
                            </Dropdown>
                        </div>
                        <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                            <Table
                                loading={loading}
                                dataSource={this.getData(preparedData, false)}
                                columns={this.getColumns(preparedData, false)}
                                pagination={false}
                                size="small"
                                className="no-hover"
                                rowClassName={(record, index) => {
                                    if (record.status === NodeStatus.DISABLED) {
                                        return "node-disabled";
                                    } else {
                                        return "";
                                    }
                                }}
                            />
                        </ConfigProvider>
                    </div>
                    <div style={{ flex: "1 1 auto" }}>
                        {this.props.project &&
                            this.props.project.status === ProjectStatus.Done &&
                            this.props.currentNode.nodeGraphURL && (
                                <div style={{ display: "inline-flex", margin: "4px" }}>
                                    <img
                                        width={640}
                                        height={480}
                                        src={
                                            Utils.getDownloadURL(this.props.currentNode.nodeGraphURL) + "?" + Date.now()
                                        }
                                        alt="Overview Graph"
                                    />
                                </div>
                            )}

                        {this.props.project &&
                            this.props.project.status === ProjectStatus.Done &&
                            this.props.currentNode.carbonationSCurveGraphURL && (
                                <div style={{ display: "inline-flex", margin: "4px", position: "relative" }}>
                                    <img
                                        width={640}
                                        height={480}
                                        src={
                                            Utils.getDownloadURL(this.props.currentNode.carbonationSCurveGraphURL) +
                                            "?" +
                                            Date.now()
                                        }
                                        alt="Carbonatation S-Curve Graph"
                                    />
                                    <Tag color="green" style={{ position: "absolute", right: "24px", top: "42px" }}>
                                        CO<sub>2</sub>
                                    </Tag>
                                </div>
                            )}
                        {this.props.project &&
                            this.props.project.status === ProjectStatus.Done &&
                            this.props.currentNode.chlorideSCurveGraphURL && (
                                <div style={{ display: "inline-flex", margin: "4px", position: "relative" }}>
                                    <img
                                        width={640}
                                        height={480}
                                        src={
                                            Utils.getDownloadURL(this.props.currentNode.chlorideSCurveGraphURL) +
                                            "?" +
                                            Date.now()
                                        }
                                        alt="Chlorides S-Curve Graph"
                                    />
                                    <Tag color="purple" style={{ position: "absolute", right: "24px", top: "42px" }}>
                                        {"Cl"}
                                    </Tag>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    handleSearch = (selectedKeys: React.Key[] | undefined, confirm: (() => void) | undefined) => {
        if (confirm) {
            confirm();
        }
        if (selectedKeys && selectedKeys[0]) {
            this.setState({
                ...this.state,
                nameFilterText: "" + selectedKeys[0]
            });
        }
    };

    handleReset = (clearFilters: (() => void) | undefined) => {
        if (clearFilters) {
            clearFilters();
        }
        this.setState({ ...this.state, nameFilterText: "" });
    };

    getMenuItem(key: string, label: string) {
        return (
            <Menu.Item key={key}>
                <label style={{ cursor: "pointer" }}>
                    <input
                        type="checkbox"
                        name={key}
                        style={{
                            marginRight: "5px",
                            height: "19px",
                            cursor: "pointer",
                            verticalAlign: "middle"
                        }}
                        checked={this.state.visibleColumns.has(key)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const copy = new Set([...this.state.visibleColumns]);
                            if (e.target.checked) {
                                // becomes checked
                                copy.add(key);
                            } else {
                                // becomes unchecked
                                copy.delete(key);
                            }
                            this.setState({ ...this.state, visibleColumns: copy });
                        }}
                    />
                    {label}
                </label>
            </Menu.Item>
        );
    }

    getVisibleColumnsMenu() {
        const flattenedColumns: any[] = [];
        this.availableColumns.forEach(c => {
            c.children.forEach(child => {
                flattenedColumns.push(child);
            });
        });

        return (
            <Menu key="visibleColumnsMenu">
                {this.getMenuItem(
                    "name",
                    this.props.intl.formatMessage({
                        id: "node.name"
                    })
                )}
                {flattenedColumns.map(c => {
                    return this.getMenuItem(c.dataIndex, c.checkboxName);
                })}
            </Menu>
        );
    }

    handleExport() {
        const div = document.createElement("div");
        const preparedData = this.prepareData(true);
        ReactDOM.render(
            <Table
                dataSource={this.getData(preparedData, true)}
                columns={this.getColumns(preparedData, true)}
                pagination={false}
                size="small"
                className="no-hover"
            />,

            div
        );
        Utils.copyToClip(div.innerHTML);
        notification["success"]({
            message: this.props.intl.formatMessage({
                id: "export"
            }),
            description: this.props.intl.formatMessage({
                id: "export.succeeded"
            })
        });
    }

    handleDownloadChloridesSCurveCSV() {
        const url = this.props.currentNode.chlorideSCurveCSVURL;
        if (url) {
            window.open(Utils.getDownloadURL(this.props.currentNode.chlorideSCurveCSVURL), "_blank");
        }
    }

    handleDownloadCarbonatationSCurveCSV() {
        const url = this.props.currentNode.carbonationSCurveCSVURL;
        if (url) {
            window.open(Utils.getDownloadURL(this.props.currentNode.carbonationSCurveCSVURL), "_blank");
        }
    }
}

export default injectIntl(Overview);
