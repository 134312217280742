import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";

import { Button, InputNumber, Skeleton } from "antd";
import { CoverMeasurementLifecycle, NodeStatus, PropagationLevel } from "../../store/projects/types";
import { IRowEntry, skeletonProps } from "./covermeasurements";

interface IOwnProps {
    record: IRowEntry;
    copy: boolean;
    isEditingCoverdepthOverlay: (record: IRowEntry) => boolean;
    saveCoverdepthOverlayChange: (newCoverdepthOverlay: number | undefined, key: string) => void;
    editCoverdepthOverlay: (record: IRowEntry) => void;
    cancelCoverdepthOverlayChange: () => void;
}

type AllProps = IOwnProps & InjectedIntlProps;

interface IState {
    currentCoverdepthOverlay?: number;
}

class CovermeasurementCoverdepthOverlay extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            currentCoverdepthOverlay: props.record.propagatedCoverdepthOverlay.value
        };
    }

    onCoverdepthOverlayChanged(value: number | undefined) {
        this.setState({
            ...this.state,
            currentCoverdepthOverlay: value
        });
    }

    render() {
        const {
            record,
            copy,
            isEditingCoverdepthOverlay,
            saveCoverdepthOverlayChange,
            editCoverdepthOverlay,
            cancelCoverdepthOverlayChange
        } = this.props;

        if (record.status === CoverMeasurementLifecycle.Draft) {
            return <Skeleton {...skeletonProps} />;
        } else if (record.status === CoverMeasurementLifecycle.Error) {
            return "—";
        } else {
            let coverdepthOverlayString: string;
            let textStyle: any = { float: "left", color: "#bbb" };

            if (record.coverdepthOverlay) {
                coverdepthOverlayString = record.coverdepthOverlay.toString();
                textStyle = { float: "left" };
            } else {
                switch (record.propagatedCoverdepthOverlay.sourceLevel) {
                    case PropagationLevel.PROJECT:
                        coverdepthOverlayString = `${
                            record.propagatedCoverdepthOverlay.value
                        } (${this.props.intl.formatMessage({
                            id: "project"
                        })})`;
                        break;
                    case PropagationLevel.NODE:
                        coverdepthOverlayString = `${record.propagatedCoverdepthOverlay.value} (${
                            record.propagatedCoverdepthOverlay.sourceName
                        })`;
                        break;
                    case PropagationLevel.FILE:
                        coverdepthOverlayString = `${record.propagatedCoverdepthOverlay.value}`;
                        textStyle = { float: "left" };
                        break;
                    default:
                        coverdepthOverlayString = "";
                }
            }

            if (copy) {
                return (
                    <span>
                        <div style={textStyle}>{coverdepthOverlayString}</div>
                    </span>
                );
            } else if (isEditingCoverdepthOverlay(record)) {
                return (
                    <span>
                        <InputNumber
                            min={0}
                            defaultValue={this.state.currentCoverdepthOverlay}
                            onChange={this.onCoverdepthOverlayChanged.bind(this)}
                            value={this.state.currentCoverdepthOverlay}
                            placeholder={coverdepthOverlayString}
                            onKeyUp={e => {
                                if (e.key === "Enter") {
                                    saveCoverdepthOverlayChange(this.state.currentCoverdepthOverlay, record.key);
                                }
                            }}
                            autoFocus={true}
                            onFocus={e => e.target.select()}
                        />
                        <Button
                            size="small"
                            icon="check"
                            onClick={e => {
                                saveCoverdepthOverlayChange(this.state.currentCoverdepthOverlay, record.key);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{ border: "none", backgroundColor: "rgba(0, 0, 0, 0)" }}
                        />
                        <Button
                            size="small"
                            icon="close"
                            onClick={e => {
                                cancelCoverdepthOverlayChange();
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{ border: "none", backgroundColor: "rgba(0, 0, 0, 0)" }}
                        />
                    </span>
                );
            } else {
                return (
                    <span>
                        {record.nodeStatus !== NodeStatus.DISABLED && (
                            <Button
                                size="small"
                                icon="edit"
                                onClick={() => editCoverdepthOverlay(record)}
                                style={{
                                    float: "left",
                                    marginLeft: 2,
                                    border: "none",
                                    backgroundColor: "rgba(0, 0, 0, 0)"
                                }}
                            />
                        )}
                        <div style={textStyle}>{coverdepthOverlayString}</div>
                    </span>
                );
            }
        }
    }
}

export default injectIntl(CovermeasurementCoverdepthOverlay);
