/**
 * Put all used icons by antd here to enable tree shaking of web pack
 */
export { default as Download } from "@ant-design/icons/lib/outline/DownloadOutline";
export { default as Delete } from "@ant-design/icons/lib/outline/DeleteOutline";
export { default as Edit } from "@ant-design/icons/lib/outline/EditOutline";
export { default as User } from "@ant-design/icons/lib/outline/UserOutline";
export { default as File } from "@ant-design/icons/lib/outline/FileOutline";
export { default as Search } from "@ant-design/icons/lib/outline/SearchOutline";
export { default as Upload } from "@ant-design/icons/lib/outline/UploadOutline";
export { default as Warning } from "@ant-design/icons/lib/outline/WarningOutline";
export { default as Inbox } from "@ant-design/icons/lib/outline/InboxOutline";
export { default as IssuesClose } from "@ant-design/icons/lib/outline/IssuesCloseOutline";
export { default as Check } from "@ant-design/icons/lib/outline/CheckOutline";
export { default as ExclamationCircle } from "@ant-design/icons/lib/outline/ExclamationCircleOutline";
export { default as InfoCircle } from "@ant-design/icons/lib/outline/InfoCircleOutline";
export { default as QuestionCircle } from "@ant-design/icons/lib/outline/QuestionCircleOutline";
export { default as Question } from "@ant-design/icons/lib/outline/QuestionOutline";
export { default as Down } from "@ant-design/icons/lib/outline/DownOutline";
export { default as Left } from "@ant-design/icons/lib/outline/LeftOutline";
export { default as Right } from "@ant-design/icons/lib/outline/RightOutline";
export { default as Plus } from "@ant-design/icons/lib/outline/PlusOutline";
export { default as ArrowLeft } from "@ant-design/icons/lib/outline/ArrowLeftOutline";
export { default as CheckCircle } from "@ant-design/icons/lib/outline/CheckCircleOutline";
export { default as CloseCircle } from "@ant-design/icons/lib/outline/CloseCircleOutline";
export { default as Close } from "@ant-design/icons/lib/outline/CloseOutline";
export { default as Loading } from "@ant-design/icons/lib/outline/LoadingOutline";
export { default as Export } from "@ant-design/icons/lib/outline/ExportOutline";
export { default as BarChart } from "@ant-design/icons/lib/outline/BarChartOutline";
export { default as Number } from "@ant-design/icons/lib/outline/NumberOutline";
export { default as Bank } from "@ant-design/icons/lib/outline/BankOutline";
export { default as Build } from "@ant-design/icons/lib/outline/BuildOutline";

export { default as CaretUp } from "@ant-design/icons/lib/fill/CaretUpFill";
export { default as CaretDown } from "@ant-design/icons/lib/fill/CaretDownFill";
export { default as Heart } from "@ant-design/icons/lib/fill/HeartFill";
