import axios from "axios";
import authService from "./auth-service";

const anyWindow = window as any;
// __runtime_configuration should always be there in aws
const baseAPI = anyWindow.__runtime_configuration ? anyWindow.__runtime_configuration.api : process.env.API;

const api = axios.create({
    baseURL: baseAPI,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json"
    }
});

// Fill in the authorization dynamically before each request
api.interceptors.request.use(config => {
    config.headers["Authorization"] = `Bearer ${authService.getAccessToken()}`;

    return config;
});

export default api;
