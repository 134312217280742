import { Reducer } from "redux";
import { IAuthState, AuthActionTypes } from "./types";
import authService from "../../services/API/auth-service";

/**
 * Initial state
 */
const initialState: IAuthState = {
    authenticating: false,
    authenticated: authService.isAuthenticated(),
    errors: undefined
};

/**
 * The reducer
 * @param state
 * @param action
 */
const reducer: Reducer<IAuthState> = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN_FINISHED: {
            return { ...state, errors: undefined, authenticating: true };
        }
        case AuthActionTypes.LOGIN_SUCCESS: {
            return { ...state, authenticating: false, authenticated: true, loginInfo: action.payload };
        }
        case AuthActionTypes.LOGIN_ERROR: {
            return { ...state, authenticating: false, authenticated: false, errors: action.payload };
        }
        case AuthActionTypes.LOGOUT_REQUEST: {
            return { ...state, errors: undefined, authenticating: true };
        }
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...state, authenticating: false, authenticated: false, loginInfo: { token: "", username: "" } };
        }
        case AuthActionTypes.LOGOUT_ERROR: {
            return { ...state, authenticating: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as authReducer };
