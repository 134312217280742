import * as React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import * as Sentry from "@sentry/browser";
Sentry.init({ dsn: "https://1ac0a7ab86cf4d2695c820c290c18e37@sentry.io/1443345" });

import configureStore from "./configure-store";
import Main from "./main";
import "./style/style.css";

// We use hash history because this example is going to be hosted statically.
// Normally you would use browser history.
const history = createBrowserHistory();

const store = configureStore(history);

ReactDOM.render(<Main store={store} history={history} />, document.getElementById("root"));
