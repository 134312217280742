import * as auth0 from "auth0-js";
import * as JWT from "jwt-decode";
import { IAuthenticatedUser } from "../../store/users/types";

const anyWindow = window as any;
// __runtime_configuration should always be there in aws
const CLIENT_ID = anyWindow.__runtime_configuration
    ? anyWindow.__runtime_configuration.clientId
    : process.env.CLIENT_ID; // frontend
const AUTH_DOMAIN = "auth.dimcost.com";
const REDIRECT_URI = location.protocol + "//" + location.host + "/callback";
const AUDIENCE = "api.app.dimcost.com";
const REQUESTED_SCOPE = "openid profile email read:projects create:projects delete:projects";
const NAMESPACE = "https://auth.dimcost.com/";

class AuthService {
    auth0 = new auth0.WebAuth({
        domain: AUTH_DOMAIN,
        clientID: CLIENT_ID,
        redirectUri: REDIRECT_URI,
        responseType: "token id_token",
        audience: AUDIENCE,
        scope: REQUESTED_SCOPE
    });
    userProfile: IAuthenticatedUser | null = null;

    login() {
        this.auth0.authorize();
    }

    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }

    handleAuthentication(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.fetchProfile(authResult.accessToken)
                        .then(profile => {
                            this.setSession(authResult, profile);
                            resolve();
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else if (err) {
                    reject(err);
                }
            });
        });
    }

    getUser(): IAuthenticatedUser {
        if (this.userProfile) {
            return this.userProfile;
        }
        const profileString = localStorage.getItem("profile");
        if (profileString) {
            this.userProfile = JSON.parse(profileString);
            if (!this.userProfile) {
                throw new Error("Could not parse saved user profile");
            }

            return this.userProfile;
        }

        throw new Error("User profile was not fetched during login");
    }

    setSession(authResult: auth0.Auth0DecodedHash, profile: IAuthenticatedUser) {
        // Set the time that the Access Token will expire at
        if (authResult.expiresIn !== undefined && authResult.accessToken && authResult.idToken) {
            const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
            localStorage.setItem("access_token", authResult.accessToken);
            localStorage.setItem("id_token", authResult.idToken);
            localStorage.setItem("expires_at", expiresAt);
            localStorage.setItem("profile", JSON.stringify(profile));
            const decodedAccessToken = JWT(authResult.accessToken) as any;
            localStorage.setItem("scope", decodedAccessToken["permissions"] || "");
        }
    }

    getAccessToken() {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            throw new Error("No access token found");
        }

        return accessToken;
    }

    private fetchProfile(accessToken: string): Promise<IAuthenticatedUser> {
        const promise = new Promise<IAuthenticatedUser>((resolve, reject) => {
            this.auth0.client.userInfo(accessToken, (err, profile) => {
                console.log("Profile", profile);
                if (profile) {
                    this.userProfile = this.convertUser(profile);
                    resolve(this.userProfile);
                } else {
                    reject(err);
                }
            });
        });

        return promise;
    }

    convertUser(authzUser: auth0.Auth0UserProfile): IAuthenticatedUser {
        console.log("User", authzUser);

        const userMetadata: any = (authzUser as any)[NAMESPACE + "user_metadata"] || {};
        const appMetadata: any = (authzUser as any)[NAMESPACE + "app_metadata"] || {};
        let userOrganizations = [];
        const dimcost = appMetadata.dimcost;
        if (dimcost) {
            const organizations = dimcost.organizations;
            if (organizations) {
                userOrganizations = organizations;
            }
        }

        return {
            emailAddress: authzUser.email,
            givenName: authzUser.given_name,
            name: authzUser.name,
            locale: userMetadata["locale"] || "en",
            username: authzUser.username || "",
            picture: authzUser.picture,
            id: authzUser.sub,
            organizations: userOrganizations
        };
    }

    logout() {
        // Clear Access Token and ID Token from local storage
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("profile");
        localStorage.removeItem("scope");

        this.userProfile = null;

        // navigate to the home route
        location.href =
            "https://auth.dimcost.com/v2/logout?returnTo=" +
            location.protocol +
            "//" +
            location.host +
            "/login" +
            "&client_id=" +
            CLIENT_ID;
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // Access Token's expiry time
        const expiresAt = localStorage.getItem("expires_at");
        if (expiresAt) {
            const parsedExpiredAt = JSON.parse(expiresAt);

            return new Date().getTime() < parsedExpiredAt;
        }

        return false;
    }

    hasScope(scope: "profile" | "read:projects" | "create:projects" | "delete:projects" | "invisible:calculation:old") {
        const currentScopes = localStorage.getItem("scope");
        if (!currentScopes) {
            return false;
        }

        return currentScopes.indexOf(scope) > -1;
    }
}

export default new AuthService();
