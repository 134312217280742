import { defineMessages } from "react-intl";

const messages = defineMessages({
    name: {
        id: "covermeasurement.name",
        description: "Covermeasurement Name",
        defaultMessage: "File"
    },
    Project: {
        id: "project",
        description: "Project - capitalized",
        defaultMessage: "Project"
    },
    count: {
        id: "covermeasurement.count",
        description: "Covermeasurement Count",
        defaultMessage: "Concrete Cover - Number of Measurements"
    },
    countShort: {
        id: "covermeasurement.count.short",
        description: "Covermeasurement Count Short",
        defaultMessage: "#Measurements"
    },
    rebarsCount: {
        id: "covermeasurement.rebarsCount",
        description: "Covermeasurement Rebars Count",
        defaultMessage: "#Rebars"
    },
    add: {
        id: "covermeasurement.add",
        description: "Covermeasurement Add",
        defaultMessage: "Manual Input"
    },
    addManualInput: {
        id: "covermeasurement.addManualInput",
        description: "Covermeasurement Add Manual Input",
        defaultMessage: "Override and Input Manually"
    },
    min: {
        id: "covermeasurement.min",
        description: "Covermeasurement Minimum",
        defaultMessage: "Concrete Cover - Minimum [mm]"
    },
    minShort: {
        id: "covermeasurement.min.short",
        description: "Covermeasurement Minimum Short",
        defaultMessage: "Min"
    },
    max: {
        id: "covermeasurement.max",
        description: "Covermeasurement Maximum",
        defaultMessage: "Concrete Cover - Maximum [mm]"
    },
    maxShort: {
        id: "covermeasurement.max.short",
        description: "Covermeasurement Maximum Short",
        defaultMessage: "Max"
    },
    avg: {
        id: "covermeasurement.avg",
        description: "Covermeasurement Average",
        defaultMessage: "Concrete Cover - Mean Value [mm]"
    },
    avgShort: {
        id: "covermeasurement.avg.short",
        description: "Covermeasurement Average Short",
        defaultMessage: "μ"
    },
    avgRequired: {
        id: "covermeasurement.avg.required",
        description: "Covermeasurement Average Required",
        defaultMessage: "Concrete Cover - Mean Value [mm] is required"
    },
    sd: {
        id: "covermeasurement.sd",
        description: "Covermeasurement Standard Deviation",
        defaultMessage: "Concrete Cover - Standard Deviation [mm]"
    },
    sdShort: {
        id: "covermeasurement.sd.short",
        description: "Covermeasurement Standard Deviation Short",
        defaultMessage: "σ"
    },
    sdRequired: {
        id: "covermeasurement.sd.required",
        description: "Covermeasurement Standard Deviation Required",
        defaultMessage: "Concrete Cover - Standard Deviation [mm] is required"
    },
    threshold: {
        id: "covermeasurement.threshold",
        description: "Covermeasurement threshold",
        defaultMessage:
            "Maximum Concrete Cover Threshold [mm] - " +
            "All the concrete cover measurements higher than or equal to this threshold value will be eliminated for statistical analysis."
    },
    thresholdShort: {
        id: "covermeasurement.threshold.short",
        description: "Covermeasurement threshold short",
        defaultMessage: "Max. Cover Threshold [mm]"
    },
    coverdepthQualityThreshold: {
        id: "covermeasurement.coverdepthQualityThreshold",
        description: "Covermeasurement quality threshold",
        defaultMessage:
            "Mininimum Quality Threshold [%] - " +
            "All the concrete cover measurements with a quality lower than this threshold value " +
            "will be eliminated for statistical analysis. " +
            "The quality is a parameter that can be found in the CSV files of the Hilti PS300. " +
            "For scans made with the Hilti PS200 and the Proceq Profometer, the quality is set to 100% by default."
    },
    coverdepthQualityThresholdShort: {
        id: "covermeasurement.coverdepthQualityThreshold.short",
        description: "Covermeasurement quality threshold short",
        defaultMessage: "Min. Quality Threshold [%]"
    },
    coverdepthOverlay: {
        id: "covermeasurement.coverdepthOverlay",
        description: "Covermeasurement overlay",
        defaultMessage:
            "Overlay [mm] - " +
            "The thickness of the overlay in the case an overlay was used or was present on the concrete surface " +
            "(for example in the case of rough surfaces, a layer of screed, ...). " +
            "The entered overlay thickness is then automatically subtracted from the measured concrete cover " +
            "to show the actual concrete cover."
    },
    coverdepthOverlayShort: {
        id: "covermeasurement.coverdepthOverlay.short",
        description: "Covermeasurement overlay short",
        defaultMessage: "Overlay [mm]"
    },
    histogram: {
        id: "covermeasurement.histogram",
        description: "Covermeasurement Histogram",
        defaultMessage: "Histogram"
    },
    devicetype: {
        id: "covermeasurement.devicetype",
        description: "Device Type (e.g. PS200/PS300)",
        defaultMessage: "Device"
    },
    actions: {
        id: "covermeasurement.actions",
        description: "Covermeasurement Actions",
        defaultMessage: "Actions"
    },
    upload: {
        id: "covermeasurement.upload",
        description: "Covermeasurement Upload Message",
        defaultMessage: "Click or drag file(s) to this area to upload them to"
    },
    uploadFailed: {
        id: "covermeasurement.upload.failed",
        description: "Upload not started or failed",
        defaultMessage: "Upload Failed"
    },
    uploadNoCsv: {
        id: "covermeasurement.upload.nocsv",
        description: "Upload failed detailed message - only cv files are allowed",
        defaultMessage: "Only .csv files can be uploaded."
    },
    uploadConnection: {
        id: "covermeasurement.upload.connection",
        description: "Upload connection error",
        defaultMessage: "An error occured during upload of {file}. Please try again."
    },
    lifeCycleError: {
        id: "covermeasurement.lifecycle.error",
        description: "Tooltip for measurement lifecycle error",
        defaultMessage: "There were issues processing this file. More details: {err}"
    },
    thresholdEditSuccessTitle: {
        id: "threshold.edit.succeeded.title",
        description: "Measurement Threshold Edit Succeeded title",
        defaultMessage: "Edit Max. Cover Threshold"
    },
    thresholdEditFailedTitle: {
        id: "threshold.edit.failed.title",
        description: "Measurement Threshold Edit Failed title",
        defaultMessage: "Server error"
    },
    thresholdEditSucceeded: {
        id: "threshold.edit.succeeded.message",
        description: "Threshold Edit Succeeded Notification",
        defaultMessage: "Max. Cover Threshold is successfully updated"
    },
    thresholdEditFailed: {
        id: "threshold.edit.failed.message",
        description: "Threshold Edit Failed Notification",
        defaultMessage: "Server error. Max. Cover Threshold could not be updated. Please try again."
    },
    coverdepthQualityThresholdEditSuccessTitle: {
        id: "coverdepthQualityThreshold.edit.succeeded.title",
        description: "Measurement Quality Threshold Edit Succeeded title",
        defaultMessage: "Edit Min. Quality Threshold"
    },
    coverdepthQualityThresholdEditFailedTitle: {
        id: "coverdepthQualityThreshold.edit.failed.title",
        description: "Measurement Quality Threshold Edit Failed title",
        defaultMessage: "Server error"
    },
    coverdepthQualityThresholdEditSucceeded: {
        id: "coverdepthQualityThreshold.edit.succeeded.message",
        description: "Threshold Quality Edit Succeeded Notification",
        defaultMessage: "Min. Quality Threshold is successfully updated"
    },
    coverdepthQualityThresholdEditFailed: {
        id: "coverdepthQualityThreshold.edit.failed.message",
        description: "Threshold Quality Edit Failed Notification",
        defaultMessage: "Server error. Min. Quality Threshold could not be updated. Please try again."
    },
    coverdepthOverlayEditSuccessTitle: {
        id: "coverdepthOverlay.edit.succeeded.title",
        description: "Measurement Coverdepth Overlay Edit Succeeded title",
        defaultMessage: "Edit Coverdepth Overlay"
    },
    coverdepthOverlayEditFailedTitle: {
        id: "coverdepthOverlay.edit.failed.title",
        description: "Measurement Coverdepth Overlay Edit Failed title",
        defaultMessage: "Server error"
    },
    coverdepthOverlayEditSucceeded: {
        id: "coverdepthOverlay.edit.succeeded.message",
        description: "Coverdepth Overlay Succeeded Notification",
        defaultMessage: "Coverdepth Overlay is successfully updated"
    },
    coverdepthOverlayEditFailed: {
        id: "coverdepthOverlay.edit.failed.message",
        description: "Coverdepth Overlay Failed Notification",
        defaultMessage: "Server error. Coverdepth Overlay could not be updated. Please try again."
    },
    measurementDelete: {
        id: "measurement.delete",
        description: "Measurement Delete Button",
        defaultMessage: "Delete measurement(s)"
    },
    measurementDeleteConfirm: {
        id: "measurement.delete.confirm",
        description: "Measurement Delete Confirmation",
        defaultMessage: "Are you sure you want to delete {number} measurement(s)?"
    },
    measurementDeleteManualConfirm: {
        id: "measurement.deleteManual.confirm",
        description: "Manual Measurement Delete Confirmation",
        defaultMessage: "Are you sure you want to delete the manual input measurement?"
    },
    measurementDeleteSuccessTitle: {
        id: "measurement.delete.succeeded.title",
        description: "Measurement Delete Succeeded title",
        defaultMessage: "Delete Measurement(s)"
    },
    measurementDeleteSucceeded: {
        id: "measurement.delete.succeeded.message",
        description: "Deleting of Measurement Succeeded message",
        defaultMessage: "Measurement(s) are successfully deleted"
    },
    measurementMoveSuccessTitle: {
        id: "measurement.move.succeeded.title",
        description: "Measurement Move Succeeded title",
        defaultMessage: "Move Measurement(s)"
    },
    measurementMoveSucceeded: {
        id: "measurement.move.succeeded.message",
        description: "Moving of Measurement Succeeded message",
        defaultMessage: "Measurement(s) are successfully moved"
    },
    edit: {
        id: "covermeasurement.edit",
        description: "Covermeasurement Edit",
        defaultMessage: "Edit Manual Covermeasurement"
    },
    editSucceeded: {
        id: "covermeasurement.edit.succeeded",
        description: "Covermeasurement Edit Succeeded Notification",
        defaultMessage: "Manual covermeasurement input is successfully changed"
    },
    ps300Warning: {
        id: "covermeasurement.ps300Warning",
        description: "Covermeasurement PS300 Warning",
        defaultMessage: "1-5 values per bar possible"
    }
});
export default messages;
