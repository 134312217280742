import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import "./projects-messages";
import Utils from "./utils";

import { Alert, Button, Form, Icon, Input, InputNumber, Radio, Spin, Switch, Tooltip } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { RadioChangeEvent } from "antd/lib/radio";
import { assign } from "lodash-es";

import organizationService from "../../services/API/organization-service";
import projectService from "../../services/API/project-service";
import { ICreateProject, IOrganization, ProjectLifecycle } from "../../store/projects/types";

interface IOwnState {
    error?: string;
    createButtonDisabled: boolean;
    loading: boolean;
    stochastic: boolean;
    ageInYears: boolean; // enter in years or days
    organizations: IOrganization[];
}
type AllProps = RouteComponentProps<{}> & FormComponentProps & InjectedIntlProps;

class CreateProject extends React.PureComponent<AllProps, IOwnState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            error: undefined,
            createButtonDisabled: false,
            loading: false,
            stochastic: true,
            ageInYears: true,
            organizations: []
        };
    }

    componentDidMount(): void {
        this.fetchOrganizations();
    }

    fetchOrganizations() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                organizationService
                    .getOrganizations()
                    .then(organizations => {
                        this.setState({ ...this.state, organizations, loading: false });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            organizations: [],
                            error: err.message
                        });
                    });
            }
        );
    }

    onCalculationMethodChange = (e: RadioChangeEvent) => {
        if (e && e.target) {
            const value = e.target.value;
            let stochastic = true;
            if (value === "deterministic") {
                stochastic = false;
            } else if (value === "stochastic") {
                stochastic = true;
            }
            this.setState({
                ...this.state,
                stochastic
            });
        }
    };

    onYearsChange = (checked: boolean, e: Event) => {
        /*const currentDaysValue = this.props.form.getFieldValue("ageInDays");
        const currentYearsValue = this.props.form.getFieldValue("ageInYears");

        this.setState({ ...this.state, ageInYears: checked }, () => {
            if (checked) {
                // became years
                console.log(currentDaysValue);
                if (currentDaysValue) {
                    this.props.form.setFieldsValue({ ageInYears: Math.floor(currentDaysValue / 365) });
                }
            } else {
                // became days
                console.log(currentYearsValue);
                if (currentYearsValue) {
                    this.props.form.setFieldsValue({ ageInDays: currentYearsValue * 365 });
                }
            }
        });*/
        this.setState({
            ...this.state,
            ageInYears: checked
        });
    };

    public render() {
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props;

        const { error } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const titleFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 23, offset: 1 }
            }
        };

        const organizationSettings = assign(
            {
                projectNameInitialValue: "DC",
                projectNamePattern: "^DC[0-9]{2}[0-9]{3}$",
                projectNamePatternDescription: "DCYYXXX",
                projectNamePatternExample: "DC23001"
            },
            this.state.organizations[0] && this.state.organizations[0].settings
        );

        return (
            <Spin spinning={this.state.loading} wrapperClassName="createProjectSpinWrapper">
                <Form
                    onSubmit={this.onSubmit}
                    layout="horizontal"
                    style={{ height: "100%", overflowY: "auto", padding: "8px 16px 0px 16px" }}
                >
                    <Form.Item
                        className="formTitle"
                        {...titleFormItemLayout}
                        label={
                            <span className="formTitleLabel">
                                {this.props.intl.formatMessage({ id: "project.info" })}
                            </span>
                        }
                        colon={false}
                    />
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.name"
                        })}
                    >
                        {getFieldDecorator("name", {
                            initialValue: organizationSettings.projectNameInitialValue,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.name.required"
                                    })
                                },
                                {
                                    pattern: new RegExp(organizationSettings.projectNamePattern),
                                    message: this.props.intl.formatMessage(
                                        {
                                            id: "project.name.pattern"
                                        },
                                        {
                                            format: organizationSettings.projectNamePatternDescription,
                                            example: organizationSettings.projectNamePatternExample
                                        }
                                    )
                                }
                            ]
                        })(<Input autoFocus />)}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.description"
                        })}
                    >
                        {getFieldDecorator("description", {
                            initialValue: "",
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.description.required"
                                    })
                                }
                            ]
                        })(<Input.TextArea rows={3} />)}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.customer"
                        })}
                    >
                        {getFieldDecorator("customer", {
                            initialValue: "",
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.customer.required"
                                    })
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.location"
                        })}
                    >
                        {getFieldDecorator("location", {
                            initialValue: "",
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.location.required"
                                    })
                                }
                            ]
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: "project.age" })}>
                        <Tooltip
                            title={this.props.intl.formatMessage({
                                id: this.state.ageInYears
                                    ? "project.age.switchYearsTooltip"
                                    : "project.age.switchDaysTooltip"
                            })}
                        >
                            <Switch
                                checkedChildren={this.props.intl.formatMessage({
                                    id: "project.ageInYears"
                                })}
                                unCheckedChildren={this.props.intl.formatMessage({
                                    id: "project.ageInDays"
                                })}
                                style={{ marginRight: "8px" }}
                                defaultChecked={this.state.ageInYears}
                                onChange={this.onYearsChange}
                            />
                        </Tooltip>
                        {this.state.ageInYears &&
                            getFieldDecorator("ageInYears", {
                                initialValue: 0,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.age.required"
                                        })
                                    },
                                    {
                                        pattern: /^[1-9]/,
                                        message: this.props.intl.formatMessage({
                                            id: "project.age.pattern"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "years"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "years"
                                            })
                                        )
                                    }
                                />
                            )}

                        {!this.state.ageInYears &&
                            getFieldDecorator("ageInDays", {
                                initialValue: 0,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.age.required"
                                        })
                                    },
                                    {
                                        pattern: /^[1-9]/,
                                        message: this.props.intl.formatMessage({
                                            id: "project.age.pattern"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "days"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "days"
                                            })
                                        )
                                    }
                                />
                            )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.maxCalculationAge"
                        })}
                    >
                        {getFieldDecorator("maxCalculationAge", {
                            initialValue: 100,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.maxCalculationAge.required"
                                    })
                                },
                                {
                                    pattern: /^[1-9]/,
                                    message: this.props.intl.formatMessage({
                                        id: "project.maxCalculationAge.pattern"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                max={200}
                                step={10}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "years"
                                        })
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "years"
                                        })
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        className="formTitle"
                        {...titleFormItemLayout}
                        label={
                            <span className="formTitleLabel">
                                {this.props.intl.formatMessage({ id: "project.concreteCover" })}
                            </span>
                        }
                        colon={false}
                    />
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {this.props.intl.formatMessage({ id: "project.coverdepthThreshold" })}
                                <Tooltip
                                    title={this.props.intl.formatMessage({
                                        id: "project.coverdepthThreshold.explanation"
                                    })}
                                >
                                    <Icon type="question-circle" className="formIcon" />
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator("coverdepthThreshold", {
                            initialValue: 100,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.coverdepthThreshold.required"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "mm"
                                        })
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "mm"
                                        })
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {this.props.intl.formatMessage({ id: "project.coverdepthQualityThreshold" })}
                                <Tooltip
                                    title={this.props.intl.formatMessage({
                                        id: "project.coverdepthQualityThreshold.explanation"
                                    })}
                                >
                                    <Icon type="question-circle" className="formIcon" />
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator("coverdepthQualityThreshold", {
                            initialValue: 20,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.coverdepthQualityThreshold.required"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                max={100}
                                step={1}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        })
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        })
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {this.props.intl.formatMessage({ id: "project.coverdepthOverlay" })}
                                <Tooltip
                                    title={this.props.intl.formatMessage({
                                        id: "project.coverdepthOverlay.explanation"
                                    })}
                                >
                                    <Icon type="question-circle" className="formIcon" />
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator("coverdepthOverlay", {
                            initialValue: 0,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.coverdepthOverlay.required"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "mm"
                                        })
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "mm"
                                        })
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        className="formTitle"
                        {...titleFormItemLayout}
                        label={
                            <span className="formTitleLabel">
                                {this.props.intl.formatMessage({ id: "project.carbonation" })}
                            </span>
                        }
                        colon={false}
                    />
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {this.props.intl.formatMessage({ id: "project.failureProbability" })}
                                {" P"}
                                <sub>f crit CO₂</sub>
                            </span>
                        }
                    >
                        {getFieldDecorator("failureProbability", {
                            initialValue: 0.1,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.failureProbability.required"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                max={1}
                                step={0.01}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        }),
                                        100
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        }),
                                        100
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        className="formTitle"
                        {...titleFormItemLayout}
                        label={
                            <span className="formTitleLabel">
                                {this.props.intl.formatMessage({ id: "project.chlorides" })}
                            </span>
                        }
                        colon={false}
                    />
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.calculationMethod"
                        })}
                    >
                        {getFieldDecorator("calculationMethod", {
                            initialValue: this.state.stochastic ? "stochastic" : "deterministic"
                        })(
                            <Radio.Group onChange={this.onCalculationMethodChange}>
                                <Radio value="stochastic">
                                    {this.props.intl.formatMessage({ id: "project.stochastic" })}
                                </Radio>
                                <Radio value="deterministic">
                                    {this.props.intl.formatMessage({ id: "project.deterministic" })}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    {this.state.stochastic && (
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.chloridesBetaStd"
                            })}
                        >
                            {getFieldDecorator("chloridesBetaStd", {
                                initialValue: 0.15,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.chloridesBetaStd.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    step={0.01}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            })
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                    )}
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: this.state.stochastic ? "project.chloridesBeta" : "project.chloridesThreshold"
                        })}
                    >
                        {getFieldDecorator("chloridesThreshold", {
                            initialValue: this.state.stochastic ? 0.6 : 0.4,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: this.state.stochastic
                                            ? "project.chloridesBeta.required"
                                            : "project.chloridesThreshold.required"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                max={100}
                                step={0.1}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        })
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        })
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: "project.chloridesAgingFactor"
                        })}
                    >
                        {getFieldDecorator("chloridesAgingFactor", {
                            initialValue: 0,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.chloridesAgingFactor.required"
                                    })
                                }
                            ]
                        })(<InputNumber min={0} max={1} step={0.01} />)}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {this.props.intl.formatMessage({ id: "project.failureProbabilityChlorides" })}
                                {" P"}
                                <sub>f crit Cl</sub>
                            </span>
                        }
                    >
                        {getFieldDecorator("failureProbabilityChlorides", {
                            initialValue: 0.1,
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: "project.failureProbabilityChlorides.required"
                                    })
                                }
                            ]
                        })(
                            <InputNumber
                                min={0}
                                max={1}
                                step={0.01}
                                formatter={value =>
                                    Utils.formatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        }),
                                        100
                                    )
                                }
                                parser={value =>
                                    Utils.unFormatNumberUnit(
                                        value,
                                        this.props.intl.formatMessage({
                                            id: "procent"
                                        }),
                                        100
                                    )
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" disabled={this.state.createButtonDisabled}>
                            {this.props.intl.formatMessage({ id: "project.create" })}
                        </Button>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        {error && (
                            <Alert
                                message={this.props.intl.formatMessage({
                                    id: "error"
                                })}
                                description={error}
                                type="error"
                                showIcon
                            />
                        )}
                    </Form.Item>
                </Form>
            </Spin>
        );
    }

    onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        const {
            form: { validateFields, getFieldsValue }
        } = this.props;

        let isValidForm = true;
        validateFields(err => {
            if (err) {
                isValidForm = false;
            }
        });

        if (!isValidForm) {
            return false;
        }

        const values = getFieldsValue() as any;

        if (values.calculationMethod === "deterministic") {
            values["chloridesBetaStd"] = 0;
        }

        if (this.state.ageInYears) {
            values["age"] = values["ageInYears"];
        } else {
            values["age"] = values["ageInDays"] / 365;
        }

        delete values["calculationMethod"];
        delete values["ageInYears"];
        delete values["ageInDays"];

        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                const project = values as ICreateProject;
                project.lifecycle = ProjectLifecycle.Published;

                if (!this.state.organizations[0]) {
                    this.setState({
                        ...this.state,
                        error: "No organization found for current user",
                        loading: false
                    });

                    return;
                } else {
                    project.organization = this.state.organizations[0]._id;
                }

                projectService
                    .createProject(project)
                    .then(createdProject => {
                        projectService.createEmptyTree(createdProject);

                        return createdProject;
                    })
                    .then(createdProject => {
                        this.props.history.push("/projects/" + createdProject._id);
                    })
                    .catch(err => {
                        this.setState({ ...this.state, error: err.message, loading: false });
                    });
            }
        );

        return true;
    };
}

export default Form.create({})(injectIntl(CreateProject));
