import { defineMessages } from "react-intl";

const messages = defineMessages({
    home: {
        id: "home",
        description: "Icon Home Tooltip",
        defaultMessage: "Home"
    },
    help: {
        id: "help",
        description: "Icon Help Tooltip",
        defaultMessage: "Documentation"
    },
    projects: {
        id: "projects",
        description: "Icon Projects Tooltip",
        defaultMessage: "Projects"
    },
    searchPlaceholder: {
        id: "search.placeholder",
        description: "Search Placeholder",
        defaultMessage: "Search for Projects"
    }
});

export default messages;
