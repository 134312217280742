import * as React from "react";
import { withRouter, Route, Switch, RouteComponentProps } from "react-router-dom";

import ProfilePage from "../pages/profile";
import ProjectsPage from "../pages/projects";
import CreateProjectPage from "../pages/projects/createproject";
import NotFoundPage from "../pages/notfound";

import { IProject } from "../store/projects/types";

interface IOwnProps {
    projects: IProject[];
}

type AllProps = IOwnProps & RouteComponentProps<{}>;

class MainRoutes extends React.PureComponent<AllProps> {
    render() {
        return (
            <Switch>
                <Route path="/projects" component={() => <ProjectsPage projects={this.props.projects} />} />
                <Route path="/createproject" component={CreateProjectPage} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        );
    }
}

export default withRouter(MainRoutes);
