import * as React from "react";
import { each } from "lodash-es";
import { injectIntl, InjectedIntlProps } from "react-intl";
import "./chlorides-messages";

import { IChlorideMeasurement, ICreateChlorideMeasurement } from "../../store/projects/types";

import { Modal, Form, Input, Alert, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

interface IOwnProps {
    measurement?: IChlorideMeasurement | ICreateChlorideMeasurement;
    visible: boolean;
    loading: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    error?: string;
}

type AllProps = IOwnProps & FormComponentProps & InjectedIntlProps;

class AddChlorides extends React.PureComponent<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    getInitialValue() {
        const { measurement } = this.props;
        let lines = "";
        if (measurement) {
            each(measurement.positions, (pos, index) => {
                const depth = pos;
                const value = measurement.measurements[index];
                if (value !== undefined) {
                    lines += depth + " " + value + "\n";
                }
            });
        }

        return lines;
    }

    public render() {
        const {
            form: { getFieldDecorator },
            error,
            measurement
        } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 0 },
                sm: { span: 0 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 24,
                    offset: 0
                }
            }
        };

        return (
            <Modal
                title={this.props.intl.formatMessage({
                    id: "chlorides.add"
                })}
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                okButtonProps={{
                    disabled: this.props.loading
                }}
                cancelButtonProps={{
                    disabled: this.props.loading
                }}
            >
                <Spin spinning={this.props.loading}>
                    <Form layout="horizontal" style={{ width: "100%" }}>
                        <Form.Item {...formItemLayout}>
                            {getFieldDecorator("values", {
                                initialValue: this.getInitialValue()
                            })(<Input.TextArea autoFocus rows={20} />)}
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            {error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={error}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const addChloridesWrapped = injectIntl(Form.create<AllProps>()(AddChlorides));

export { addChloridesWrapped as AddChlorides, AllProps as AddChloridesProps };
