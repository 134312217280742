import { findKey, isDate, map, orderBy, reduce } from "lodash-es";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./main-layout-messages";

import authService from "../services/API/auth-service";

import ProfileButton from "../components/profilebutton/profile-button";
import { IProject } from "../store/projects/types";

import { Button, Icon, Select, Tooltip } from "antd";
import { SelectValue } from "antd/lib/select";
import Highlighter from "react-highlight-words";

interface IOwnProps {
    projects: IProject[];
    onLogout: () => void;
}

interface IState {
    filterText: string;
    selectedValue?: string;
}

interface IMatchProject {
    project: IProject;
    matchKey: string;
    matchValue: string;
}
const whiteListKeys = [
    "name",
    "description",
    "customer",
    "location",
    "created_by",
    "updated_by",
    "createdAt",
    "updatedAt"
];

type AllProps = IOwnProps & RouteComponentProps<{}> & InjectedIntlProps;

class MainHeader extends React.PureComponent<AllProps, IState> {
    selectRef = React.createRef<Select>();
    constructor(props: AllProps) {
        super(props);
        this.state = {
            filterText: "",
            selectedValue: undefined
        };
    }

    handleSearch(value: string) {
        this.setState({ ...this.state, filterText: value });
    }

    handleSelect(value: SelectValue) {
        this.props.history.push("/projects/" + value);
        this.setState({ ...this.state, filterText: "", selectedValue: undefined }, () => {
            if (this.selectRef.current) {
                this.selectRef.current.blur();
            }
        });
    }

    render() {
        const { projects } = this.props;

        const highLight = (text: string) => {
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffffbb", padding: 0 }}
                    searchWords={[this.state.filterText]}
                    autoEscape
                    textToHighlight={text}
                />
            );
        };

        const subStyle: React.CSSProperties = {
            fontSize: "12px",
            color: "rgba(0, 0, 0, 0.50)"
        };

        const filteredProjects = orderBy(
            reduce(
                projects,
                (memo: IMatchProject[], project: IProject) => {
                    let matchValue: string | number | Date | undefined = "";
                    const matchKey = findKey(project, (value, key) => {
                        if (isDate(value)) {
                            matchValue = this.props.intl.formatDate(value) + " " + this.props.intl.formatTime(value);
                        } else {
                            matchValue = value;
                        }

                        return (
                            whiteListKeys.includes(key) &&
                            ("" + matchValue).toLowerCase().includes(this.state.filterText.toLowerCase())
                        );
                    });

                    if (matchKey) {
                        memo.push({
                            project,
                            matchKey,
                            matchValue
                        });
                    }

                    return memo;
                },
                []
            ),
            matchProject => {
                return matchProject.project.updatedAt.getTime();
            },
            "desc"
        );

        const selectOptions = map(filteredProjects, (matchProject: IMatchProject) => {
            const project = matchProject.project;
            const ignoreKeys = ["name", "description", "customer", "location"];
            const showKey = !ignoreKeys.includes(matchProject.matchKey);

            return (
                <Select.Option key={project._id}>
                    <div>
                        <span>{highLight(project.name)}</span>
                        <span style={{ ...subStyle, marginLeft: "15px" }}>{highLight(project.description)}</span>
                        <span style={subStyle}> | {highLight(project.customer)}</span>
                        <span style={subStyle}> | {highLight(project.location)}</span>
                        {showKey && (
                            <span style={{ ...subStyle, marginLeft: "5px" }}>
                                (
                                <span>
                                    {this.props.intl.formatMessage({ id: "project." + matchProject.matchKey })}:{" "}
                                </span>
                                <span>{highLight(matchProject.matchValue)}</span>)
                            </span>
                        )}
                    </div>
                </Select.Option>
            );
        });

        return (
            <div style={{ display: "flex" }}>
                <div style={{ flexShrink: 0, width: "210px" }}>
                    <Tooltip title={this.props.intl.formatMessage({ id: "home" })}>
                        <img
                            src={require("../logo.png").default}
                            alt={"Sanacon"}
                            style={{ height: "70px", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                        />
                    </Tooltip>
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        lineHeight: "70px",
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "50px",
                        marginLeft: "50px"
                    }}
                >
                    <Select
                        ref={this.selectRef}
                        showSearch
                        value={this.state.selectedValue}
                        onSelect={this.handleSelect.bind(this)}
                        onSearch={this.handleSearch.bind(this)}
                        suffixIcon={<Icon type="search" />}
                        placeholder={this.props.intl.formatMessage({
                            id: "search.placeholder"
                        })}
                        size="large"
                        style={{
                            maxWidth: "800px",
                            width: "100%",
                            height: "40px",
                            margin: "15px 0px",
                            fontSize: "18px"
                        }}
                        filterOption={false}
                    >
                        {selectOptions}
                    </Select>
                </div>

                <div style={{ width: "100%", maxWidth: "160px" /*210-50*/, flex: "0 1 auto" }} />

                <div style={{ flexShrink: 0, display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ flexShrink: 0, lineHeight: "70px", display: "flex", gap: "8px" }}>
                        <Tooltip title={this.props.intl.formatMessage({ id: "help" })}>
                            <div>
                                <Button
                                    shape="round"
                                    icon="question"
                                    style={{ width: "50px", height: "50px" }}
                                    onClick={() => {
                                        const domain = window.location.hostname;
                                        window.open(`https://docs.${domain}`, "_blank");
                                    }}
                                />
                            </div>
                        </Tooltip>
                        <ProfileButton user={authService.getUser()} onLogout={this.props.onLogout} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(MainHeader));
