import { Dispatch } from "redux";
import { connect } from "react-redux";

import { IApplicationState } from "../../store";

import NotFound from "./not-found";

const mapStateToProps = (state: IApplicationState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotFound);
