import * as React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { IApplicationState, IConnectedReduxProps } from "../store";
import { IProject } from "../store/projects/types";

import { InjectedIntlProps, injectIntl } from "react-intl";
import "./main-layout-messages";

import MainRoutes from "../routes/main-routes";
import projectService from "../services/API/project-service";
import { logoutRequest } from "../store/auth";
import MainHeader from "./main-header";

import { Alert, Divider, Drawer, Icon, Spin } from "antd";

interface IState {
    projects: IProject[];
    loading: boolean;
    error?: string;
    showDrawer: boolean;
}

interface IPropsFromState {
    authenticating: boolean;
    authenticated: boolean;
    errors?: string;
}

interface IPropsFromDispatch {
    logout: typeof logoutRequest;
}
type AllProps = IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps &
    InjectedIntlProps;

class MainLayout extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            projects: [],
            loading: true,
            error: undefined,
            showDrawer: false
        };
    }

    fetchProjects() {
        projectService
            .getProjects()
            .then(projects => {
                const mappedProjects = projects.map(project => {
                    project.updatedAt = new Date(project.updatedAt);
                    project.createdAt = new Date(project.createdAt);

                    return project;
                });
                this.setState({
                    ...this.state,
                    projects: mappedProjects,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    error: err.message,
                    projects: [],
                    loading: false
                });
            });
    }

    componentDidMount() {
        this.fetchProjects();
    }

    componentDidUpdate(prevProps: AllProps) {
        if (this.props.location !== prevProps.location) {
            // re-fetch projects on route change
            this.fetchProjects();
        }
    }

    render() {
        const { authenticating, authenticated, errors } = this.props;

        if (errors) {
            return <p>{errors}</p>;
        }

        if (!authenticated) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: this.props.location.pathname }
                    }}
                />
            );
        }

        if (this.props.location.pathname === "/") {
            return <Redirect to={"/projects"} />;
        }

        const version = process.env.VERSION || "__DEV__";

        return (
            <Spin spinning={authenticating || this.state.loading} wrapperClassName="mainLayout">
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div
                        style={{
                            background: "#fff",
                            height: "70px",
                            boxShadow: "0 4px 2px -2px #f0f1f2",
                            padding: "0 16px",
                            flex: "0 0 auto"
                        }}
                    >
                        <MainHeader projects={this.state.projects} onLogout={this.props.logout} />
                    </div>
                    <Drawer
                        placement="top"
                        mask={false}
                        height={70}
                        closable={true}
                        onClose={() => this.setState({ ...this.state, showDrawer: false })}
                        visible={this.state.showDrawer}
                        bodyStyle={{ backgroundColor: "rgb(255,192,0)" }} // "#f2dede"}}
                    >
                        <div
                            style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "22px"
                            }}
                        >
                            <Icon style={{ color: "#a94442", marginRight: "8px" }} type="warning" />
                            {"Placeholder"}
                        </div>
                    </Drawer>
                    <div style={{ marginTop: "5px", flex: "1 1 auto", backgroundColor: "#fff", overflow: "auto" }}>
                        <div style={{ background: "#fff", minHeight: 280, height: "100%" }}>
                            {this.state.error && (
                                <Alert
                                    style={{ marginBottom: "10px", marginLeft: "10px", marginRight: "10px" }}
                                    message={this.props.intl.formatMessage({ id: "error" })}
                                    description={this.state.error}
                                    type="error"
                                    showIcon
                                />
                            )}
                            {!this.state.error && <MainRoutes projects={this.state.projects} />}
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto", textAlign: "center", height: 60 }}>
                        <Divider style={{ margin: "11px 0px" }} />
                        {/*<span>
                            Made with <Icon type="heart" style={{ color: "red" }} theme="filled" /> by Balloon Inc.
                    </span>*/}
                        <span>DIMCOST © Copyright 2024</span>
                        <span style={{ color: "#aaa", marginLeft: "5px" }}>(Build: {version}-CDK)</span>
                    </div>
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = ({ auth }: IApplicationState) => ({
    authenticating: auth.authenticating,
    authenticated: auth.authenticated,
    errors: auth.errors
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logoutRequest())
});

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(MainLayout)
    )
);
