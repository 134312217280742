import { action } from "typesafe-actions";
import { AuthActionTypes } from "./types";

export const loginFinished = () => action(AuthActionTypes.LOGIN_FINISHED);
export const loginSuccess = () => action(AuthActionTypes.LOGIN_SUCCESS);
export const loginError = (message: string) => action(AuthActionTypes.LOGIN_ERROR, message);

export const logoutRequest = () => action(AuthActionTypes.LOGOUT_REQUEST);
export const logoutSuccess = () => action(AuthActionTypes.LOGOUT_SUCCESS);
export const logoutError = (message: string) => action(AuthActionTypes.LOGOUT_ERROR, message);
