import { defineMessages } from "react-intl";

const messages = defineMessages({
    genericErrorTitle: {
        id: "login.error",
        description: "Login error - title",
        defaultMessage: "Login failed"
    },
    emailVerificationTitle: {
        id: "email.verification.title",
        description: "Verification of e-mail needed - title",
        defaultMessage: "Verification of e-mail needed"
    },
    emailVerificationDescription: {
        id: "email.verification.description",
        description: "Verification of e-mail needed - details",
        defaultMessage: `Check your mailbox for a verification e- mail from support@dimcost.com.
        Click the link to verify your e-mail and re-login.
        If you did not receive a verification e-mail, or need other help, send an e-mail to support@dimcost.com`
    }
});

export default messages;
