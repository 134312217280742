import { defineMessages } from "react-intl";

const messages = defineMessages({
    histogram: {
        id: "histogram",
        description: "Histogram Title",
        defaultMessage: "Histogram"
    },
    covermeasurementThreshold: {
        id: "histogram.covermeasurementThreshold",
        description: "Covermeasurement threshold",
        defaultMessage:
            "Maximum Concrete Cover Threshold [mm] - " +
            "All the concrete cover measurements higher than or equal to this threshold value will be eliminated for statistical analysis."
    },
    covermeasurementThresholdShort: {
        id: "histogram.covermeasurementThreshold.short",
        description: "Covermeasurement threshold short",
        defaultMessage: "Max. Cover Threshold [mm]"
    },
    covermeasurementQualityThreshold: {
        id: "histogram.covermeasurementQualityThreshold",
        description: "Covermeasurement Quality Threshold",
        defaultMessage:
            "Mininimum Quality Threshold [%] - " +
            "All the concrete cover measurements with a quality lower than this threshold value " +
            "will be eliminated for statistical analysis. " +
            "The quality is a parameter that can be found in the CSV files of the Hilti PS300. " +
            "For scans made with the Hilti PS200 and the Proceq Profometer, the quality is set to 100% by default."
    },
    covermeasurementQualityThresholdShort: {
        id: "histogram.covermeasurementQualityThreshold.short",
        description: "Covermeasurement Quality Threshold Short",
        defaultMessage: "Min. Quality Threshold [%]"
    }
});

export default messages;
