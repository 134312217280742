import * as React from "react";
import { Avatar, Popover, Divider, Tooltip } from "antd";
import { injectIntl, InjectedIntlProps } from "react-intl";
import "./profile-button-messages";
import { IAuthenticatedUser } from "../../store/users/types";

interface IOwnProps {
    user: IAuthenticatedUser;
    onLogout: () => void;
    style?: React.CSSProperties;
}

type AllProps = IOwnProps & InjectedIntlProps;

interface IState {
    visible: boolean;
    toProfile: boolean;
}

class ProfileButton extends React.PureComponent<AllProps, IState> {
    private popovercontent = (
        <div>
            <div>
                <Avatar src={this.props.user.picture} icon="user" />
                <span style={{ marginLeft: "10px" }}>{this.props.user.name}</span>
            </div>
            <Divider style={{ margin: "10px 0px" }} />
            <div key="/profile">
                <a href="/profile">{this.props.intl.formatMessage({ id: "button.profile" })}</a>
            </div>
            <div style={{ marginTop: "5px" }}>
                <a
                    onClick={() => {
                        this.setState({
                            ...this.state,
                            visible: false
                        });
                        this.props.onLogout();
                    }}
                >
                    {this.props.intl.formatMessage({ id: "button.logout" })}
                </a>
            </div>
        </div>
    );

    constructor(props: AllProps) {
        super(props);
        this.state = {
            visible: false,
            toProfile: false
        };
    }

    public handleVisibleChange = (visible: boolean) => {
        this.setState({ ...this.state, visible });
    };

    public render() {
        return (
            <Popover
                content={this.popovercontent}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
            >
                <Tooltip title={this.props.user.name}>
                    <div style={{ cursor: "pointer", ...this.props.style }}>
                        <Avatar src={this.props.user.picture} icon="user" style={{ height: "50px", width: "50px" }} />
                    </div>
                </Tooltip>
            </Popover>
        );
    }
}

export default injectIntl(ProfileButton);
