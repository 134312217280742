import { defineMessages } from "react-intl";

const messages = defineMessages({
    login: {
        id: "button.login",
        description: "Button Login",
        defaultMessage: "Login"
    },
    signup: {
        id: "button.signup",
        description: "Button Sign Up",
        defaultMessage: "Sign Up"
    }
});

export default messages;
