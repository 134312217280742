import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { Redirect } from "react-router";

type AllProps = InjectedIntlProps;

const notFound: React.SFC<AllProps> = props => {
    location.replace("error.html");

    return (
        <div>
            <h2>
                {props.intl.formatMessage({
                    id: "doesnotexist"
                })}
            </h2>
        </div>
    );
};

export default injectIntl(notFound);
