import * as React from "react";
import authService from "../../services/API/auth-service";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { loginFinished, loginError, logoutRequest } from "../../store/auth";
import { injectIntl, InjectedIntlProps } from "react-intl";
import "./callback-messages";

import { Alert, Button, Layout, Row, Col } from "antd";

const handleAuthentication = ({ location }: RouteComponentProps) => {
    return new Promise<void>((resolve, reject) => {
        if (/access_token|id_token|error/.test(location.hash)) {
            authService
                .handleAuthentication()
                .then(result => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        } else {
            reject("No accessToken or error provided.");
        }
    });
};

interface IPropsFromDispatch {
    loginFinished: typeof loginFinished;
    loginError: typeof loginError;
    logoutRequest: typeof logoutRequest;
}

interface IPropsFromState {
    authenticating: boolean;
    authenticated: boolean;
    errors?: string;
}

type AllProps = IPropsFromDispatch & RouteComponentProps & IPropsFromState & InjectedIntlProps;

class Callback extends React.PureComponent<AllProps> {
    public componentDidMount() {
        handleAuthentication(this.props)
            .then(() => {
                this.props.loginFinished();
            })
            .catch(reason => {
                this.props.loginError(reason.errorDescription);
            });
    }

    render() {
        if (this.props.errors) {
            let alertType: "info" | "error" = "error";
            let title = "Error logging in";
            let description = this.props.errors;
            if (this.props.errors.indexOf("verify your email") > -1) {
                alertType = "info";
                title = this.props.intl.formatMessage({ id: "email.verification.title" });
                description = this.props.intl.formatMessage({ id: "email.verification.description" });
            }

            return (
                <Layout style={{ height: "100vh", background: "#fff" }}>
                    <Layout.Content style={{ margin: "24px 16px 0" }}>
                        <div style={{ padding: 24, textAlign: "center" }}>
                            <Row style={{ margin: "60px 0px" }}>
                                <Col
                                    md={{ span: 12, offset: 6 }}
                                    xs={{ span: 18, offset: 3 }}
                                    style={{ textAlign: "center" }}
                                >
                                    <Alert message={title} description={description} type={alertType} showIcon />{" "}
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    margin: "auto",
                                    width: "50%"
                                }}
                            >
                                <Col md={{ span: 12, offset: 6 }} xs={{ span: 18, offset: 3 }}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            this.props.logoutRequest();
                                        }}
                                    >
                                        Logout
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Layout.Content>
                </Layout>
            );
        }
        if (this.props.authenticated) {
            const destination = sessionStorage.getItem("after-login-destination");
            sessionStorage.removeItem("after-login-destination");
            // only redirect if something is set and the time since login is < 120s
            if (destination) {
                return <Redirect to={destination} />;
            } else {
                return <Redirect to="/" />;
            }
        }

        return <div>{this.props.intl.formatMessage({ id: "loading" })}</div>;
    }
}

export default withRouter(injectIntl(Callback));
