import { orderBy } from "lodash-es";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./projects-messages";

import { IProject } from "../../store/projects/types";

import { Button, Table } from "antd";
import { ColumnProps } from "antd/lib/table";

interface IOwnProps {
    projects: IProject[];
}
interface IRowEntry {
    key: string;
    name: string;
    description: string;
    customer: string;
    location: string;
    created_by: string;
    createdAt: Date;
    updated_by: string;
    updatedAt: Date;
}

type AllProps = IOwnProps & RouteComponentProps<{}> & InjectedIntlProps;

class Projects extends React.PureComponent<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    render() {
        const columns: Array<ColumnProps<IRowEntry>> = [
            {
                title: this.props.intl.formatMessage({
                    id: "project.name"
                }),
                dataIndex: "name",
                render: (text: string, record: IRowEntry) => {
                    return <Link to={`projects/${record.key}`}>{text}</Link>;
                },
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.name.localeCompare(b.name);
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.description"
                }),
                dataIndex: "description",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.description.localeCompare(b.description);
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.customer"
                }),
                dataIndex: "customer",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.customer.localeCompare(b.customer);
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.location"
                }),
                dataIndex: "location",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.location.localeCompare(b.location);
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.createdAt"
                }),
                dataIndex: "createdAt",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.createdAt.getTime() - b.createdAt.getTime();
                },
                render: (text: string, record: IRowEntry) => {
                    return (
                        this.props.intl.formatDate(record.createdAt) +
                        " " +
                        this.props.intl.formatTime(record.createdAt)
                    );
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.created_by"
                }),
                dataIndex: "created_by",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.created_by.localeCompare(b.created_by);
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.updatedAt"
                }),
                dataIndex: "updatedAt",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.updatedAt.getTime() - b.updatedAt.getTime();
                }, // defaultSortOrder: "descend",
                render: (text: string, record: IRowEntry) => {
                    return (
                        this.props.intl.formatDate(record.updatedAt) +
                        " " +
                        this.props.intl.formatTime(record.updatedAt)
                    );
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "project.updated_by"
                }),
                dataIndex: "updated_by",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.updated_by.localeCompare(b.updated_by);
                }
            }
        ];

        const data: IRowEntry[] = orderBy(
            this.props.projects.map(project => {
                return {
                    key: project._id,
                    name: project.name,
                    description: project.description,
                    location: project.location,
                    customer: project.customer,
                    created_by: project.created_by,
                    createdAt: project.createdAt,
                    updated_by: project.updated_by,
                    updatedAt: project.updatedAt
                };
            }),
            project => {
                return project.updatedAt.getTime();
            },
            "desc"
        );

        return (
            <div style={{ display: "flex", height: "100%", flexDirection: "column", padding: "8px 16px 0px 16px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={() => {
                            this.props.history.push("/createproject");
                        }}
                    >
                        {this.props.intl.formatMessage({ id: "project.create" })}
                    </Button>
                </div>
                <Table dataSource={data} columns={columns} />
            </div>
        );
    }
}

export default withRouter(injectIntl(Projects));
