import { cloneDeep, each, some, without } from "lodash-es";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Utils from "./utils";

import { InjectedIntlProps, IntlProvider, injectIntl } from "react-intl";
import "./covermeasurements-messages";

import api from "../../services/API/api";
import authService from "../../services/API/auth-service";
import covermeasurementService from "../../services/API/covermeasurement-service";
import {
    CoverMeasurementLifecycle,
    ICoverMeasurement,
    ICoverMeasurementManualInput,
    ICreateCoverMeasurementManualInput,
    INode,
    IProjectTree,
    IPropagatedValue,
    NodeStatus,
    PropagationLevel
} from "../../store/projects/types";
import { AddCovermeasurementManualInput, AddCovermeasurementManualInputProps } from "./addmanualcovermeasurement";

import { Alert, Badge, Button, Icon, Popconfirm, Skeleton, Table, Tooltip, Upload, notification } from "antd";
import { ColumnProps, TableRowSelection } from "antd/lib/table";
import { UploadChangeParam } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { useDrag } from "react-dnd";

import HistogramButton from "../../components/histogram/histogrambutton";
import projectService from "../../services/API/project-service";
import CovermeasurementCoverdepthOverlay from "./covermeasurementcoverdepthoverlay";
import CovermeasurementQualityThreshold from "./covermeasurementqualitythreshold";
import CovermeasurementThreshold from "./covermeasurementthreshold";

interface IState {
    error: string;
    loading: boolean;
    measurements: ICoverMeasurement[];

    uploadFileList: UploadFile[];

    editingKey?: string;
    rowEditingField?: RowEditingField;

    selectedRowKeys: string[] | number[];

    manualInput?: ICoverMeasurementManualInput;
    manualInputVisible: boolean;
    manualInputLoading: boolean;
    manualInputError: undefined;

    graphDate: number;
}

export enum RowEditingField {
    Threshold = "THRESHOLD",
    QualityThreshold = "QUALITY_THRESHOLD",
    Overlay = "OVERLAY",
    Histogram = "HISTOGRAM"
}

export const skeletonProps = {
    active: true,
    title: true,
    paragraph: false
};

interface IOwnProps {
    projectId: string;
    currentNode: INode;
    onChange: () => void;
    onErrorFilesFound: (fileNames: string[]) => void;
    tree: IProjectTree;
    dragFiles: boolean;
    onEditNodes: (tree: IProjectTree) => void;
    onLoading: (loading: boolean) => void;
}

type AllProps = IOwnProps & InjectedIntlProps;

export interface IRowEntry {
    key: string;
    name: string;
    count: number;
    totalCount: number;
    min: number;
    max: number;
    average: number;
    standardDeviation: number;
    threshold: number;
    coverdepthQualityThreshold?: number;
    coverdepthOverlay?: number;
    propagatedThreshold: IPropagatedValue;
    propagatedCoverdepthQualityThreshold: IPropagatedValue;
    propagatedCoverdepthOverlay: IPropagatedValue;
    measurements: number[];
    deviceType?: string;
    status: CoverMeasurementLifecycle;
    errorMessage?: string;
    coverPlotSmall?: string;
    coverPlotLarge?: string;
    nodeStatus: NodeStatus;
}

interface IManualRowEntry {
    key: string;
    average: number;
    standardDeviation: number;
}

class CoverMeasurements extends React.PureComponent<AllProps, IState> {
    addManualInputForm?: React.Component<AddCovermeasurementManualInputProps>;

    constructor(props: AllProps) {
        super(props);
        this.state = {
            error: "",
            loading: false,
            measurements: [],
            uploadFileList: [],
            editingKey: undefined,
            rowEditingField: undefined,
            selectedRowKeys: [],
            manualInput: undefined,
            manualInputVisible: false,
            manualInputLoading: false,
            manualInputError: undefined,
            graphDate: Date.now()
        };
    }

    fetchMeasurements(softLoading: boolean) {
        this.setState(
            {
                ...this.state,
                selectedRowKeys: [],
                loading: softLoading ? false : true,
                graphDate: Date.now()
            },
            () => {
                covermeasurementService
                    .getCovermeasurements(this.props.projectId, this.props.currentNode.uuid)
                    .then(measurements => {
                        const anyInDraft = some(
                            measurements,
                            measurement => measurement.status === CoverMeasurementLifecycle.Draft
                        );
                        const errorFiles = measurements
                            .filter(m => m.errorMessage)
                            .map(m => this.cleanPath(m.filePath) + "/" + m.fileName);
                        if (errorFiles && errorFiles.length > 0) {
                            console.log("error files: ", errorFiles);
                            this.props.onErrorFilesFound(errorFiles);
                        } else {
                            this.props.onErrorFilesFound([]);
                        }
                        this.setState({
                            ...this.state,
                            measurements,
                            loading: false,
                            error: ""
                        });
                        if (anyInDraft) {
                            setTimeout(() => this.fetchMeasurements(true), 2000);
                        }
                        const missingGraphs = measurements.filter(m => !m.coverPlotSmall);
                        if (missingGraphs && missingGraphs.length > 0) {
                            // console.log(missingGraphs);
                            missingGraphs.forEach(measurement => {
                                covermeasurementService
                                    .generateCoverMeasurementGraph(this.props.projectId, measurement._id)
                                    .then(updatedMeasurement => {
                                        const newMeasurements = [...this.state.measurements];
                                        const index = newMeasurements.findIndex(
                                            item => updatedMeasurement._id === item._id
                                        );
                                        if (index > -1) {
                                            const item = newMeasurements[index];
                                            item.coverPlotSmall = updatedMeasurement.coverPlotSmall;
                                            item.coverPlotLarge = updatedMeasurement.coverPlotLarge;

                                            this.setState({
                                                ...this.state,
                                                measurements: newMeasurements,
                                                loading: false,
                                                error: ""
                                            });
                                        }
                                    });
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            error: err.message,
                            measurements: [],
                            loading: false
                        });
                    });
            }
        );
    }

    fetchManualInputMeasurements() {
        this.setState(
            {
                ...this.state,
                selectedRowKeys: [],
                loading: true
            },
            () => {
                if (this.props.currentNode.covermeasurementsManualInput) {
                    covermeasurementService
                        .getManualInputCovermeasurement(this.props.currentNode.covermeasurementsManualInput)
                        .then(measurement => {
                            this.setState({
                                ...this.state,
                                manualInput: measurement,
                                loading: false,
                                error: ""
                            });
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                error: err.message,
                                manualInput: undefined,
                                loading: false
                            });
                        });
                } else {
                    this.setState({
                        ...this.state,
                        manualInput: undefined,
                        loading: false
                    });
                }
            }
        );
    }

    componentDidMount() {
        // console.log("Mount covermeasurements");
        this.fetchMeasurements(false);
        this.fetchManualInputMeasurements();
    }

    componentDidUpdate(prevProps: AllProps) {
        // console.log("Update covermeasurements");
        // console.log(prevProps.currentNode, this.props.currentNode, prevProps.currentNode === this.props.currentNode);
        if (prevProps.currentNode !== this.props.currentNode || prevProps.projectId !== this.props.projectId) {
            this.fetchMeasurements(false);
            this.fetchManualInputMeasurements();
        }
    }

    textRender = (text: string, record: IRowEntry) => {
        if (record.status === CoverMeasurementLifecycle.Draft) {
            return <Skeleton {...skeletonProps} />;
        } else if (record.status === CoverMeasurementLifecycle.Error) {
            return "—";
        } else {
            // published
            return text;
        }
    };

    cleanPath(path: string) {
        let slash = path.indexOf("/");
        if (slash === -1) {
            return path;
        }
        path = path.substr(slash + 1);
        slash = path.indexOf("/");
        if (slash === -1) {
            return path;
        }

        return path.substr(slash + 1);
    }

    getColumns(copy: boolean) {
        const columns: Array<ColumnProps<IRowEntry>> = [
            {
                title: (
                    <div>
                        <span>{this.props.intl.formatMessage({ id: "covermeasurement.name" })}</span>
                        {!copy && (
                            <Badge
                                count={this.state.measurements.length}
                                style={{
                                    backgroundColor: "#fff",
                                    color: "#999",
                                    boxShadow: "0 0 0 1px #d9d9d9 inset",
                                    marginLeft: "4px"
                                }}
                            />
                        )}
                    </div>
                ),
                dataIndex: "name",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.name.localeCompare(b.name);
                },
                render: (text: string, record: IRowEntry) => {
                    if (record.status === CoverMeasurementLifecycle.Draft) {
                        return <Skeleton {...skeletonProps} />;
                    } else if (record.status === CoverMeasurementLifecycle.Error || record.errorMessage) {
                        return (
                            <div>
                                <Tooltip
                                    title={this.props.intl.formatMessage(
                                        { id: "covermeasurement.lifecycle.error" },
                                        { err: record.errorMessage }
                                    )}
                                >
                                    <Icon style={{ color: "#a94442" }} type="warning" />
                                </Tooltip>{" "}
                                {text}
                            </div>
                        );
                    } else {
                        return text;
                    }
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "covermeasurement.count" })}>
                        {this.props.intl.formatMessage({ id: "covermeasurement.count.short" })}
                    </Tooltip>
                ),
                dataIndex: "count",
                width: 150,
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.count - b.count;
                },
                render: (text: string, record: IRowEntry) => {
                    const info =
                        record.deviceType === "PS300" ? (
                            <Tooltip
                                title={this.props.intl.formatMessage({
                                    id: "covermeasurement.ps300Warning"
                                })}
                            >
                                <Icon type="info-circle" style={{ marginLeft: "8px" }} />
                            </Tooltip>
                        ) : (
                            ""
                        );
                    if (record.status === CoverMeasurementLifecycle.Draft) {
                        return <Skeleton {...skeletonProps} />;
                    } else if (record.status === CoverMeasurementLifecycle.Error) {
                        return "—";
                    } else if (record.count === record.totalCount) {
                        return (
                            <div style={{ whiteSpace: "nowrap" }}>
                                {record.count}
                                {info}
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ whiteSpace: "nowrap" }}>
                                <span>{record.count}</span>
                                <span style={{ color: "#bbb" }}>{" (" + record.totalCount + ")"}</span>
                                <span>{info}</span>
                            </div>
                        );
                    }
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "covermeasurement.min" })}>
                        {this.props.intl.formatMessage({ id: "covermeasurement.min.short" })}
                        <sub>CC </sub>
                        {this.props.intl.formatMessage({ id: "node.unit.mm" })}
                    </Tooltip>
                ),
                dataIndex: "min",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.min - b.min;
                },
                render: this.textRender
            },
            {
                title: (
                    <Tooltip
                        title={this.props.intl.formatMessage({
                            id: "covermeasurement.max"
                        })}
                    >
                        {this.props.intl.formatMessage({ id: "covermeasurement.max.short" })}
                        <sub>CC </sub>
                        {this.props.intl.formatMessage({ id: "node.unit.mm" })}
                    </Tooltip>
                ),
                dataIndex: "max",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.max - b.max;
                },
                render: this.textRender
            },
            {
                title: (
                    <Tooltip
                        title={this.props.intl.formatMessage({
                            id: "covermeasurement.avg"
                        })}
                    >
                        {this.props.intl.formatMessage({ id: "covermeasurement.avg.short" })}
                        <sub>CC </sub>
                        {this.props.intl.formatMessage({ id: "node.unit.mm" })}
                    </Tooltip>
                ),
                dataIndex: "average",
                className: "average-highlight",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.average - b.average;
                },
                render: (text: string, record: IRowEntry) => {
                    if (record.status === CoverMeasurementLifecycle.Draft) {
                        return <Skeleton {...skeletonProps} />;
                    } else if (record.status === CoverMeasurementLifecycle.Error) {
                        return "—";
                    } else {
                        // published
                        return <b>{text}</b>;
                    }
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "covermeasurement.sd" })}>
                        {this.props.intl.formatMessage({ id: "covermeasurement.sd.short" })}
                        <sub>CC </sub>
                        {this.props.intl.formatMessage({ id: "node.unit.mm" })}
                    </Tooltip>
                ),
                dataIndex: "standardDeviation",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.standardDeviation - b.standardDeviation;
                },
                render: this.textRender
            },
            {
                title: this.props.intl.formatMessage({
                    id: "covermeasurement.devicetype"
                }),
                dataIndex: "deviceType",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return (a.deviceType || "").localeCompare(b.deviceType || "");
                },
                render: this.textRender
            }
        ];

        columns.push({
            title: (
                <Tooltip title={this.props.intl.formatMessage({ id: "covermeasurement.threshold" })}>
                    {this.props.intl.formatMessage({ id: "covermeasurement.threshold.short" })}
                </Tooltip>
            ),
            dataIndex: "threshold",
            render: (text: string, record: IRowEntry) => {
                return (
                    <CovermeasurementThreshold
                        record={record}
                        copy={copy}
                        isEditingThreshold={this.isEditingThreshold.bind(this)}
                        saveThresholdChange={this.saveThresholdChange.bind(this)}
                        editThreshold={this.editThreshold.bind(this)}
                        cancelThresholdChange={this.cancelThresholdChange.bind(this)}
                    />
                );
            },
            sorter: (a: IRowEntry, b: IRowEntry) => {
                return a.propagatedThreshold.value - b.propagatedThreshold.value;
            }
        });

        columns.push({
            title: (
                <Tooltip title={this.props.intl.formatMessage({ id: "covermeasurement.coverdepthQualityThreshold" })}>
                    {this.props.intl.formatMessage({ id: "covermeasurement.coverdepthQualityThreshold.short" })}
                </Tooltip>
            ),
            dataIndex: "coverdepthQualityThreshold",

            render: (text: string, record: IRowEntry) => {
                return (
                    <CovermeasurementQualityThreshold
                        record={record}
                        copy={copy}
                        isEditingQualityThreshold={this.isEditingQualityThreshold.bind(this)}
                        saveQualityThresholdChange={this.saveQualityThresholdChange.bind(this)}
                        editQualityThreshold={this.editQualityThreshold.bind(this)}
                        cancelQualityThresholdChange={this.cancelQualityThresholdChange.bind(this)}
                    />
                );
            },
            sorter: (a: IRowEntry, b: IRowEntry) => {
                return a.propagatedCoverdepthQualityThreshold.value - b.propagatedCoverdepthQualityThreshold.value;
            }
        });

        columns.push({
            title: (
                <Tooltip title={this.props.intl.formatMessage({ id: "covermeasurement.coverdepthOverlay" })}>
                    {this.props.intl.formatMessage({ id: "covermeasurement.coverdepthOverlay.short" })}
                </Tooltip>
            ),
            dataIndex: "coverdepthOverlay",

            render: (text: string, record: IRowEntry) => {
                return (
                    <CovermeasurementCoverdepthOverlay
                        record={record}
                        copy={copy}
                        isEditingCoverdepthOverlay={this.isEditingCoverdepthOverlay.bind(this)}
                        saveCoverdepthOverlayChange={this.saveCoverdepthOverlayChange.bind(this)}
                        editCoverdepthOverlay={this.editCoverdepthOverlay.bind(this)}
                        cancelCoverdepthOverlayChange={this.cancelCoverdepthOverlayChange.bind(this)}
                    />
                );
            },
            sorter: (a: IRowEntry, b: IRowEntry) => {
                return a.propagatedCoverdepthOverlay.value - b.propagatedCoverdepthOverlay.value;
            }
        });

        if (!copy) {
            columns.push({
                title: this.props.intl.formatMessage({
                    id: "covermeasurement.histogram"
                }),
                dataIndex: "histogram",
                width: 130,
                render: (text: string, record: IRowEntry) => {
                    if (record.status === CoverMeasurementLifecycle.Draft) {
                        return <Skeleton {...skeletonProps} />;
                    } else if (record.status === CoverMeasurementLifecycle.Error) {
                        return "—";
                    } else {
                        return (
                            <HistogramButton
                                record={record}
                                graphDate={this.state.graphDate}
                                editHistogram={this.editHistogram.bind(this)}
                                saveThresholdChange={this.saveThresholdChange.bind(this)}
                                saveQualityThresholdChange={this.saveQualityThresholdChange.bind(this)}
                                visible={this.isEditingHistogram(record)}
                            />
                        );
                    }
                }
            });

            if (this.canDeleteMeasurements()) {
                columns.push({
                    title: this.props.intl.formatMessage({
                        id: "covermeasurement.actions"
                    }),
                    dataIndex: "actions",
                    width: 65,
                    render: (text: string, record: IRowEntry) => {
                        if (record.nodeStatus === NodeStatus.DISABLED) {
                            return null;
                        } else {
                            return (
                                <span>
                                    <Popconfirm
                                        icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                        title={this.props.intl.formatMessage(
                                            { id: "measurement.delete.confirm" },
                                            { number: 1 }
                                        )}
                                        onConfirm={() => this.deleteMeasurements([record.key])}
                                        okText={this.props.intl.formatMessage({ id: "yes" })}
                                        cancelText={this.props.intl.formatMessage({ id: "no" })}
                                    >
                                        <Button icon="delete" type="danger" style={{ marginLeft: 2 }} />
                                    </Popconfirm>
                                </span>
                            );
                        }
                    }
                });
            }
        }

        return columns;
    }

    getData() {
        const { measurements } = this.state;

        return measurements.map(measurement => {
            return {
                key: measurement._id,
                name: measurement.fileName,
                deviceType: measurement.deviceType,
                totalCount: measurement.count,
                count: measurement.thresholdStatistics.count,
                min: Utils.formatNumber(measurement.thresholdStatistics.min),
                max: Utils.formatNumber(measurement.thresholdStatistics.max),
                average: Utils.formatNumber(measurement.thresholdStatistics.average, 0),
                standardDeviation: Utils.formatNumber(measurement.thresholdStatistics.standardDeviation, 0),
                measurements: measurement.measurements,
                threshold: measurement.threshold || 0,
                coverdepthQualityThreshold: measurement.coverdepthQualityThreshold || 0,
                coverdepthOverlay: measurement.coverdepthOverlay || 0,
                propagatedThreshold: measurement.propagatedThreshold,
                propagatedCoverdepthQualityThreshold: measurement.propagatedCoverdepthQualityThreshold,
                propagatedCoverdepthOverlay: measurement.propagatedCoverdepthOverlay,
                coverPlotSmall: measurement.coverPlotSmall,
                coverPlotLarge: measurement.coverPlotLarge,
                status: measurement.status,
                nodeStatus: measurement.nodeStatus,
                errorMessage: measurement.errorMessage,
                actions: ""
            } as IRowEntry;
        });
    }

    getManualData() {
        const { manualInput } = this.state;
        const data: IManualRowEntry[] = [];
        if (manualInput) {
            data.push({
                key: manualInput._id,
                average: Utils.formatNumber(manualInput.average, 0),
                standardDeviation: Utils.formatNumber(manualInput.standardDeviation, 0)
            } as IManualRowEntry);
        }

        return data;
    }

    getManualColumns(forExport = false) {
        const columns = [
            {
                title: this.props.intl.formatMessage({
                    id: "covermeasurement.avg"
                }),
                dataIndex: "average",
                render: (text: string, record: IManualRowEntry) => {
                    return <b>{text}</b>;
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "covermeasurement.sd"
                }),
                dataIndex: "standardDeviation",
                render: (text: string, record: IManualRowEntry) => {
                    return <span>{text}</span>;
                }
            }
        ];

        if (!forExport && this.canEditMeasurements()) {
            columns.push({
                title: this.props.intl.formatMessage({
                    id: "covermeasurement.actions"
                }),
                dataIndex: "actions",
                render: (text: string, record: IManualRowEntry) => {
                    return (
                        <span style={{ whiteSpace: "nowrap" }}>
                            <Button icon="edit" onClick={this.onEditManualInputClick.bind(this)} />
                            {this.canDeleteMeasurements() && (
                                <Popconfirm
                                    icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                    title={this.props.intl.formatMessage(
                                        { id: "measurement.deleteManual.confirm" },
                                        { number: 1 }
                                    )}
                                    onConfirm={() => this.deleteManualInput()}
                                    okText={this.props.intl.formatMessage({ id: "yes" })}
                                    cancelText={this.props.intl.formatMessage({ id: "no" })}
                                >
                                    <Button icon="delete" type="danger" style={{ marginLeft: 2 }} />
                                </Popconfirm>
                            )}
                        </span>
                    );
                }
            });
        }

        return columns;
    }

    render() {
        const { selectedRowKeys, error, loading, manualInput } = this.state;

        let rowSelection: TableRowSelection<IRowEntry> | undefined;
        if (this.canDeleteMeasurements()) {
            rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectChange,
                getCheckboxProps: record => ({
                    disabled: record.nodeStatus === NodeStatus.DISABLED
                })
            };
        }

        const hasSelection = selectedRowKeys.length > 0;

        return (
            <div>
                {error && (
                    <Alert
                        style={{ marginBottom: "16px" }}
                        message={this.props.intl.formatMessage({
                            id: "error"
                        })}
                        description={error}
                        type="error"
                        showIcon
                    />
                )}
                <div style={{ marginBottom: "16px" }}>
                    <Button icon="export" onClick={this.handleExport.bind(this)}>
                        {this.props.intl.formatMessage({ id: "export" })}
                    </Button>
                    {!manualInput &&
                        this.canAddMeasurements() && (
                            <Button
                                icon="addManualInput"
                                onClick={this.onAddManualInputClick.bind(this)}
                                style={{ marginLeft: 8 }}
                            >
                                {this.props.intl.formatMessage({ id: "covermeasurement.addManualInput" })}
                            </Button>
                        )}
                    {this.canDeleteMeasurements() &&
                        hasSelection && (
                            <Popconfirm
                                icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                title={this.props.intl.formatMessage(
                                    { id: "measurement.delete.confirm" },
                                    { number: selectedRowKeys.length }
                                )}
                                onConfirm={() => this.deleteMeasurements(selectedRowKeys)}
                                okText={this.props.intl.formatMessage({ id: "yes" })}
                                cancelText={this.props.intl.formatMessage({ id: "no" })}
                            >
                                <Button
                                    icon="delete"
                                    type="danger"
                                    disabled={!hasSelection}
                                    loading={loading}
                                    style={{ marginLeft: 8 }}
                                >
                                    {this.props.intl.formatMessage({ id: "measurement.delete" })}
                                </Button>
                            </Popconfirm>
                        )}
                </div>

                {manualInput && (
                    <Table
                        loading={loading}
                        dataSource={this.getManualData()}
                        columns={this.getManualColumns(false)}
                        size="small"
                        pagination={false}
                        style={{ marginBottom: "5px" }}
                    />
                )}

                {this.state.manualInputVisible && (
                    <AddCovermeasurementManualInput
                        visible={this.state.manualInputVisible}
                        handleCancel={this.onManualInputCancel.bind(this)}
                        handleOk={this.onManualInputOK.bind(this)}
                        wrappedComponentRef={this.saveManualInputFormRef.bind(this)}
                        error={this.state.manualInputError}
                        loading={this.state.manualInputLoading}
                        measurement={this.state.manualInput}
                    />
                )}

                {!manualInput && (
                    <Table
                        loading={loading}
                        rowSelection={rowSelection}
                        components={{
                            body: {
                                row: props => {
                                    const [{ opacity }, drag] = useDrag(() => ({
                                        type: "covermeasurements",
                                        item: () => {
                                            return {
                                                ids:
                                                    selectedRowKeys.length > 0
                                                        ? selectedRowKeys
                                                        : [props["data-row-key"]]
                                            };
                                        },
                                        end: (item, monitor) => {
                                            if (monitor.didDrop()) {
                                                const result: {
                                                    nodeId: string;
                                                } | null = monitor.getDropResult();
                                                if (result) {
                                                    const fileIds = item.ids;
                                                    const nodeId = result.nodeId;
                                                    this.onFilesMoved(fileIds, nodeId);
                                                }
                                            }
                                        },
                                        collect: monitor => ({
                                            opacity: monitor.isDragging() ? 0.4 : undefined
                                        })
                                    }));
                                    const isRowDisabled = props.className.indexOf("node-disabled") > -1;

                                    // TODO https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_hooks_ts/
                                    // 02-drag-around/custom-drag-layer?from-embed=&file=/src/CustomDragLayer.tsx
                                    return (
                                        <tr
                                            {...props}
                                            ref={
                                                this.props.dragFiles &&
                                                this.props.currentNode.status !== "DISABLED" &&
                                                !isRowDisabled
                                                    ? drag
                                                    : undefined
                                            }
                                            style={{
                                                cursor:
                                                    this.props.dragFiles &&
                                                    this.props.currentNode.status !== "DISABLED" &&
                                                    !isRowDisabled
                                                        ? "move"
                                                        : "default",
                                                opacity
                                            }}
                                        />
                                    );
                                }
                            }
                        }}
                        dataSource={this.getData()}
                        columns={this.getColumns(false)}
                        /*pagination={{
                            onChange: this.cancelThresholdChange // this also resets quality
                        }}*/ pagination={
                            false
                        }
                        size="small"
                        style={{ marginBottom: "5px" }}
                        scroll={{ y: 590 }}
                        rowClassName={(record, index) => {
                            if (record.nodeStatus === NodeStatus.DISABLED) {
                                return "node-disabled";
                            } else {
                                return "";
                            }
                        }}
                    />
                )}
                {!manualInput &&
                    this.canAddMeasurements() && (
                        <Upload.Dragger
                            action={
                                api.defaults.baseURL +
                                "project/" +
                                this.props.projectId +
                                "/uploadmeasurement?node=" +
                                this.props.currentNode.uuid
                            }
                            headers={{ authorization: `Bearer ${authService.getAccessToken()}` }}
                            onChange={this.onUploadChange.bind(this)}
                            beforeUpload={this.beforeUpload.bind(this)}
                            fileList={this.state.uploadFileList}
                            multiple={true}
                            accept=".csv"
                        >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">
                                <span>{this.props.intl.formatMessage({ id: "covermeasurement.upload" }) + " "}</span>
                                <span style={{ fontWeight: 500 }}>{this.props.currentNode.name}</span>
                            </p>
                        </Upload.Dragger>
                    )}
            </div>
        );
    }

    canEditMeasurements() {
        return this.props.currentNode.status !== NodeStatus.DISABLED;
    }

    canDeleteMeasurements() {
        return authService.hasScope("delete:projects") && this.props.currentNode.status !== NodeStatus.DISABLED;
    }

    canAddMeasurements() {
        const { currentNode, tree } = this.props;
        if (currentNode.status === NodeStatus.DISABLED) {
            return false;
        }
        if (currentNode.type) {
            return currentNode.type === "REINFORCEMENT";
        }
        if (tree.tree && currentNode.uuid === tree.tree.uuid) {
            return false;
        }

        return true;
    }

    onFilesMoved(measurementKeys: string[] | number[], targetNodeId: string) {
        this.props.onLoading(true);
        const treeCopy = cloneDeep(this.props.tree);
        if (treeCopy && treeCopy.tree) {
            // Delete in old place
            each(measurementKeys, measurementKey => {
                const measurementId = "" + measurementKey;
                let found = false;
                if (treeCopy && treeCopy.tree) {
                    projectService.loopCondition(
                        [treeCopy.tree],
                        (node: INode) => node.files.includes(measurementId),
                        (node: INode) => {
                            node.files = without(node.files, measurementId);
                            found = true;
                        }
                    );
                }
                if (!found) {
                    this.setState({
                        ...this.state,
                        error: "Measurement with id " + measurementId + " not found in tree"
                    });

                    this.props.onLoading(false);

                    return;
                }
            });

            // Add in new place
            each(measurementKeys, measurementKey => {
                const measurementId = "" + measurementKey;
                let found = false;
                if (treeCopy && treeCopy.tree) {
                    projectService.loopCondition(
                        [treeCopy.tree],
                        (node: INode) => node.uuid === targetNodeId,
                        (node: INode) => {
                            node.files = node.files.concat(measurementId);
                            found = true;
                        }
                    );
                }
                if (!found) {
                    this.setState({
                        ...this.state,
                        error: "Node with id " + targetNodeId + " not found in tree"
                    });

                    this.props.onLoading(false);

                    return;
                }
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(async modifiedTree => {
                    // Update graphs
                    const allGraphs = new Array<Promise<ICoverMeasurement>>();
                    each(measurementKeys, measurementKey => {
                        const measurementId = "" + measurementKey;
                        const coverMeasurement = this.state.measurements.find(m => m._id === measurementId);
                        if (
                            coverMeasurement &&
                            (coverMeasurement.propagatedThreshold.sourceLevel === PropagationLevel.NODE ||
                                coverMeasurement.propagatedCoverdepthQualityThreshold.sourceLevel ===
                                    PropagationLevel.NODE ||
                                coverMeasurement.propagatedCoverdepthOverlay.sourceLevel === PropagationLevel.NODE)
                        ) {
                            allGraphs.push(
                                covermeasurementService.generateCoverMeasurementGraph(
                                    treeCopy.projectId,
                                    coverMeasurement._id
                                )
                            );
                        }
                    });

                    await Promise.all(allGraphs);

                    notification["success"]({
                        message: this.props.intl.formatMessage({
                            id: "measurement.move.succeeded.title"
                        }),
                        description: this.props.intl.formatMessage({
                            id: "measurement.move.succeeded.message"
                        })
                    });
                    this.props.onEditNodes(modifiedTree);
                    this.props.onLoading(false);
                    this.fetchMeasurements(true);
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        error: err.message
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    }

    handleExport() {
        const div = document.createElement("div");
        if (this.state.manualInput) {
            ReactDOM.render(
                <IntlProvider
                    locale={this.props.intl.locale}
                    key={this.props.intl.locale}
                    messages={this.props.intl.messages}
                >
                    <Table
                        dataSource={this.getManualData()}
                        columns={this.getManualColumns(true)}
                        size="small"
                        style={{ width: "100%" }}
                        pagination={false}
                    />
                </IntlProvider>,
                div
            );
        } else {
            ReactDOM.render(
                <IntlProvider
                    locale={this.props.intl.locale}
                    key={this.props.intl.locale}
                    messages={this.props.intl.messages}
                >
                    <Table
                        dataSource={this.getData()}
                        columns={this.getColumns(true)}
                        size="small"
                        style={{ width: "100%" }}
                        pagination={false}
                    />
                </IntlProvider>,
                div
            );
        }

        Utils.copyToClip(div.innerHTML);
        notification["success"]({
            message: this.props.intl.formatMessage({
                id: "export"
            }),
            description: this.props.intl.formatMessage({
                id: "export.succeeded"
            })
        });
    }

    onEditManualInputClick() {
        this.onAddManualInputClick();
    }

    onAddManualInputClick() {
        this.setState({
            ...this.state,
            manualInputVisible: true
        });
    }

    onManualInputCancel() {
        this.setState(
            {
                ...this.state,
                manualInputVisible: false,
                manualInputLoading: false,
                manualInputError: undefined
            },
            () => {
                if (this.addManualInputForm) {
                    this.addManualInputForm.props.form.resetFields();
                }
            }
        );
    }

    onManualInputOK() {
        if (this.addManualInputForm) {
            const {
                form: { validateFields, getFieldsValue }
            } = this.addManualInputForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const fieldValues = getFieldsValue() as any;

            if (this.state.manualInput) {
                // update
                const measurement = cloneDeep(this.state.manualInput);
                measurement.average = fieldValues.average;
                measurement.standardDeviation = fieldValues.standardDeviation;

                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        manualInputLoading: true,
                        loading: true
                    },
                    () => {
                        covermeasurementService
                            .updateManualInputCovermeasurement(measurement)
                            .then(updatedMeasurement => {
                                this.onUpdateManualInput(updatedMeasurement._id, updatedMeasurement);
                            })
                            .catch(err => {
                                this.props.onLoading(false);
                                this.setState({
                                    ...this.state,
                                    manualInputLoading: false,
                                    manualInputError: err.message,
                                    loading: false
                                });
                            });
                    }
                );
            } else {
                // create
                const measurement = fieldValues as ICreateCoverMeasurementManualInput;

                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        manualInputLoading: true,
                        loading: true
                    },
                    () => {
                        covermeasurementService
                            .createManualInputCovermeasurement(measurement)
                            .then(updatedMeasurement => {
                                this.onUpdateManualInput(updatedMeasurement._id, updatedMeasurement);
                            })
                            .catch(err => {
                                this.props.onLoading(false);
                                this.setState({
                                    ...this.state,
                                    manualInputLoading: false,
                                    manualInputError: err.message,
                                    loading: false
                                });
                            });
                    }
                );
            }
        }
    }

    saveManualInputFormRef = (formRef: React.PureComponent<AddCovermeasurementManualInputProps>) => {
        this.addManualInputForm = formRef;
    };

    onUpdateManualInput(manualInputId?: string, updatedManualInput?: ICoverMeasurementManualInput) {
        const treeCopy = cloneDeep(this.props.tree);
        const rootNode = treeCopy.tree;
        if (rootNode) {
            projectService.loop([rootNode], this.props.currentNode.uuid, (node, index, arr) => {
                node.covermeasurementsManualInput = manualInputId;
                node.useManualInput = !!manualInputId;
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(modifiedTree => {
                    this.setState(
                        {
                            ...this.state,
                            manualInputLoading: false,
                            manualInputVisible: false,
                            loading: false,
                            manualInput: updatedManualInput
                        },
                        () => {
                            notification["success"]({
                                message: this.props.intl.formatMessage({
                                    id: "covermeasurement.edit"
                                }),
                                description: this.props.intl.formatMessage({
                                    id: "covermeasurement.edit.succeeded"
                                })
                            });
                            if (this.addManualInputForm) {
                                this.addManualInputForm.props.form.resetFields();
                            }
                        }
                    );

                    this.props.onLoading(false);
                    this.props.onEditNodes(modifiedTree);
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        manualInputLoading: false,
                        manualInputError: err.message,
                        error: err.message,
                        loading: false
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    }

    deleteManualInput() {
        if (this.state.manualInput) {
            const manualMeasurementId = this.state.manualInput._id;

            this.props.onLoading(true);
            this.setState(
                {
                    ...this.state,
                    manualInputLoading: true,
                    loading: true
                },
                () => {
                    covermeasurementService
                        .deleteManualInputCovermeasurement(manualMeasurementId)
                        .then(updatedManualMeasurement => {
                            this.onUpdateManualInput(undefined, undefined);
                        })
                        .catch(err => {
                            this.props.onLoading(false);
                            this.setState({
                                ...this.state,
                                manualInputLoading: false,
                                error: err.message,
                                loading: false
                            });
                        });
                }
            );
        }
    }

    onSelectChange = (selectedRowKeys: string[] | number[], selectedRows: IRowEntry[]) => {
        this.setState({ ...this.state, selectedRowKeys });
    };

    deleteMeasurements = (measurementKeys: string[] | number[]) => {
        this.props.onLoading(true);
        const treeCopy = cloneDeep(this.props.tree);
        if (treeCopy && treeCopy.tree) {
            each(measurementKeys, measurementKey => {
                const measurementId = "" + measurementKey;
                let found = false;
                if (treeCopy && treeCopy.tree) {
                    projectService.loopCondition(
                        [treeCopy.tree],
                        (node: INode) => node.files.includes(measurementId),
                        (node: INode) => {
                            node.files = without(node.files, measurementId);
                            found = true;
                        }
                    );
                }
                if (!found) {
                    this.setState({
                        ...this.state,
                        error: "Measurement with id " + measurementId + " not found in tree"
                    });

                    this.props.onLoading(false);

                    return;
                }
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(modifiedTree => {
                    notification["success"]({
                        message: this.props.intl.formatMessage({
                            id: "measurement.delete.succeeded.title"
                        }),
                        description: this.props.intl.formatMessage({
                            id: "measurement.delete.succeeded.message"
                        })
                    });
                    this.props.onEditNodes(modifiedTree);
                    this.props.onLoading(false);
                    this.fetchMeasurements(false);
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        error: err.message
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    };

    isEditingThreshold = (record: IRowEntry) =>
        record.key === this.state.editingKey && this.state.rowEditingField === RowEditingField.Threshold;

    isEditingQualityThreshold = (record: IRowEntry) =>
        record.key === this.state.editingKey && this.state.rowEditingField === RowEditingField.QualityThreshold;

    isEditingCoverdepthOverlay = (record: IRowEntry) =>
        record.key === this.state.editingKey && this.state.rowEditingField === RowEditingField.Overlay;

    isEditingHistogram = (record: IRowEntry) =>
        record.key === this.state.editingKey && this.state.rowEditingField === RowEditingField.Histogram;

    editThreshold = (record: IRowEntry) =>
        this.setState({
            editingKey: record.key,
            rowEditingField: RowEditingField.Threshold
        });

    editQualityThreshold = (record: IRowEntry) =>
        this.setState({
            editingKey: record.key,
            rowEditingField: RowEditingField.QualityThreshold
        });

    editCoverdepthOverlay = (record: IRowEntry) =>
        this.setState({
            editingKey: record.key,
            rowEditingField: RowEditingField.Overlay
        });

    editHistogram = (record: IRowEntry, edit: boolean) => {
        if (edit) {
            this.setState({
                editingKey: record.key,
                rowEditingField: RowEditingField.Histogram
            });
        } else {
            this.setState({ editingKey: undefined, rowEditingField: undefined });
        }
    };

    cancelThresholdChange = () => this.setState({ editingKey: undefined, rowEditingField: undefined });

    cancelQualityThresholdChange = () => this.setState({ editingKey: undefined, rowEditingField: undefined });

    cancelCoverdepthOverlayChange = () => this.setState({ editingKey: undefined, rowEditingField: undefined });

    saveThresholdChange = (newThreshold: number | undefined, key: string) => {
        const newMeasurements = [...this.state.measurements];
        const index = newMeasurements.findIndex(item => key === item._id);
        if (index > -1) {
            const item = newMeasurements[index];
            item.threshold = newThreshold;

            this.props.onLoading(true);

            return covermeasurementService
                .updateCovermeasurement(item, this.props.projectId)
                .then(() => {
                    if (this.state.rowEditingField !== RowEditingField.Histogram) {
                        this.setState({
                            ...this.state,
                            editingKey: undefined,
                            rowEditingField: undefined
                        });
                    }
                    notification["success"]({
                        message: this.props.intl.formatMessage({
                            id: "threshold.edit.succeeded.title"
                        }),
                        description: this.props.intl.formatMessage({
                            id: "threshold.edit.succeeded.message"
                        })
                    });
                    this.fetchMeasurements(true);
                    this.props.onChange();
                })
                .catch(() => {
                    this.setState({}, () => {
                        notification["error"]({
                            message: this.props.intl.formatMessage({
                                id: "threshold.edit.failed.title"
                            }),
                            description: this.props.intl.formatMessage({
                                id: "threshold.edit.failed.message"
                            })
                        });
                    });
                })
                .finally(() => {
                    this.props.onLoading(false);
                });
        } else {
            throw new Error("Current measurement not found. Adding measurements is not yet implemented.");
        }
    };

    saveQualityThresholdChange = (newThreshold: number | undefined, key: string) => {
        const newMeasurements = [...this.state.measurements];
        const index = newMeasurements.findIndex(item => key === item._id);
        if (index > -1) {
            const item = newMeasurements[index];
            item.coverdepthQualityThreshold = newThreshold;

            this.props.onLoading(true);

            return covermeasurementService
                .updateCovermeasurement(item, this.props.projectId)
                .then(() => {
                    if (this.state.rowEditingField !== RowEditingField.Histogram) {
                        this.setState({
                            ...this.state,
                            editingKey: undefined,
                            rowEditingField: undefined
                        });
                    }
                    notification["success"]({
                        message: this.props.intl.formatMessage({
                            id: "coverdepthQualityThreshold.edit.succeeded.title"
                        }),
                        description: this.props.intl.formatMessage({
                            id: "coverdepthQualityThreshold.edit.succeeded.message"
                        })
                    });
                    this.fetchMeasurements(true);
                    this.props.onChange();
                })
                .catch(() => {
                    this.setState({}, () => {
                        notification["error"]({
                            message: this.props.intl.formatMessage({
                                id: "coverdepthQualityThreshold.edit.failed.title"
                            }),
                            description: this.props.intl.formatMessage({
                                id: "coverdepthQualityThreshold.edit.failed.message"
                            })
                        });
                    });
                })
                .finally(() => {
                    this.props.onLoading(false);
                });
        } else {
            throw new Error("Current measurement not found. Adding measurements is not yet implemented.");
        }
    };

    saveCoverdepthOverlayChange = (newCoverdepthOverlay: number | undefined, key: string) => {
        const newMeasurements = [...this.state.measurements];
        const index = newMeasurements.findIndex(item => key === item._id);
        if (index > -1) {
            const item = newMeasurements[index];
            item.coverdepthOverlay = newCoverdepthOverlay;

            this.props.onLoading(true);

            return covermeasurementService
                .updateCovermeasurement(item, this.props.projectId)
                .then(() => {
                    if (this.state.rowEditingField !== RowEditingField.Histogram) {
                        this.setState({
                            ...this.state,
                            editingKey: undefined,
                            rowEditingField: undefined
                        });
                    }
                    notification["success"]({
                        message: this.props.intl.formatMessage({
                            id: "coverdepthOverlay.edit.succeeded.title"
                        }),
                        description: this.props.intl.formatMessage({
                            id: "coverdepthOverlay.edit.succeeded.message"
                        })
                    });
                    this.fetchMeasurements(true);
                    this.props.onChange();
                })
                .catch(() => {
                    this.setState({}, () => {
                        notification["error"]({
                            message: this.props.intl.formatMessage({
                                id: "coverdepthOverlay.edit.failed.title"
                            }),
                            description: this.props.intl.formatMessage({
                                id: "coverdepthOverlay.edit.failed.message"
                            })
                        });
                    });
                })
                .finally(() => {
                    this.props.onLoading(false);
                });
        } else {
            throw new Error("Current measurement not found. Adding measurements is not yet implemented.");
        }
    };

    beforeUpload = (file: RcFile, fileList: RcFile[]) => {
        if (
            (file.type !== "text/csv" && file.type !== "application/vnd.ms-excel") ||
            some(fileList, f => f.type !== "text/csv" && f.type !== "application/vnd.ms-excel")
        ) {
            this.setState(
                {
                    ...this.state,
                    uploadFileList: []
                },
                () => {
                    notification["error"]({
                        message: this.props.intl.formatMessage({
                            id: "covermeasurement.upload.failed"
                        }),
                        description: this.props.intl.formatMessage({
                            id: "covermeasurement.upload.nocsv"
                        })
                    });
                }
            );

            return false;
        }

        return new Promise<void>((resolve, reject) => {
            this.setState(
                {
                    ...this.state,
                    loading: true
                },
                () => {
                    resolve();
                }
            );
        });
    };

    onUploadChange = (info: UploadChangeParam) => {
        console.log("upload state: ", info.file.status);

        if (info.file.status === undefined) {
            // e.g. not a CSV file
            this.setState({ ...this.state, uploadFileList: [] });
        } else {
            this.setState({ ...this.state, uploadFileList: info.fileList });
        }

        if (info.file.status === "done" || info.file.status === "error") {
            this.setState({ ...this.state, uploadFileList: [] });
            console.log("done or error");
            if (info.file.status === "error") {
                notification["error"]({
                    message: this.props.intl.formatMessage({
                        id: "covermeasurement.upload.failed"
                    }),
                    description: this.props.intl.formatMessage(
                        {
                            id: "covermeasurement.upload.connection"
                        },
                        { file: info.file.name }
                    )
                });
            }

            this.fetchMeasurements(true);
            this.props.onChange();
        }
    };
}

export default injectIntl(CoverMeasurements);
