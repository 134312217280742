import { cloneDeep, each, some, uniq } from "lodash-es";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Utils from "./utils";

import { InjectedIntlProps, injectIntl } from "react-intl";
import "./chlorides-messages";

import authService from "../../services/API/auth-service";
import chlorideService from "../../services/API/chloride-service";
import projectService from "../../services/API/project-service";
import {
    ChlorideStatus,
    ChloridesStatus,
    IChlorideMeasurement,
    ICreateChlorideMeasurement,
    INode,
    IProjectTree,
    NodeStatus
} from "../../store/projects/types";

import { AddChlorideParameters, AddChlorideParametersProps } from "./addchlorideparameters";
import { AddChlorides, AddChloridesProps } from "./addchlorides";

import {
    Alert,
    Button,
    Col,
    ConfigProvider,
    Empty,
    Form,
    Icon,
    Popconfirm,
    Row,
    Spin,
    Table,
    notification
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { ColumnProps, PaginationConfig, SorterResult, TableCurrentDataSource } from "antd/lib/table";
import { EditableCell, EditableContext } from "../../components/editableCell/editableCell";
import { emptyImg } from "./utils";

export const D_UNIT = 0.000001; // #1E-6

interface IState {
    error?: string;
    loading: boolean;
    chlorideMeasurement?: IChlorideMeasurement | ICreateChlorideMeasurement;
    editedChlorideMeasurement?: IChlorideMeasurement | ICreateChlorideMeasurement;

    combinedGraphLoading: boolean;

    sortInfo: {
        columnKey: string;
        order: "ascend" | "descend";
    };

    addVisible: boolean;
    addLoading: boolean;
    addError?: string;

    addParametersVisible: boolean;
    addParametersLoading: boolean;
    addParametersError?: string;
}

interface IOwnProps {
    projectId: string;
    tree: IProjectTree;
    currentNode: INode;

    onEditNodes: (tree: IProjectTree, dontSetDirty?: boolean) => void;
    onLoading: (loading: boolean) => void;
}

type AllProps = IOwnProps & InjectedIntlProps & FormComponentProps;

interface IRowEntry {
    key: number;
    depth: number;
    value: number;
    active: number;
}

interface IOwnColumnProps {
    editable: boolean;
    autoFocus: boolean;
}

type AllColumnProps = IOwnColumnProps & ColumnProps<IRowEntry>;

class Chlorides extends React.PureComponent<AllProps, IState> {
    addChloridesForm?: React.Component<AddChloridesProps>;
    addChlorideParametersForm?: React.Component<AddChlorideParametersProps>;
    constructor(props: AllProps) {
        super(props);

        this.state = {
            error: undefined,
            loading: false,
            chlorideMeasurement: undefined,
            editedChlorideMeasurement: undefined,

            combinedGraphLoading: false,

            sortInfo: {
                columnKey: "depth",
                order: "ascend"
            },

            addVisible: false,
            addLoading: false,
            addError: undefined,

            addParametersVisible: false,
            addParametersLoading: false,
            addParametersError: undefined
        };
    }

    renderValueUnit(key: string) {
        return (
            this.props.intl.formatMessage({
                id: key
            }) +
            " [" +
            this.props.intl.formatMessage({
                id: "chlorides.value.unit"
            }) +
            "]"
        );
    }

    renderDepthUnit(key: string) {
        return (
            this.props.intl.formatMessage({
                id: key
            }) +
            " [" +
            this.props.intl.formatMessage({
                id: "chlorides.depth.unit"
            }) +
            "]"
        );
    }

    getColumns(copy: boolean) {
        const { sortInfo } = this.state;

        const columns: AllColumnProps[] = [];

        if (this.props.currentNode.useManualChloridesInput) {
            columns.push({
                title: this.props.intl.formatMessage({
                    id: "chlorides.addParameters.title"
                }),
                dataIndex: "parameters",
                autoFocus: true,
                sorter: undefined,
                sortOrder: false,
                editable: true
            });
        } else {
            columns.push({
                title: this.props.intl.formatMessage({
                    id: "chlorides.active"
                }),
                editable: false,
                dataIndex: "active",
                autoFocus: false,
                width: "70px",
                render: (text: string, record: IRowEntry) => {
                    if (copy) {
                        return <span>{record.active}</span>;
                    } else {
                        return (
                            <span
                                className={"ant-checkbox" + (record.active ? " ant-checkbox-checked" : "")}
                                onClick={() => {
                                    if (this.state.editedChlorideMeasurement) {
                                        this.onClickActive(record);
                                    }
                                }}
                            >
                                <input type="checkbox" className="ant-checkbox-input" />
                                <span className="ant-checkbox-inner" />
                            </span>
                        );
                    }
                },
                className: "ant-table-selection-column"
            });
            columns.push({
                title: this.renderDepthUnit("chlorides.depth"),
                dataIndex: "depth",
                autoFocus: true,
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.depth - b.depth;
                },
                sortOrder: this.state.editedChlorideMeasurement
                    ? false
                    : sortInfo.columnKey === "depth" && sortInfo.order,
                editable: true
            });
            columns.push({
                title: this.renderValueUnit("chlorides.value"),
                dataIndex: "value",
                autoFocus: false,
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.value - b.value;
                },
                sortOrder: this.state.editedChlorideMeasurement
                    ? false
                    : sortInfo.columnKey === "value" && sortInfo.order,
                editable: true
            });

            if (this.state.editedChlorideMeasurement) {
                columns.push({
                    title: this.props.intl.formatMessage({
                        id: "chlorides.actions"
                    }),
                    dataIndex: "actions",
                    autoFocus: false,
                    editable: false,
                    render: (text: string, record: IRowEntry) => {
                        return (
                            <span style={{ whiteSpace: "nowrap" }}>
                                {authService.hasScope("delete:projects") && (
                                    <Popconfirm
                                        icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                        title={this.props.intl.formatMessage({ id: "chloride.delete.confirm" })}
                                        onConfirm={this.onDeleteChlorideMeasurement.bind(this, record)}
                                        okText={this.props.intl.formatMessage({ id: "yes" })}
                                        cancelText={this.props.intl.formatMessage({ id: "no" })}
                                    >
                                        <Button icon="delete" type="danger" />
                                    </Popconfirm>
                                )}
                                <Button
                                    icon="plus"
                                    style={{ marginLeft: 2 }}
                                    onClick={this.onAddChlorideMeasurement.bind(this, record)}
                                />
                            </span>
                        );
                    }
                });
            }
        }

        const mappedColumns = columns.map(col => {
            return {
                ...col,
                onCell: (record: IRowEntry) => ({
                    editable: col.editable && this.state.editedChlorideMeasurement !== undefined,
                    record,
                    dataIndex: col.dataIndex,
                    autoFocus: col.autoFocus,
                    title: col.title,
                    handleCellSave: this.handleCellSave.bind(this)
                })
            };
        });

        return mappedColumns;
    }

    getData() {
        const { chlorideMeasurement, editedChlorideMeasurement } = this.state;
        const data: IRowEntry[] = [];
        let measurement: IChlorideMeasurement | ICreateChlorideMeasurement | undefined;

        if (editedChlorideMeasurement) {
            // editing mode
            measurement = editedChlorideMeasurement;
        } else {
            // non-editing mode
            measurement = chlorideMeasurement;
        }

        if (measurement) {
            each(measurement.positions, (value, index, arr) => {
                const depth = value;
                const chloride = measurement!.measurements[index];
                let active = measurement!.active[index];

                if (active === undefined) {
                    // migration
                    active = 1;
                }

                data.push({
                    key: index,
                    active,
                    depth,
                    value: Utils.formatNumber(chloride)
                } as IRowEntry);
            });
        }

        return data;
    }

    customizeRenderEmpty = () => {
        let labelStyle: React.CSSProperties;
        labelStyle = { fontWeight: 500, paddingLeft: "20px", paddingRight: "4px" };

        if (this.props.currentNode.useManualChloridesInput) {
            return (
                <div style={{ display: "flex", color: "rgba(0, 0, 0, 0.65)", flexDirection: "column", gap: "8px" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ float: "left", textAlign: "left" }}>
                            <Col>
                                <Row style={labelStyle}>{this.props.intl.formatMessage({ id: "chlorides.d" })}:</Row>
                                <Row style={labelStyle}>{this.props.intl.formatMessage({ id: "chlorides.cs" })}:</Row>
                                <Row style={labelStyle}>{this.props.intl.formatMessage({ id: "chlorides.ci" })}:</Row>
                            </Col>
                        </div>
                        <div style={{ float: "left", textAlign: "left" }}>
                            <Col>
                                <Row>
                                    {this.props.currentNode.D !== undefined && this.props.currentNode.D / D_UNIT}{" "}
                                    {this.props.intl.formatMessage({ id: "chlorides.d.unit" })}
                                </Row>
                                <Row>
                                    {this.props.currentNode.Cs !== undefined && this.props.currentNode.Cs}{" "}
                                    {this.props.intl.formatMessage({ id: "chlorides.unit" })}
                                </Row>
                                <Row>
                                    {this.props.currentNode.Ci !== undefined && this.props.currentNode.Ci}{" "}
                                    {this.props.intl.formatMessage({ id: "chlorides.unit" })}
                                </Row>
                            </Col>
                        </div>
                    </div>
                    {this.canEditChlorides() && (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                marginTop: "16px"
                            }}
                        >
                            <Button type="primary" onClick={e => this.onAddChlorideParametersButtonClick()}>
                                {this.props.intl.formatMessage({ id: "chlorides.editParameters" })}
                            </Button>
                            <Popconfirm
                                icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                title={this.props.intl.formatMessage(
                                    { id: "chlorides.deleteParameters.confirm" },
                                    { number: 1 }
                                )}
                                onConfirm={() => this.onDeleteChlorideParameters()}
                                okText={this.props.intl.formatMessage({ id: "yes" })}
                                cancelText={this.props.intl.formatMessage({ id: "no" })}
                            >
                                <Button type="primary">
                                    {this.props.intl.formatMessage({ id: "chlorides.deleteParameters" })}
                                </Button>
                            </Popconfirm>
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <Empty image={emptyImg} className="ant-empty ant-empty-normal">
                    <div
                        style={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center"
                        }}
                    >
                        {this.canEditChlorides() && (
                            <React.Fragment>
                                <Button type="primary" onClick={e => this.onAddChlorideMeasurement()}>
                                    {this.props.intl.formatMessage({ id: "chlorides.add" })}
                                </Button>
                                <Button type="primary" onClick={e => this.onAddChlorideMeasurementAsText()}>
                                    {this.props.intl.formatMessage({ id: "chlorides.addAsText" })}
                                </Button>
                                <Button type="primary" onClick={e => this.onAddChlorideParametersButtonClick()}>
                                    {this.props.intl.formatMessage({ id: "chlorides.addParameters" })}
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </Empty>
            );
        }
    };

    getImage() {
        const { chlorideMeasurement, editedChlorideMeasurement } = this.state;
        let measurement: IChlorideMeasurement | ICreateChlorideMeasurement | undefined;

        if (editedChlorideMeasurement) {
            // editing mode
            measurement = editedChlorideMeasurement;
        } else {
            // non-editing mode
            measurement = chlorideMeasurement;
        }

        if (measurement) {
            switch (measurement.status) {
                case ChloridesStatus.Done:
                    return (
                        <img
                            width={640}
                            height={480}
                            src={Utils.getDownloadURL(measurement.graphURL) + "?" + Date.now()}
                        />
                    );
                case ChloridesStatus.GraphProcessing:
                    return (
                        <Spin
                            tip={this.props.intl.formatMessage({
                                id: "chlorides.generatingGraph"
                            })}
                        >
                            <div style={{ width: "640px", height: "480px", backgroundColor: "#eee" }} />
                        </Spin>
                    );
                case ChloridesStatus.Dirty:
                    return (
                        <div
                            style={{
                                width: "640px",
                                height: "480px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#eee"
                            }}
                        >
                            <Button type="primary" onClick={e => this.generateGraph()}>
                                {this.props.intl.formatMessage({
                                    id: "chlorides.generateGraph"
                                })}
                            </Button>
                        </div>
                    );
                case ChloridesStatus.Failed:
                    return (
                        <div
                            style={{
                                width: "640px",
                                height: "480px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#eee"
                            }}
                        >
                            <Alert
                                message={this.props.intl.formatMessage({
                                    id: "error"
                                })}
                                description={this.props.intl.formatMessage({
                                    id: "chlorides.generateGraphError"
                                })}
                                type="error"
                                showIcon
                            />
                            <Button type="primary" onClick={e => this.generateGraph()} style={{ marginTop: "8px" }}>
                                {this.props.intl.formatMessage({
                                    id: "chlorides.generateGraph"
                                })}
                            </Button>
                        </div>
                    );
            }
        } else {
            if (this.props.currentNode.chlorideGraphURL) {
                return (
                    <img
                        width={640}
                        height={480}
                        src={Utils.getDownloadURL(this.props.currentNode.chlorideGraphURL) + "?" + Date.now()}
                    />
                );
            } else {
                if (this.state.combinedGraphLoading) {
                    return (
                        <Spin
                            tip={this.props.intl.formatMessage({
                                id: "chlorides.generatingGraph"
                            })}
                        >
                            <div style={{ width: "640px", height: "480px", backgroundColor: "#eee" }} />
                        </Spin>
                    );
                } else {
                    const hasCombinedGraph = this.hasCombinedGraph();

                    return (
                        <div
                            style={{
                                width: "640px",
                                height: "480px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#eee"
                            }}
                        >
                            {hasCombinedGraph && (
                                <Button type="primary" onClick={e => this.generateCombinedGraph()}>
                                    {this.props.intl.formatMessage({
                                        id: "chlorides.generateGraph"
                                    })}
                                </Button>
                            )}
                            {!hasCombinedGraph && (
                                <div>
                                    {this.props.intl.formatMessage({
                                        id: "chlorides.noCombinedGraph"
                                    })}
                                </div>
                            )}
                        </div>
                    );
                }
            }
        }
    }

    // This code is duplicated
    getManualChlorideWarning(node: INode) {
        if (node.useManualChloridesInput) {
            return this.props.intl.formatMessage({ id: "chlorides.warningManualPropagation" });
        }

        return undefined;
    }

    getNodeWarning(node: INode) {
        if (node.chlorideCalculationStatus) {
            if (
                node.chlorideCalculationStatus === ChlorideStatus.ACCEPTED_D_TIME_ANALYSIS ||
                node.chlorideCalculationStatus === ChlorideStatus.NO_CHLORIDE_CALCULATION_NO_DATA
            ) {
                // OK or no data => no warning
                return this.getManualChlorideWarning(node);
            } else {
                return this.props.intl.formatMessage({ id: "chlorides." + node.chlorideCalculationStatus });
            }
        }

        return this.getManualChlorideWarning(node);
    }

    canEditChlorides() {
        return this.props.currentNode.status !== NodeStatus.DISABLED;
    }

    render() {
        const { error, loading } = this.state;

        const nodeWarning = this.getNodeWarning(this.props.currentNode);

        const data = this.getData();

        const components = {
            body: {
                cell: EditableCell
            }
        };

        return (
            <div>
                {error && (
                    <Alert
                        style={{ marginBottom: "10px" }}
                        message={this.props.intl.formatMessage({
                            id: "error"
                        })}
                        description={error}
                        type="error"
                        showIcon
                    />
                )}
                {nodeWarning && (
                    <Alert
                        style={{ marginBottom: "10px" }}
                        message={this.props.intl.formatMessage({
                            id: "warning"
                        })}
                        description={nodeWarning}
                        type="warning"
                        showIcon
                    />
                )}
                <div style={{ marginBottom: "16px" }}>
                    {!this.state.editedChlorideMeasurement &&
                        !this.props.currentNode.useManualChloridesInput && (
                            <Button icon="export" onClick={this.handleExport.bind(this)}>
                                {this.props.intl.formatMessage({ id: "export" })}
                            </Button>
                        )}
                    {authService.hasScope("delete:projects") &&
                        this.canEditChlorides() &&
                        this.state.chlorideMeasurement &&
                        this.isAlreadyExistingMeasurement(this.state.chlorideMeasurement) && (
                            <Popconfirm
                                icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                title={this.props.intl.formatMessage({ id: "chlorides.delete.confirm" })}
                                onConfirm={this.onDeleteChloridesMeasurements.bind(this)}
                                okText={this.props.intl.formatMessage({ id: "yes" })}
                                cancelText={this.props.intl.formatMessage({ id: "no" })}
                            >
                                <Button icon="delete" type="danger" key="deleteChlorides" style={{ marginLeft: "8px" }}>
                                    {this.props.intl.formatMessage({ id: "chlorides.delete" })}
                                </Button>
                            </Popconfirm>
                        )}
                    {this.canEditChlorides() &&
                        this.state.chlorideMeasurement &&
                        this.isAlreadyExistingMeasurement(this.state.chlorideMeasurement) &&
                        !this.state.editedChlorideMeasurement && (
                            <Button
                                icon="edit"
                                key="editChlorides"
                                style={{ marginLeft: "8px" }}
                                onClick={this.onEditChlorideMeasurements.bind(this)}
                            >
                                {this.props.intl.formatMessage({ id: "chlorides.edit" })}
                            </Button>
                        )}
                    {this.canEditChlorides() &&
                        this.state.editedChlorideMeasurement && (
                            <Button
                                type="primary"
                                icon="save"
                                key="saveChlorides"
                                style={{ marginLeft: "8px" }}
                                onClick={this.onSaveChlorideMeasurements.bind(this)}
                            >
                                {this.props.intl.formatMessage({ id: "chlorides.save" })}
                            </Button>
                        )}
                    {this.canEditChlorides() &&
                        this.state.editedChlorideMeasurement && (
                            <Button
                                icon="undo"
                                key="cancelChlorides"
                                style={{ marginLeft: "8px" }}
                                onClick={this.onCancelChlorideMeasurements.bind(this)}
                            >
                                {this.props.intl.formatMessage({ id: "chlorides.cancel" })}
                            </Button>
                        )}
                    {this.canEditChlorides() &&
                        this.state.chlorideMeasurement &&
                        this.isAlreadyExistingMeasurement(this.state.chlorideMeasurement) &&
                        !this.state.editedChlorideMeasurement && (
                            <Button
                                icon="edit"
                                onClick={this.onAddChloridesButtonClick.bind(this)}
                                key="editChloridesAsText"
                                style={{ marginLeft: "8px" }}
                            >
                                {this.props.intl.formatMessage({ id: "chlorides.editAsText" })}
                            </Button>
                        )}
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "1 1 auto" }} onDoubleClick={this.onDoubleClick.bind(this)}>
                        <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                            <EditableContext.Provider value={this.props.form}>
                                <Table
                                    loading={loading}
                                    components={components}
                                    dataSource={data}
                                    columns={this.getColumns(false)}
                                    pagination={false}
                                    size="small"
                                    onChange={this.onTableChange.bind(this)}
                                />
                            </EditableContext.Provider>
                        </ConfigProvider>
                    </div>
                    <div style={{ flex: "0 0 auto", marginLeft: "8px" }}>{this.getImage()}</div>
                </div>
                {this.state.addVisible &&
                    !this.state.editedChlorideMeasurement && (
                        <AddChlorides
                            visible={this.state.addVisible}
                            handleCancel={this.onAddChloridesCancel.bind(this)}
                            handleOk={this.onAddChloridesOK.bind(this)}
                            wrappedComponentRef={this.saveAddChloridesFormRef.bind(this)}
                            error={this.state.addError}
                            loading={this.state.addLoading}
                            measurement={this.state.chlorideMeasurement}
                        />
                    )}

                {this.state.addParametersVisible &&
                    !this.state.editedChlorideMeasurement && (
                        <AddChlorideParameters
                            visible={this.state.addParametersVisible}
                            handleCancel={this.onAddChlorideParametersCancel.bind(this)}
                            handleOk={this.onAddChlorideParametersOK.bind(this)}
                            wrappedComponentRef={this.saveAddChlorideParametersFormRef.bind(this)}
                            error={this.state.addParametersError}
                            loading={this.state.addParametersLoading}
                            node={this.props.currentNode}
                        />
                    )}
            </div>
        );
    }

    onTableChange(
        pagination: PaginationConfig,
        filters: Partial<Record<keyof IRowEntry, string[]>>,
        sorter: SorterResult<IRowEntry>,
        extra: TableCurrentDataSource<IRowEntry>
    ) {
        this.setState({
            ...this.state,
            sortInfo: {
                columnKey: sorter.columnKey,
                order: sorter.order
            }
        });
    }

    handleExport() {
        const div = document.createElement("div");
        ReactDOM.render(
            <Table dataSource={this.getData()} columns={this.getColumns(true)} size="small" pagination={false} />,
            div
        );
        Utils.copyToClip(div.innerHTML);
        notification["success"]({
            message: this.props.intl.formatMessage({
                id: "export"
            }),
            description: this.props.intl.formatMessage({
                id: "export.succeeded"
            })
        });
    }

    onDoubleClick() {
        if (this.canEditChlorides() && this.state.chlorideMeasurement && !this.state.editedChlorideMeasurement) {
            this.onEditChlorideMeasurements();
        }
    }

    onEditChlorideMeasurements() {
        const { chlorideMeasurement } = this.state;
        if (chlorideMeasurement) {
            const measurement = cloneDeep(chlorideMeasurement);
            this.setState({
                ...this.state,
                editedChlorideMeasurement: measurement
            });
        }
    }

    onSaveChlorideMeasurements() {
        if (this.props.form && this.props.form.validateFields) {
            let isValidForm = true;
            this.props.form.validateFields((error, values) => {
                if (error) {
                    isValidForm = false;
                }

                if (!isValidForm) {
                    return;
                }

                if (this.state.editedChlorideMeasurement) {
                    const measurement = cloneDeep(this.state.editedChlorideMeasurement);
                    measurement.status = ChloridesStatus.Dirty;

                    const uniquePositions = uniq(measurement.positions);
                    if (uniquePositions.length !== measurement.positions.length) {
                        // there were duplicates
                        this.setState({
                            ...this.state,
                            error: this.props.intl.formatMessage({
                                id: "chlorides.notUniq"
                            })
                        });

                        return;
                    }

                    if (this.isAlreadyExistingMeasurement(measurement)) {
                        this.onChangedMeasurement(measurement);
                    } else {
                        this.onCreatedMeasurement(measurement);
                    }
                }
            });
        }
    }

    isAlreadyExistingMeasurement(object: any): object is IChlorideMeasurement {
        return "_id" in object;
    }

    onCancelChlorideMeasurements() {
        this.setState({
            ...this.state,
            error: undefined,
            editedChlorideMeasurement: undefined
        });
    }

    onClickActive(record: IRowEntry) {
        const { editedChlorideMeasurement } = this.state;
        if (editedChlorideMeasurement) {
            const measurement = cloneDeep(editedChlorideMeasurement);

            const i = record.key;
            measurement.active[i] = measurement.active[i] > 0 ? 0 : 1;

            this.setState({
                ...this.state,
                editedChlorideMeasurement: measurement
            });
        }
    }

    onAddChlorideMeasurement(previousRecord?: IRowEntry) {
        const { editedChlorideMeasurement } = this.state;
        if (editedChlorideMeasurement) {
            const measurement = cloneDeep(editedChlorideMeasurement);
            let i = 0;
            if (previousRecord) {
                i = previousRecord.key;
            }
            // add a row
            i++;
            measurement.positions.splice(i, 0, 0);
            measurement.active.splice(i, 0, 1);
            measurement.measurements.splice(i, 0, 0);

            this.setState({
                ...this.state,
                editedChlorideMeasurement: measurement
            });
        } else {
            // create
            const measurement = {
                positions: [0],
                measurements: [0],
                active: [1],
                status: ChloridesStatus.Dirty
            };

            this.setState({
                ...this.state,
                editedChlorideMeasurement: measurement
            });

            // The creation is now handled in onSaveChlorideMeasurements
            //  this.onCreatedMeasurement(measurement);
        }
    }

    onAddChlorideMeasurementAsText() {
        // create
        const measurement = {
            positions: [],
            measurements: [],
            active: [],
            status: ChloridesStatus.Dirty
        };

        this.setState({
            ...this.state,
            addVisible: true,
            chlorideMeasurement: measurement
        });
    }

    // delete a single one
    onDeleteChlorideMeasurement(record: IRowEntry) {
        const { editedChlorideMeasurement } = this.state;
        if (editedChlorideMeasurement) {
            const i = record.key;
            const measurement = cloneDeep(editedChlorideMeasurement);

            // remove a row
            measurement.positions.splice(i, 1);
            measurement.active.splice(i, 1);
            measurement.measurements.splice(i, 1);

            this.setState({
                ...this.state,
                editedChlorideMeasurement: measurement
            });
        }
    }

    // delete all
    onDeleteChloridesMeasurements() {
        if (this.state.chlorideMeasurement && this.isAlreadyExistingMeasurement(this.state.chlorideMeasurement)) {
            const measurementId = this.state.chlorideMeasurement._id;

            this.props.onLoading(true);
            this.setState(
                {
                    ...this.state,
                    loading: true
                },
                () => {
                    chlorideService
                        .deleteChlorideMeasurement(measurementId)
                        .then(updatedMeasurement => {
                            this.onUpdatedMeasurement(undefined, undefined);
                        })
                        .catch(err => {
                            this.props.onLoading(false);
                            this.setState({
                                ...this.state,
                                error: err.message,
                                loading: false
                            });
                        });
                }
            );
        }
    }

    handleCellSave(record: any) {
        const { editedChlorideMeasurement } = this.state;
        if (editedChlorideMeasurement) {
            const measurement = cloneDeep(editedChlorideMeasurement);

            const i = record.key;
            const pos = parseFloat(record.depth);
            const meas = parseFloat(record.value);

            measurement.positions[i] = pos;
            measurement.measurements[i] = meas;

            this.setState({
                ...this.state,
                editedChlorideMeasurement: measurement
            });
        }
    }

    // call after changing the measurement to save it
    onChangedMeasurement(measurement: IChlorideMeasurement) {
        this.props.onLoading(true);
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                chlorideService
                    .updateChlorideMeasurement(measurement)
                    .then(updatedMeasurement => {
                        this.onUpdatedMeasurement(updatedMeasurement._id, updatedMeasurement);
                    })
                    .catch(err => {
                        this.props.onLoading(false);
                        this.setState({
                            ...this.state,
                            error: err.message,
                            loading: false,
                            addLoading: false,
                            addVisible: false
                        });
                    });
            }
        );
    }

    // call after creating a new chloridemeasurement
    onCreatedMeasurement(createdMeasurement: ICreateChlorideMeasurement) {
        this.props.onLoading(true);
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                chlorideService
                    .createChlorideMeasurement(createdMeasurement)
                    .then(updatedMeasurement => {
                        this.onUpdatedMeasurement(updatedMeasurement._id, updatedMeasurement);
                    })
                    .catch(err => {
                        this.props.onLoading(false);
                        this.setState({
                            ...this.state,
                            error: err.message,
                            loading: false,
                            addLoading: false,
                            addVisible: false
                        });
                    });
            }
        );
    }

    // call after an updated chloride measurement (from backend)
    onUpdatedMeasurement(updatedMeasurementId?: string, updatedMeasurement?: IChlorideMeasurement) {
        const treeCopy = cloneDeep(this.props.tree);
        const rootNode = treeCopy.tree;
        if (rootNode) {
            projectService.loop([rootNode], this.props.currentNode.uuid, (node, index, arr) => {
                node.chlorides = updatedMeasurementId;
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(modifiedTree => {
                    return chlorideService.clearParentGraphs(this.props.currentNode.uuid, this.props.projectId);
                })
                .then(cleanedTree => {
                    this.setState(
                        {
                            ...this.state,
                            loading: false,
                            chlorideMeasurement: updatedMeasurement,
                            editedChlorideMeasurement: undefined,
                            addLoading: false,
                            addVisible: false
                        },
                        () => {
                            notification["success"]({
                                message: this.props.intl.formatMessage({
                                    id: "chlorides.edit"
                                }),
                                description: this.props.intl.formatMessage({
                                    id: "chlorides.edit.succeeded"
                                })
                            });
                            this.props.onLoading(false);
                            console.log("onUpdatedMeasurement Modified tree", cleanedTree);
                            this.props.onEditNodes(cleanedTree);
                        }
                    );
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        error: err.message,
                        loading: false
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    }

    fetchMeasurements() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                if (this.props.currentNode.chlorides) {
                    chlorideService
                        .getChlorideMeasurement(this.props.currentNode.chlorides)
                        .then(measurement => {
                            this.setState(
                                {
                                    ...this.state,
                                    chlorideMeasurement: measurement,
                                    editedChlorideMeasurement: undefined,
                                    loading: false,
                                    error: undefined
                                },
                                () => {
                                    this.regenerateGraphIfNeeded();
                                }
                            );
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                error: err.message,
                                chlorideMeasurement: undefined,
                                editedChlorideMeasurement: undefined,
                                loading: false
                            });
                        });
                } else {
                    this.setState({
                        ...this.state,
                        chlorideMeasurement: undefined,
                        editedChlorideMeasurement: undefined,
                        loading: false
                    });
                }
            }
        );
    }

    generateGraph() {
        if (this.props.currentNode.chlorides) {
            if (this.state.chlorideMeasurement) {
                const measurement = cloneDeep(this.state.chlorideMeasurement);
                measurement.status = ChloridesStatus.GraphProcessing; // this is also set on the server
                this.setState({
                    ...this.state,
                    chlorideMeasurement: measurement
                });
            }

            chlorideService
                .generateGraph(this.props.currentNode.chlorides, this.props.currentNode.uuid, this.props.projectId)
                .then(measurement => {
                    this.setState({
                        ...this.state,
                        chlorideMeasurement: measurement,
                        loading: false,
                        error: undefined
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        error: err.message,
                        chlorideMeasurement: undefined,
                        loading: false
                    });
                });
        }
    }

    generateCombinedGraph() {
        this.setState(
            {
                ...this.state,
                combinedGraphLoading: true
            },
            () => {
                chlorideService
                    .generateCombinedGraph(this.props.projectId, this.props.currentNode.uuid)
                    .then(() => {
                        projectService
                            .getNodes(this.props.projectId)
                            .then(tree => {
                                this.props.onEditNodes(tree, true);
                            })
                            .finally(() => {
                                this.setState({
                                    ...this.state,
                                    combinedGraphLoading: false
                                });
                            });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            error: err.message,
                            chlorideMeasurement: undefined,
                            loading: false,
                            combinedGraphLoading: false
                        });
                    });
            }
        );
    }

    hasCombinedGraph() {
        let hasCombinedGraph = false;
        if (this.props.tree.tree) {
            projectService.loop([this.props.tree.tree], this.props.currentNode.uuid, node => {
                hasCombinedGraph = !!node.useManualChloridesInput || this.hasTreeChloride(node);
            });
        }

        return hasCombinedGraph;
    }

    hasTreeChloride(node: INode): boolean {
        if (node.chlorides) {
            return true;
        } else {
            return some(node.children, child => {
                return this.hasTreeChloride(child);
            });
        }
    }

    saveAddChloridesFormRef = (formRef: React.PureComponent<AddChloridesProps>) => {
        this.addChloridesForm = formRef;
    };

    onAddChloridesButtonClick() {
        this.setState({
            ...this.state,
            addVisible: true
        });
    }

    onAddChloridesCancel() {
        this.setState(
            {
                ...this.state,
                addVisible: false,
                addLoading: false,
                addError: undefined
            },
            () => {
                if (this.addChloridesForm) {
                    this.addChloridesForm.props.form.resetFields();
                }
            }
        );
    }

    onAddChloridesOK() {
        if (this.addChloridesForm) {
            const {
                form: { validateFields, getFieldsValue }
            } = this.addChloridesForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const fieldValues = getFieldsValue() as any;

            const positions: number[] = [];
            const measurements: number[] = [];
            const active: number[] = [];

            // Parse
            const lines: string[] = fieldValues.values.split(/\n/gi);
            each(lines, line => {
                const values = line.replace(/,/gi, ".").split(/\s+/gi);
                const parsedValues: number[] = [];
                each(values, value => {
                    const parsed = parseFloat(value);
                    if (!isNaN(parsed) && isFinite(parsed)) {
                        parsedValues.push(parsed);
                        if (parsedValues.length === 2) {
                            return false; // ignore rest of line
                        }
                    }

                    return;
                });

                if (parsedValues.length === 2) {
                    positions.push(parsedValues[0]);
                    measurements.push(parsedValues[1]);
                    active.push(1);
                }
            });

            if (this.state.chlorideMeasurement) {
                const measurement = cloneDeep(this.state.chlorideMeasurement);
                measurement.measurements = measurements;
                measurement.positions = positions;
                measurement.active = active;
                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        addLoading: true,
                        loading: true
                    },
                    () => {
                        if (this.isAlreadyExistingMeasurement(measurement)) {
                            this.onChangedMeasurement(measurement);
                        } else {
                            this.onCreatedMeasurement(measurement);
                        }
                    }
                );
            }
        }
    }

    saveAddChlorideParametersFormRef = (formRef: React.PureComponent<AddChlorideParametersProps>) => {
        this.addChlorideParametersForm = formRef;
    };

    onAddChlorideParametersButtonClick() {
        this.setState({
            ...this.state,
            addParametersVisible: true
        });
    }

    onAddChlorideParametersCancel() {
        this.setState(
            {
                ...this.state,
                addParametersVisible: false,
                addParametersLoading: false,
                addParametersError: undefined
            },
            () => {
                if (this.addChlorideParametersForm) {
                    this.addChlorideParametersForm.props.form.resetFields();
                }
            }
        );
    }

    onAddChlorideParametersOK() {
        if (this.addChlorideParametersForm) {
            const {
                form: { validateFields, getFieldsValue }
            } = this.addChlorideParametersForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const fieldValues = getFieldsValue() as any;

            const treeCopy = cloneDeep(this.props.tree);
            const rootNode = treeCopy.tree;
            if (rootNode) {
                projectService.loop([rootNode], this.props.currentNode.uuid, (node, index, arr) => {
                    node.useManualChloridesInput = true;
                    node.D = fieldValues.d * D_UNIT;
                    node.Cs = fieldValues.Cs;
                    node.Ci = fieldValues.Ci;
                });

                projectService
                    .editNodes(treeCopy.projectId, treeCopy)
                    .then(modifiedTree => {
                        this.setState(
                            {
                                ...this.state,
                                loading: false,
                                addLoading: false,
                                addVisible: false,
                                addParametersLoading: false,
                                addParametersVisible: false
                            },
                            () => {
                                notification["success"]({
                                    message: this.props.intl.formatMessage({
                                        id: "chlorides.edit"
                                    }),
                                    description: this.props.intl.formatMessage({
                                        id: "chlorides.edit.succeeded"
                                    })
                                });
                            }
                        );

                        this.props.onLoading(false);
                        this.props.onEditNodes(modifiedTree);
                    })
                    .catch(err => {
                        this.props.onLoading(false);
                        this.setState({
                            ...this.state,
                            error: err.message,
                            loading: false
                        });
                    });
            } else {
                this.props.onLoading(false);
            }
        }
    }

    onDeleteChlorideParameters() {
        const treeCopy = cloneDeep(this.props.tree);
        const rootNode = treeCopy.tree;
        if (rootNode) {
            projectService.loop([rootNode], this.props.currentNode.uuid, (node, index, arr) => {
                node.useManualChloridesInput = false;
                node.D = undefined;
                node.Cs = undefined;
                node.Ci = undefined;
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(modifiedTree => {
                    this.setState(
                        {
                            ...this.state,
                            loading: false,
                            addLoading: false,
                            addVisible: false,
                            addParametersLoading: false,
                            addParametersVisible: false
                        },
                        () => {
                            notification["success"]({
                                message: this.props.intl.formatMessage({
                                    id: "chlorides.edit"
                                }),
                                description: this.props.intl.formatMessage({
                                    id: "chlorides.edit.succeeded"
                                })
                            });
                        }
                    );

                    this.props.onLoading(false);
                    this.props.onEditNodes(modifiedTree);
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        error: err.message,
                        loading: false
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    }

    shouldReGenerateGraph() {
        if (this.props.currentNode.chlorides) {
            if (this.state.chlorideMeasurement) {
                if (this.state.chlorideMeasurement.status === ChloridesStatus.Done) {
                    return false;
                }
                if (this.state.chlorideMeasurement.status === ChloridesStatus.GraphProcessing) {
                    return false;
                }
                if (this.state.chlorideMeasurement.status === ChloridesStatus.Failed) {
                    return false;
                }
                if (this.state.chlorideMeasurement.status === ChloridesStatus.Dirty) {
                    return true;
                }
                if (!this.state.chlorideMeasurement.graphURL) {
                    return true;
                }
            }
        }

        return false;
    }

    regenerateGraphIfNeeded() {
        if (this.shouldReGenerateGraph()) {
            this.generateGraph();
        }
    }

    componentDidMount() {
        this.fetchMeasurements();

        if (
            !this.props.currentNode.chlorides &&
            !this.props.currentNode.chlorideGraphURL &&
            !this.state.combinedGraphLoading &&
            this.hasCombinedGraph()
        ) {
            this.generateCombinedGraph();
        }
    }

    componentDidUpdate(prevProps: AllProps) {
        /*console.log(
            "Update currentNode",
            prevProps.currentNode,
            this.props.currentNode,
            prevProps.currentNode === this.props.currentNode,
            prevProps.currentNode.name + "=>" + this.props.currentNode.name
        );*/
        // console.log("Update tree", prevProps.tree, this.props.tree, prevProps.tree === this.props.tree);
        if (
            prevProps.projectId !== this.props.projectId ||
            prevProps.currentNode !== this.props.currentNode ||
            prevProps.tree !== this.props.tree
        ) {
            this.fetchMeasurements();

            if (
                !this.props.currentNode.chlorides &&
                !this.props.currentNode.chlorideGraphURL &&
                !this.state.combinedGraphLoading &&
                this.hasCombinedGraph()
            ) {
                this.generateCombinedGraph();
            }
        }
    }
}

const chloridesWrapped = injectIntl(Form.create<AllProps>()(Chlorides));
export default chloridesWrapped;
