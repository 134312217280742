import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { IProject } from "../../store/projects/types";

import Projects from "./projects";
import Project from "./project";
import NotFoundPage from "../../pages/notfound";

interface IOwnProps {
    projects: IProject[];
}

type AllProps = IOwnProps;

class ProjectsPage extends React.PureComponent<AllProps> {
    public render() {
        return (
            <Switch>
                <Route exact path="/projects" render={() => <Projects projects={this.props.projects} />} />
                <Route path="/projects/:id" component={Project} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        );
    }
}

export default ProjectsPage;
