import { addLocaleData } from "react-intl";

import * as enLocaleData from "react-intl/locale-data/en";
import * as nlLocaleData from "react-intl/locale-data/nl";
addLocaleData(enLocaleData);
addLocaleData(nlLocaleData);

import antd_en from "antd/lib/locale-provider/en_US";
import antd_nl from "antd/lib/locale-provider/nl_BE";

import en from "./en.json";
import nl from "./nl.json";

const messages = { en, nl };
const allowedLanguages = "en" | "nl";
const antdlocale = { en: antd_en, nl: antd_nl };

export default { messages, antdlocale, allowedLanguages };
