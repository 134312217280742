import { v4 as generateUUID } from "uuid";
import {
    ICreateProject,
    INode,
    IProject,
    IProjectTree,
    ProjectLifecycle,
    ProjectStatus
} from "../../store/projects/types";
import api from "./api";
import authService from "./auth-service";

const NEW_DRAFT_PROJECT: ICreateProject = {
    organization: "",
    name: "_",
    created_by: "_",
    updated_by: "_",
    age: 0,
    maxCalculationAge: 100,
    coverdepthThreshold: 100,
    coverdepthQualityThreshold: 20,
    coverdepthOverlay: 0,
    failureProbability: 0.1,
    failureProbabilityChlorides: 0.1,
    chloridesThreshold: 0.6,
    chloridesBetaStd: 0.15,
    chloridesAgingFactor: 0,
    lifecycle: ProjectLifecycle.Draft,
    customer: "",
    location: "",
    description: ""
};

class ProjectService {
    async calculate(projectId: string, fast?: boolean) {
        let url = "/project/" + projectId + "/calculate";
        if (fast) {
            url += "?fast=1";
        }
        const calculationResponse = await api.post(url);

        if (calculationResponse.status !== 200 || !calculationResponse.data || !calculationResponse.data.response) {
            throw new Error("Error while requesting calculation");
        }

        return calculationResponse.data.response;
    }

    async createEmptyTree(updatedProject: IProject) {
        const rootNode: INode = {
            name: updatedProject.name,
            uuid: generateUUID(),
            files: [],
            children: [],
            useManualInput: false
        };
        const projectContent: IProjectTree = { projectId: updatedProject._id, tree: rootNode };

        const treeResponse = await api.put("/project/" + updatedProject._id + "/content", projectContent);
        if (!treeResponse || !treeResponse.data || !treeResponse.data.response || !treeResponse.data.response._id) {
            throw new Error("Error while creating project content");
        }

        return treeResponse.data.response;
    }

    async createDraftProject() {
        const draft = { ...NEW_DRAFT_PROJECT } as ICreateProject;
        const user = authService.getUser();
        draft.created_by = user.emailAddress || user.name;
        draft.updated_by = user.emailAddress || user.name;

        const response = await api.post("/projects", draft);
        if (!response || !response.data || !response.data.response || !response.data.response._id) {
            throw new Error("Error while creating project");
        }

        return response.data.response._id;
    }

    async createProject(project: ICreateProject) {
        const user = authService.getUser();
        project.created_by = user.emailAddress || user.name;
        project.updated_by = user.emailAddress || user.name;

        const response = await api.post("/projects", project);
        if (!response || !response.data || !response.data.response || !response.data.response._id) {
            throw new Error("Error while creating project");
        }

        return response.data.response;
    }

    async editProject(project: IProject): Promise<IProject> {
        const user = authService.getUser();
        project.updated_by = user.emailAddress || user.name;
        project.status = ProjectStatus.Dirty;
        const response = await api.put("/project/" + project._id, project);

        if (response && response.data && response.data.response) {
            return response.data.response;
        }

        // TODO how to do server error handling?
        throw new Error("Error while updating project with id " + project._id);
    }

    async getProjects(): Promise<IProject[]> {
        const response = await api.get("/projects");

        if (response && response.data && response.data.response && response.data.response.items) {
            return response.data.response.items;
        }

        // TODO how to do server error handling?
        throw new Error("Error while fetching projects");
    }

    async getProject(projectId: string): Promise<IProject> {
        const response = await api.get("/project/" + projectId);

        if (response && response.data && response.data.response) {
            return response.data.response;
        }

        // TODO how to do server error handling?
        throw new Error("Error while fetching project with id " + projectId);
    }

    async deleteProject(projectId: string): Promise<IProject> {
        const response = await api.delete("/project/" + projectId);

        if (response && response.data && response.data.response) {
            return response.data.response;
        }

        // TODO how to do server error handling?
        throw new Error("Error while fetching project with id " + projectId);
    }

    async getNodes(projectId: string): Promise<IProjectTree> {
        const response = await api.get("/project/" + projectId + "/content");

        if (response && response.data && response.data.response) {
            return response.data.response;
        }

        // TODO how to do server error handling?
        throw new Error("Error while fetching nodes for project with id " + projectId);
    }

    async editNodes(projectId: string, tree: IProjectTree) {
        const response = await api.put("/project/" + projectId + "/content", tree);

        if (response && response.data && response.data.response) {
            return response.data.response;
        }
    }

    /**
     * UTILITY FUNCTIONS
     */

    b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });

        return blob;
    };
    /**
     * Execute a callback for the node with the given uuid, giving also his index in the parent array
     */
    loop: (
        nodes: INode[],
        uuid: string,
        callback: (node: INode, index: number, arr: INode[], level: number, parent?: INode) => void
    ) => void = (nodes, uuid, callback) => {
        this.loopCondition(nodes, (node: INode) => node.uuid === uuid, callback);
    };

    /**
     * Execute a callback for the node passing the given test, giving also his index in the parent array
     */
    loopCondition: (
        nodes: INode[],
        test: (node: INode) => boolean,
        callback: (node: INode, index: number, arr: INode[], level: number, parent?: INode) => void,
        level?: number,
        parent?: INode
    ) => void = (nodes, test, callback, level = 0, parent) => {
        nodes.forEach((node, index, arr) => {
            if (test(node)) {
                return callback(node, index, arr, level, parent);
            }
            if (node.children) {
                return this.loopCondition(node.children, test, callback, level + 1, node);
            }
        });
    };
    /**
     * Get the path of the parents of the nodeToFind in the nodes tree
     * @param nodes The nodes tree
     * @param nodeToFind The node to look for in the tree
     * @param path This contains the path if found
     * @returns True if found
     */
    getParentsPath: (nodes: INode[], nodeToFind: INode, path: INode[]) => boolean = (nodes, nodeToFind, path) => {
        let found = false;

        nodes.every(node => {
            if (node.uuid === nodeToFind.uuid) {
                // console.log("For node " + nodeToFind.name + " found: " + path.map(p => p.name).join("->"));
                found = true;

                return false; // stop looking
            } else {
                if (!found && node.children.length > 0) {
                    path.push(node);

                    found = this.getParentsPath(node.children, nodeToFind, path);

                    if (!found) {
                        path.pop();
                    }
                }
            }

            return true; // continue looking
        });

        return found;
    };
}

export default new ProjectService();
