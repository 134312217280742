import * as React from "react";
import { IConnectedReduxProps } from "../../store";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { injectIntl, InjectedIntlProps } from "react-intl";
import "./login-messages";

import { Row, Col, Button, Icon, Layout, Alert } from "antd";

import authService from "../../services/API/auth-service";

interface IPropsFromState {
    authenticating: boolean;
    authenticated: boolean;
    errors?: string;
}

type AllProps = IPropsFromState & IConnectedReduxProps & RouteComponentProps<{}> & InjectedIntlProps;

class Login extends React.PureComponent<AllProps> {
    public render() {
        const { errors, authenticating } = this.props;
        if (this.props.authenticated) {
            return <Redirect to="/" />;
        }

        if (this.props.errors) {
            return <p>{this.props.errors}</p>;
        }

        return (
            <Layout style={{ height: "100vh", background: "#fff" }}>
                <Layout.Content style={{ margin: "24px 16px 0" }}>
                    <div style={{ padding: 24 }}>
                        <Row className="login-form" style={{ margin: "60px 0px" }}>
                            <Col
                                md={{ span: 6, offset: 8 }}
                                xs={{ span: 18, offset: 3 }}
                                style={{ textAlign: "center" }}
                            >
                                <Icon type="user" style={{ fontSize: "142px" }} />
                            </Col>
                        </Row>
                        <Row className="login-form">
                            <Col md={{ span: 6, offset: 8 }} xs={{ span: 18, offset: 3 }}>
                                <Button
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                    loading={authenticating}
                                    disabled={authenticating}
                                    type="primary"
                                    onClick={this.login}
                                >
                                    {this.props.intl.formatMessage({ id: "button.login" })}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 6, offset: 8 }} xs={{ span: 18, offset: 3 }}>
                                {errors && (
                                    <Alert
                                        message={this.props.intl.formatMessage({ id: "error" })}
                                        description={errors}
                                        type="error"
                                        showIcon
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
            </Layout>
        );
    }

    login = () => {
        if (this.props.location.state) {
            sessionStorage.setItem("after-login-destination", this.props.location.state.from);
        }
        authService.login();
    };
}

export default injectIntl(Login);
