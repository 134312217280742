import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./histogram-messages";

import { Modal, Spin, Tooltip } from "antd";
import CovermeasurementQualityThreshold from "../../pages/projects/covermeasurementqualitythreshold";
import { IRowEntry } from "../../pages/projects/covermeasurements";
import CovermeasurementThreshold from "../../pages/projects/covermeasurementthreshold";
import Utils from "../../pages/projects/utils";

interface IOwnProps {
    record: IRowEntry;
    visible: boolean;
    graphDate: number;
    editHistogram: (record: IRowEntry, edit: boolean) => void;
    saveThresholdChange: (newThreshold: number | undefined, key: string) => Promise<void>;
    saveQualityThresholdChange: (newQualityThreshold: number | undefined, key: string) => Promise<void>;
}

interface IState {
    isEditingThreshold: boolean;
    isEditingQualityThreshold: boolean;
    loading: boolean;
}

type AllProps = IOwnProps & InjectedIntlProps;

class HistogramButton extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            isEditingThreshold: false,
            isEditingQualityThreshold: false,
            loading: false
        };
    }

    componentDidUpdate(prevProps: AllProps) {
        console.log("Update HistogramButton");
    }

    public render() {
        const { record, visible, graphDate } = this.props;

        return (
            <div style={{ cursor: "pointer" }} onClick={this.handleClick.bind(this)}>
                {record.coverPlotSmall && (
                    <img width={128} height={48} src={Utils.getDownloadURL(record.coverPlotSmall) + "?" + graphDate} />
                )}
                {!record.coverPlotSmall && (
                    <Spin>
                        <div style={{ width: "128px", height: "48px", backgroundColor: "#eee" }} />
                    </Spin>
                )}
                <Modal
                    title={this.props.intl.formatMessage({
                        id: "histogram"
                    })}
                    visible={visible}
                    onOk={this.handleOK.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    width={"90%"}
                    footer={null}
                >
                    <Spin spinning={this.state.loading}>
                        {record.coverPlotLarge && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    aspectRatio: "1280 / 480",
                                    width: "100%"
                                }}
                            >
                                <img
                                    width={"90%"}
                                    src={Utils.getDownloadURL(record.coverPlotLarge) + "?" + graphDate}
                                />
                            </div>
                        )}
                        {!record.coverPlotLarge && (
                            <Spin>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ aspectRatio: "1280 / 480", width: "90%", backgroundColor: "#eee" }} />
                                </div>
                            </Spin>
                        )}
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <span
                                style={{ marginRight: "8px", lineHeight: this.state.isEditingThreshold ? "32px" : "" }}
                            >
                                <Tooltip
                                    title={this.props.intl.formatMessage({
                                        id: "histogram.covermeasurementThreshold"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "histogram.covermeasurementThreshold.short"
                                    })}
                                </Tooltip>
                            </span>
                            <CovermeasurementThreshold
                                record={record}
                                copy={false}
                                isEditingThreshold={this.isEditingThreshold.bind(this)}
                                saveThresholdChange={this.saveThresholdChange.bind(this)}
                                editThreshold={this.editThreshold.bind(this)}
                                cancelThresholdChange={this.cancelThresholdChange.bind(this)}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <span
                                style={{
                                    marginRight: "8px",
                                    lineHeight: this.state.isEditingQualityThreshold ? "32px" : ""
                                }}
                            >
                                <Tooltip
                                    title={this.props.intl.formatMessage({
                                        id: "histogram.covermeasurementQualityThreshold"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id: "histogram.covermeasurementQualityThreshold.short"
                                    })}
                                </Tooltip>
                            </span>
                            <CovermeasurementQualityThreshold
                                record={record}
                                copy={false}
                                isEditingQualityThreshold={this.isEditingQualityThreshold.bind(this)}
                                saveQualityThresholdChange={this.saveQualityThresholdChange.bind(this)}
                                editQualityThreshold={this.editQualityThreshold.bind(this)}
                                cancelQualityThresholdChange={this.cancelQualityThresholdChange.bind(this)}
                            />
                        </div>
                    </Spin>
                </Modal>
            </div>
        );
    }

    handleOK(e: React.MouseEvent<any>) {
        // console.log("Handle OK");
        e.stopPropagation();
        e.preventDefault();
        this.props.editHistogram(this.props.record, false);
    }

    handleCancel(e: React.MouseEvent<any>) {
        // console.log("Handle cancel");
        e.stopPropagation();
        e.preventDefault();
        this.props.editHistogram(this.props.record, false);
    }

    handleClick() {
        this.props.editHistogram(this.props.record, true);
    }

    isEditingThreshold = (record: IRowEntry) => this.state.isEditingThreshold;

    isEditingQualityThreshold = (record: IRowEntry) => this.state.isEditingQualityThreshold;

    editThreshold = (record: IRowEntry) =>
        this.setState({
            ...this.state,
            isEditingThreshold: true
        });

    editQualityThreshold = (record: IRowEntry) =>
        this.setState({
            ...this.state,
            isEditingQualityThreshold: true
        });

    saveThresholdChange = (newThreshold: number | undefined, key: string) => {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                this.props.saveThresholdChange(newThreshold, key).finally(() => {
                    this.setState({
                        ...this.state,
                        isEditingThreshold: false,
                        loading: false
                    });
                });
            }
        );
    };

    saveQualityThresholdChange = (newQualityThreshold: number | undefined, key: string) => {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                this.props.saveQualityThresholdChange(newQualityThreshold, key).finally(() => {
                    this.setState({
                        ...this.state,
                        isEditingQualityThreshold: false,
                        loading: false
                    });
                });
            }
        );
    };

    cancelThresholdChange = () =>
        this.setState({
            ...this.state,
            isEditingThreshold: false
        });

    cancelQualityThresholdChange = () =>
        this.setState({
            ...this.state,
            isEditingQualityThreshold: false
        });
}

export default injectIntl(HistogramButton);
