import { defineMessages } from "react-intl";

// general messages that can be used throughout the application
const messages = defineMessages({
    loading: {
        id: "loading",
        description: "Loading indicator",
        defaultMessage: "Loading..."
    },
    doesnotexist: {
        id: "doesnotexist",
        description: "Does Not Exist",
        defaultMessage: "This page does not exist..."
    },
    error: {
        id: "error",
        description: "Error title",
        defaultMessage: "Error"
    },
    warning: {
        id: "warning",
        description: "Warning title",
        defaultMessage: "Warning"
    },
    info: {
        id: "info",
        description: "Info title",
        defaultMessage: "Info"
    },
    yes: {
        id: "yes",
        description: "Yes",
        defaultMessage: "Yes"
    },
    no: {
        id: "no",
        description: "No",
        defaultMessage: "No"
    },
    mm: {
        id: "mm",
        description: "mm unit",
        defaultMessage: "mm"
    },
    years: {
        id: "years",
        description: "Years unit",
        defaultMessage: "years"
    },
    days: {
        id: "days",
        description: "Days unit",
        defaultMessage: "days"
    },
    procent: {
        id: "procent",
        description: "Probablity unit",
        defaultMessage: "%"
    },
    export: {
        id: "export",
        description: "Export Button for Tables",
        defaultMessage: "Copy to Clipboard"
    },
    exportSuccess: {
        id: "export.succeeded",
        description: "Export Success Message",
        defaultMessage: "Table copied to Clipboard! Now you can paste this in Excel."
    }
});

export default messages;
