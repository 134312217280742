import { defineMessages } from "react-intl";

const messages = defineMessages({
    logout: {
        id: "button.logout",
        description: "Button Logout",
        defaultMessage: "Logout"
    },
    profile: {
        id: "button.profile",
        description: "Button Profile",
        defaultMessage: "View Profile"
    }
});

export default messages;
