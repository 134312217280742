import { IChlorideMeasurement, ICreateChlorideMeasurement, IProjectTree } from "../../store/projects/types";
import api from "./api";

class ChlorideService {
    async getChlorideMeasurement(chlorideId: string): Promise<IChlorideMeasurement> {
        const response = await api.get("/chloridemeasurement/" + chlorideId);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while getting chloride measurements with id " + chlorideId);
    }

    async createChlorideMeasurement(measurement: ICreateChlorideMeasurement): Promise<IChlorideMeasurement> {
        const response = await api.post("/chloridemeasurements", measurement);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating chloride measurement");
    }

    async updateChlorideMeasurement(measurement: IChlorideMeasurement): Promise<IChlorideMeasurement> {
        const response = await api.put("/chloridemeasurement/" + measurement._id, measurement);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating chloride measurement");
    }

    async deleteChlorideMeasurement(measurementId: string): Promise<void> {
        const response = await api.delete("/chloridemeasurement/" + measurementId);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while deleting chloride measurement");
    }

    async generateGraph(chlorideId: string, nodeId: string, project: string): Promise<IChlorideMeasurement> {
        const response = await api.get(
            "/chloridemeasurement/" + chlorideId + "/graph?project=" + project + "&nodeId=" + nodeId
        );

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating graph for " + chlorideId);
    }

    async generateCombinedGraph(project: string, nodeId: string): Promise<void> {
        const response = await api.get("/chloridemeasurementnodegraph?project=" + project + "&nodeId=" + nodeId);

        if (response && response.data) {
            if (response.data.response) {
                return;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating combined graph for project " + project + " and node " + nodeId);
    }

    async clearParentGraphs(nodeId: string, projectId: string): Promise<IProjectTree> {
        const response = await api.get("/chloridemeasurementnodegraphclear?project=" + projectId + "&nodeId=" + nodeId);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        throw new Error("Error while clearing parent graphs for project " + projectId + " and node " + nodeId);
    }
}

export default new ChlorideService();
