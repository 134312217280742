import { defineMessages } from "react-intl";

const messages = defineMessages({
    active: {
        id: "chlorides.active",
        description: "Chlorides Active",
        defaultMessage: "Active"
    },
    depth: {
        id: "chlorides.depth",
        description: "Chlorides Depth",
        defaultMessage: "Depth"
    },
    depthUnit: {
        id: "chlorides.depth.unit",
        description: "Chlorides Depth Unit",
        defaultMessage: "mm"
    },
    value: {
        id: "chlorides.value",
        description: "Chlorides Value",
        defaultMessage: "Chloride Content"
    },
    valueUnit: {
        id: "chlorides.value.unit",
        description: "Chlorides Value Unit",
        defaultMessage: "wt.-%/c"
    },
    add: {
        id: "chlorides.add",
        description: "Chlorides Add Button",
        defaultMessage: "Add Chloride Measurements"
    },
    addAsText: {
        id: "chlorides.addAsText",
        description: "Chlorides Add as Text Button",
        defaultMessage: "Add Chloride Measurements as Text"
    },
    edit: {
        id: "chlorides.edit",
        description: "Chlorides Edit Button",
        defaultMessage: "Edit Chloride Measurements"
    },
    editAsText: {
        id: "chlorides.editAsText",
        description: "Chlorides Edit As Text Button",
        defaultMessage: "Edit Chloride Measurements as Text"
    },
    editSucceeded: {
        id: "chlorides.edit.succeeded",
        description: "Chlorides Edit Succeeded Message",
        defaultMessage: "Chloride Measurements are successfully changed"
    },
    delete: {
        id: "chloride.delete",
        description: "Chloride Delete Button",
        defaultMessage: "Delete Chloride Measurement"
    },
    deleteConfirm: {
        id: "chloride.delete.confirm",
        description: "Chloride Delete Confirmation Message",
        defaultMessage: "Are you sure you want to delete this chloride value?"
    },
    deleteAll: {
        id: "chlorides.delete",
        description: "Chlorides Delete Button",
        defaultMessage: "Delete All Chloride Measurements"
    },
    deleteAllConfirm: {
        id: "chlorides.delete.confirm",
        description: "Chlorides Delete Confirmation Message",
        defaultMessage: "Are you sure you want to delete all chloride values?"
    },
    chloridesActions: {
        id: "chlorides.actions",
        description: "Actions Column Title",
        defaultMessage: "Actions"
    },
    generateGraph: {
        id: "chlorides.generateGraph",
        description: "Chlorides Generate Graph Button",
        defaultMessage: "Generate Graph"
    },
    generatingGraph: {
        id: "chlorides.generatingGraph",
        description: "Chlorides Generating Loading Indicator",
        defaultMessage: "Generating Graph..."
    },
    generateGraphError: {
        id: "chlorides.generateGraphError",
        description: "Chlorides Generate Graph Error Alert",
        defaultMessage: "Failed to generate graph. Please try again."
    },
    save: {
        id: "chlorides.save",
        description: "Chlorides Save Button",
        defaultMessage: "Save All Changes"
    },
    cancel: {
        id: "chlorides.cancel",
        description: "Chlorides Cancel Button",
        defaultMessage: "Cancel All Changes"
    },
    noCombinedGraph: {
        id: "chlorides.noCombinedGraph",
        description: "Chlorides No Combined Graph Possible Message",
        defaultMessage: "No combined graph possible since none of the subnodes have Chloride Measurements."
    },
    maxTooLow: {
        id: "chlorides.MAX_TOO_LOW",
        description: "Chlorides Warning Max Too Low",
        defaultMessage: "All Chloride measurements are below the Chlorides Threshold."
    },
    noTimeAnalysis: {
        id: "chlorides.D_NOT_ACCEPTED_NO_TIME_ANALYSIS_LIN_INTERPOLATION",
        description: "Chlorides Warning No Time Analysis",
        defaultMessage:
            "D value not accepted. Probability of failure calculation uses multi-linear interpolation. " +
            "Chloride measurements are taken into account for parent levels."
    },
    warningManualPropagation: {
        id: "chlorides.warningManualPropagation",
        description: "Chlorides Warning Manual Propagation does not happen",
        defaultMessage: "Manual Chloride Parameters are not propagated upwards"
    },
    notUniq: {
        id: "chlorides.notUniq",
        description: "Error message when no unique depths are specified",
        defaultMessage: "There are duplicate depths specified."
    },
    addParameters: {
        id: "chlorides.addParameters",
        description: "Add Chloride Parameters Manually Button",
        defaultMessage: "Add Chloride Parameters Manually"
    },
    addParametersTitle: {
        id: "chlorides.addParameters.title",
        description: "Add or Edit Chloride Parameters Manually Title",
        defaultMessage: "Manual Chloride Parameters"
    },
    editParameters: {
        id: "chlorides.editParameters",
        description: "Edit Manual Chloride Parameters Button",
        defaultMessage: "Edit Manual Chloride Parameters"
    },
    deleteParameters: {
        id: "chlorides.deleteParameters",
        description: "Delete Manual Chloride Parameters Button",
        defaultMessage: "Delete Manual Chloride Parameters"
    },
    deleteParametersConfirm: {
        id: "chlorides.deleteParameters.confirm",
        description: "Delete Manual Chloride Parameters Button Confirmation",
        defaultMessage: "Are you sure you want to delete the Manual Chloride Parameters?"
    },
    d: {
        id: "chlorides.d",
        description: "Chlorides D",
        defaultMessage: "Diffusion Coefficient D"
    },
    dRequired: {
        id: "chlorides.d.required",
        description: "Chlorides D Required",
        defaultMessage: "Diffusion Coefficient D is required"
    },
    dUnit: {
        id: "chlorides.d.unit",
        description: "Chlorides D Unit",
        defaultMessage: "E-6 mm²/s"
    },
    cs: {
        id: "chlorides.cs",
        description: "Chlorides Cs",
        defaultMessage: "Chloride Content at Surface Cs"
    },
    csRequired: {
        id: "chlorides.cs.required",
        description: "Chlorides Cs Required",
        defaultMessage: "Chloride Content at Surface Cs is required"
    },
    ci: {
        id: "chlorides.ci",
        description: "Chlorides Ci",
        defaultMessage: "Initial Chloride Content Ci"
    },
    ciRequired: {
        id: "chlorides.ci.required",
        description: "Chlorides Ci Required",
        defaultMessage: "Initial Chloride Content Ci is required"
    },
    chloridesUnit: {
        id: "chlorides.unit",
        description: "Chlorides Unit",
        defaultMessage: "[wt.-%/c]"
    }
});
export default messages;
