import { cloneDeep, each, isFinite, isNaN, sortBy } from "lodash-es";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Utils from "./utils";

import { InjectedIntlProps, injectIntl } from "react-intl";
import "./carbonatation-messages";

import authService from "../../services/API/auth-service";
import carbonatationService from "../../services/API/carbonatation-service";
import projectService from "../../services/API/project-service";
import {
    ICarbonatationMeasurement,
    ICarbonatationMeasurementManualInput,
    ICreateCarbonatationMeasurementManualInput,
    INode,
    IProjectTree,
    NodeStatus
} from "../../store/projects/types";
import { AddCarbonatation, AddCarbonatationProps } from "./addcarbonatation";
import {
    AddCarbonatationMeasurementManualInput,
    AddCarbonatationMeasurementManualInputProps
} from "./addmanualcarbonatationmeasurement";

import { Alert, Button, ConfigProvider, Empty, Icon, Popconfirm, Table, Tooltip, notification } from "antd";
import { emptyImg } from "./utils";

interface IState {
    error?: string;
    loading: boolean;

    carbonatationMeasurement?: ICarbonatationMeasurement;
    carbonatationMeasurements: ICarbonatationMeasurement[];

    manualInput?: ICarbonatationMeasurementManualInput;
    manualInputVisible: boolean;
    manualInputLoading: boolean;
    manualInputError?: string;

    addVisible: boolean;
    addLoading: boolean;
    addError?: string;
}

interface IOwnProps {
    tree: IProjectTree;
    projectId: string;
    currentNode: INode;

    onEditNodes: (tree: IProjectTree) => void;
    onLoading: (loading: boolean) => void;
}

type AllProps = IOwnProps & InjectedIntlProps;

interface IRowEntry {
    key: string;
    treeOrder: number;
    nodeName: string;
    values: number[];
    count: number;
    min: number;
    max: number;
    average: number;
    standardDeviation: number;
}

interface IManualRowEntry {
    key: string;
    average: number;
    standardDeviation: number;
}

class Carbonatation extends React.PureComponent<AllProps, IState> {
    addCarbonatationForm?: React.Component<AddCarbonatationProps>;
    addManualInputForm?: React.Component<AddCarbonatationMeasurementManualInputProps>;
    constructor(props: AllProps) {
        super(props);

        this.state = {
            error: undefined,
            loading: false,
            carbonatationMeasurement: undefined,
            carbonatationMeasurements: [],

            manualInput: undefined,
            manualInputVisible: false,
            manualInputLoading: false,
            manualInputError: undefined,

            addVisible: false,
            addLoading: false,
            addError: undefined
        };
    }

    customizeRenderEmpty = () => (
        <Empty image={emptyImg} className="ant-empty ant-empty-normal">
            {this.canEditMeasurements() && (
                <Button type="primary" onClick={this.onAddCarbonatationButtonClick.bind(this)}>
                    {this.props.intl.formatMessage({
                        id: "carbonatation.add"
                    })}
                </Button>
            )}
        </Empty>
    );

    fetchMeasurements() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                if (this.props.currentNode.carbonatation) {
                    carbonatationService
                        .getCarbonatationMeasurement(this.props.currentNode.carbonatation)
                        .then(measurement => {
                            this.setState({
                                ...this.state,
                                carbonatationMeasurement: measurement,
                                carbonatationMeasurements: [],
                                loading: false,
                                error: undefined
                            });
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                error: err.message,
                                carbonatationMeasurement: undefined,
                                carbonatationMeasurements: [],
                                loading: false
                            });
                        });
                } else {
                    carbonatationService
                        .getCarbonatationMeasurements(this.props.projectId, this.props.currentNode.uuid)
                        .then(measurements => {
                            this.setState({
                                ...this.state,
                                carbonatationMeasurement: undefined,
                                carbonatationMeasurements: measurements,
                                loading: false,
                                error: undefined
                            });
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                error: err.message,
                                carbonatationMeasurements: [],
                                carbonatationMeasurement: undefined,
                                loading: false
                            });
                        });
                }
            }
        );
    }

    fetchManualInputMeasurements() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                if (this.props.currentNode.carbonatationMeasurementsManualInput) {
                    carbonatationService
                        .getCarbonatationMeasurementsManualInput(
                            this.props.currentNode.carbonatationMeasurementsManualInput
                        )
                        .then(measurement => {
                            this.setState({
                                ...this.state,
                                manualInput: measurement,
                                loading: false,
                                error: ""
                            });
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                error: err.message,
                                manualInput: undefined,
                                loading: false
                            });
                        });
                } else {
                    this.setState({
                        ...this.state,
                        manualInput: undefined,
                        loading: false
                    });
                }
            }
        );
    }

    componentDidMount() {
        this.fetchMeasurements();
        this.fetchManualInputMeasurements();
    }

    componentDidUpdate(prevProps: AllProps) {
        if (prevProps.currentNode !== this.props.currentNode) {
            this.fetchMeasurements();
            this.fetchManualInputMeasurements();
        }
    }

    hasMultipleMeasurements() {
        return this.state.carbonatationMeasurements.length > 0;
    }

    canEditMeasurements() {
        return this.props.currentNode.status !== NodeStatus.DISABLED;
    }

    canDeleteMeasurements() {
        return authService.hasScope("delete:projects") && this.props.currentNode.status !== NodeStatus.DISABLED;
    }

    canAddMeasurements() {
        const { currentNode } = this.props;
        if (currentNode.status === NodeStatus.DISABLED) {
            return false;
        }

        return true;
    }

    getColumns(forExport = false) {
        const columns = [
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "carbonatation.values" })}>
                        {this.props.intl.formatMessage({ id: "carbonatation.values.short" })}
                    </Tooltip>
                ),
                dataIndex: "values",
                render: (text: string, record: IRowEntry) => {
                    return <span>{record.values.join(", ")}</span>;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "carbonatation.count" })}>
                        {this.props.intl.formatMessage({ id: "carbonatation.count.short" })}
                    </Tooltip>
                ),
                dataIndex: "count",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.count - b.count;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "carbonatation.min" })}>
                        {this.props.intl.formatMessage({ id: "carbonatation.min.short" })}
                        <sub> CO₂ </sub>
                        {this.props.intl.formatMessage({ id: "carbonatation.unit" })}
                    </Tooltip>
                ),
                dataIndex: "min",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.min - b.min;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "carbonatation.max" })}>
                        {this.props.intl.formatMessage({ id: "carbonatation.max.short" })}
                        <sub> CO₂ </sub>
                        {this.props.intl.formatMessage({ id: "carbonatation.unit" })}
                    </Tooltip>
                ),
                dataIndex: "max",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.max - b.max;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "carbonatation.avg" })}>
                        {this.props.intl.formatMessage({ id: "carbonatation.avg.short" })}
                        <sub> CO₂ </sub>
                        {this.props.intl.formatMessage({ id: "carbonatation.unit" })}
                    </Tooltip>
                ),
                dataIndex: "average",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.average - b.average;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "carbonatation.sd" })}>
                        {this.props.intl.formatMessage({ id: "carbonatation.sd.short" })}
                        <sub> CO₂ </sub>
                        {this.props.intl.formatMessage({ id: "carbonatation.unit" })}
                    </Tooltip>
                ),
                dataIndex: "standardDeviation",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.standardDeviation - b.standardDeviation;
                }
            }
        ];

        if (!forExport && !this.hasMultipleMeasurements() && this.canEditMeasurements()) {
            columns.push({
                title: <span>{this.props.intl.formatMessage({ id: "carbonatation.actions" })}</span>,
                dataIndex: "actions",
                render: (text: string, record: IRowEntry) => {
                    return (
                        <span style={{ whiteSpace: "nowrap" }}>
                            <Button icon="edit" onClick={this.onEditCarbonatationMeasurements.bind(this)} />
                            {authService.hasScope("delete:projects") && (
                                <Popconfirm
                                    icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                    title={this.props.intl.formatMessage({
                                        id: "carbonatation.delete.confirm"
                                    })}
                                    onConfirm={this.onDeleteCarbonatationMeasurements.bind(this)}
                                    okText={this.props.intl.formatMessage({ id: "yes" })}
                                    cancelText={this.props.intl.formatMessage({ id: "no" })}
                                >
                                    <Button icon="delete" type="danger" style={{ marginLeft: 2 }} />
                                </Popconfirm>
                            )}
                        </span>
                    );
                }
            });
        }

        if (this.hasMultipleMeasurements()) {
            columns.unshift({
                title: <span>{this.props.intl.formatMessage({ id: "carbonatation.nodeName" })}</span>,
                dataIndex: "nodeName",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.nodeName.localeCompare(b.nodeName);
                }
            });
        }

        return columns;
    }

    getNodeDetails(carbonatationId: string) {
        let nodeName = "";
        let counter = 0;
        let tmpCounter = 0;
        const tree = this.props.tree.tree;
        if (tree) {
            projectService.loopCondition(
                [tree],
                (node: INode) => {
                    tmpCounter++;

                    return node.carbonatation === carbonatationId;
                },
                (node: INode) => {
                    counter = tmpCounter;
                    nodeName = node.name;
                }
            );
        }

        return {
            counter,
            nodeName
        };
    }

    createData(carbonatationMeasurement: ICarbonatationMeasurement) {
        const nodeDetails = this.getNodeDetails(carbonatationMeasurement._id);

        return {
            key: carbonatationMeasurement._id,
            treeOrder: nodeDetails.counter,
            nodeName: nodeDetails.nodeName,
            values: carbonatationMeasurement.measurements,
            count: carbonatationMeasurement.count,
            min: carbonatationMeasurement.min,
            max: carbonatationMeasurement.max,
            average: Utils.formatNumber(carbonatationMeasurement.average, 0),
            standardDeviation: Utils.formatNumber(carbonatationMeasurement.standardDeviation, 0),
            actions: ""
        } as IRowEntry;
    }

    getData() {
        const { carbonatationMeasurement, carbonatationMeasurements } = this.state;
        let data: IRowEntry[] = [];
        if (carbonatationMeasurement) {
            data.push(this.createData(carbonatationMeasurement));
        } else if (carbonatationMeasurements.length > 0) {
            carbonatationMeasurements.forEach(m => {
                data.push(this.createData(m));
            });
            // sort by order in the tree
            data = sortBy(data, d => d.treeOrder);
        }

        return data;
    }

    getManualData() {
        const { manualInput } = this.state;
        const data: IManualRowEntry[] = [];
        if (manualInput) {
            data.push({
                key: manualInput._id,
                average: Utils.formatNumber(manualInput.average, 0),
                standardDeviation: Utils.formatNumber(manualInput.standardDeviation, 0)
            } as IManualRowEntry);
        }

        return data;
    }

    getManualColumns(forExport = false) {
        const columns = [
            {
                title: this.props.intl.formatMessage({
                    id: "carbonatation.avg"
                }),
                dataIndex: "average",
                render: (text: string, record: IManualRowEntry) => {
                    return <b>{text}</b>;
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: "carbonatation.sd"
                }),
                dataIndex: "standardDeviation",
                render: (text: string, record: IManualRowEntry) => {
                    return <span>{text}</span>;
                }
            }
        ];

        if (!forExport && this.canEditMeasurements()) {
            columns.push({
                title: this.props.intl.formatMessage({
                    id: "carbonatation.actions"
                }),
                dataIndex: "actions",
                render: (text: string, record: IManualRowEntry) => {
                    return (
                        <span style={{ whiteSpace: "nowrap" }}>
                            <Button icon="edit" onClick={this.onEditManualInputClick.bind(this)} />
                            {this.canDeleteMeasurements() && (
                                <Popconfirm
                                    icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                    title={this.props.intl.formatMessage(
                                        { id: "carbonatation.deleteManual.confirm" },
                                        { number: 1 }
                                    )}
                                    onConfirm={() => this.deleteManualInput()}
                                    okText={this.props.intl.formatMessage({ id: "yes" })}
                                    cancelText={this.props.intl.formatMessage({ id: "no" })}
                                >
                                    <Button icon="delete" type="danger" style={{ marginLeft: 2 }} />
                                </Popconfirm>
                            )}
                        </span>
                    );
                }
            });
        }

        return columns;
    }

    render() {
        const { error, loading, manualInput } = this.state;

        return (
            <div>
                {error && (
                    <Alert
                        style={{ marginBottom: "10px" }}
                        message={this.props.intl.formatMessage({
                            id: "error"
                        })}
                        description={error}
                        type="error"
                        showIcon
                    />
                )}
                <div style={{ marginBottom: "16px" }}>
                    <Button icon="export" onClick={this.handleExport.bind(this)}>
                        {this.props.intl.formatMessage({ id: "export" })}
                    </Button>
                    {!manualInput &&
                        this.canAddMeasurements() && (
                            <Button
                                icon="addManualInput"
                                onClick={this.onAddManualInputClick.bind(this)}
                                style={{ marginLeft: 8 }}
                            >
                                {this.props.intl.formatMessage({ id: "carbonatation.addManualInput" })}
                            </Button>
                        )}
                </div>

                {manualInput && (
                    <Table
                        loading={loading}
                        dataSource={this.getManualData()}
                        columns={this.getManualColumns(false)}
                        size="small"
                        pagination={false}
                        style={{ marginBottom: "5px" }}
                    />
                )}

                {this.state.manualInputVisible && (
                    <AddCarbonatationMeasurementManualInput
                        visible={this.state.manualInputVisible}
                        handleCancel={this.onManualInputCancel.bind(this)}
                        handleOk={this.onManualInputOK.bind(this)}
                        wrappedComponentRef={this.saveManualInputFormRef.bind(this)}
                        error={this.state.manualInputError}
                        loading={this.state.manualInputLoading}
                        measurement={this.state.manualInput}
                    />
                )}

                {!manualInput && (
                    <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                        <Table
                            loading={loading}
                            dataSource={this.getData()}
                            columns={this.getColumns()}
                            pagination={false}
                            size="small"
                        />
                    </ConfigProvider>
                )}
                {this.state.addVisible && (
                    <AddCarbonatation
                        visible={this.state.addVisible}
                        handleCancel={this.onAddCarbonatationCancel.bind(this)}
                        handleOk={this.onAddCarbonatationOK.bind(this)}
                        wrappedComponentRef={this.saveAddCarbonatationFormRef.bind(this)}
                        error={this.state.addError}
                        loading={this.state.addLoading}
                        measurement={this.state.carbonatationMeasurement}
                    />
                )}
            </div>
        );
    }

    handleExport() {
        const div = document.createElement("div");
        ReactDOM.render(
            <Table dataSource={this.getData()} columns={this.getColumns(true)} size="small" pagination={false} />,
            div
        );
        Utils.copyToClip(div.innerHTML);
        notification["success"]({
            message: this.props.intl.formatMessage({
                id: "export"
            }),
            description: this.props.intl.formatMessage({
                id: "export.succeeded"
            })
        });
    }

    onEditManualInputClick() {
        this.onAddManualInputClick();
    }

    onAddManualInputClick() {
        this.setState({
            ...this.state,
            manualInputVisible: true
        });
    }

    onManualInputCancel() {
        this.setState(
            {
                ...this.state,
                manualInputVisible: false,
                manualInputLoading: false,
                manualInputError: undefined
            },
            () => {
                if (this.addManualInputForm) {
                    this.addManualInputForm.props.form.resetFields();
                }
            }
        );
    }

    onManualInputOK() {
        if (this.addManualInputForm) {
            const {
                form: { validateFields, getFieldsValue }
            } = this.addManualInputForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const fieldValues = getFieldsValue() as any;

            if (this.state.manualInput) {
                // update
                const measurement = cloneDeep(this.state.manualInput);
                measurement.average = fieldValues.average;
                measurement.standardDeviation = fieldValues.standardDeviation;

                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        manualInputLoading: true,
                        loading: true
                    },
                    () => {
                        carbonatationService
                            .updateCarbonatationMeasurementsManualInput(measurement)
                            .then(updatedMeasurement => {
                                this.onUpdateManualInput(updatedMeasurement._id, updatedMeasurement);
                            })
                            .catch(err => {
                                this.props.onLoading(false);
                                this.setState({
                                    ...this.state,
                                    manualInputLoading: false,
                                    manualInputError: err.message,
                                    loading: false
                                });
                            });
                    }
                );
            } else {
                // create
                const measurement = fieldValues as ICreateCarbonatationMeasurementManualInput;

                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        manualInputLoading: true,
                        loading: true
                    },
                    () => {
                        carbonatationService
                            .createCarbonatationMeasurementsManualInput(measurement)
                            .then(updatedMeasurement => {
                                this.onUpdateManualInput(updatedMeasurement._id, updatedMeasurement);
                            })
                            .catch(err => {
                                this.props.onLoading(false);
                                this.setState({
                                    ...this.state,
                                    manualInputLoading: false,
                                    manualInputError: err.message,
                                    loading: false
                                });
                            });
                    }
                );
            }
        }
    }

    saveManualInputFormRef = (formRef: React.PureComponent<AddCarbonatationMeasurementManualInputProps>) => {
        this.addManualInputForm = formRef;
    };

    onUpdateManualInput(manualInputId?: string, updatedManualInput?: ICarbonatationMeasurementManualInput) {
        const treeCopy = cloneDeep(this.props.tree);
        const rootNode = treeCopy.tree;
        if (rootNode) {
            projectService.loop([rootNode], this.props.currentNode.uuid, (node, index, arr) => {
                node.carbonatationMeasurementsManualInput = manualInputId;
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(modifiedTree => {
                    this.setState(
                        {
                            ...this.state,
                            manualInputLoading: false,
                            manualInputVisible: false,
                            loading: false,
                            manualInput: updatedManualInput
                        },
                        () => {
                            notification["success"]({
                                message: this.props.intl.formatMessage({
                                    id: "carbonatation.edit"
                                }),
                                description: this.props.intl.formatMessage({
                                    id: "carbonatation.edit.succeeded"
                                })
                            });
                            if (this.addManualInputForm) {
                                this.addManualInputForm.props.form.resetFields();
                            }
                        }
                    );

                    this.props.onLoading(false);
                    this.props.onEditNodes(modifiedTree);
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        manualInputLoading: false,
                        manualInputError: err.message,
                        error: err.message,
                        loading: false
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    }

    deleteManualInput() {
        if (this.state.manualInput) {
            const manualMeasurementId = this.state.manualInput._id;

            this.props.onLoading(true);
            this.setState(
                {
                    ...this.state,
                    manualInputLoading: true,
                    loading: true
                },
                () => {
                    carbonatationService
                        .deleteCarbonatationMeasurementsManualInput(manualMeasurementId)
                        .then(updatedManualMeasurement => {
                            this.onUpdateManualInput(undefined, undefined);
                        })
                        .catch(err => {
                            this.props.onLoading(false);
                            this.setState({
                                ...this.state,
                                manualInputLoading: false,
                                error: err.message,
                                loading: false
                            });
                        });
                }
            );
        }
    }

    saveAddCarbonatationFormRef = (formRef: React.PureComponent<AddCarbonatationProps>) => {
        this.addCarbonatationForm = formRef;
    };

    onEditCarbonatationMeasurements() {
        this.onAddCarbonatationButtonClick();
    }

    onDeleteCarbonatationMeasurements() {
        if (this.state.carbonatationMeasurement) {
            const measurementId = this.state.carbonatationMeasurement._id;

            this.props.onLoading(true);
            this.setState(
                {
                    ...this.state,
                    addLoading: true,
                    loading: true
                },
                () => {
                    carbonatationService
                        .deleteCarbonatationMeasurement(measurementId)
                        .then(updatedMeasurement => {
                            this.onUpdatedMeasurement(undefined, undefined);
                            // this.props.onChange(); // TODO???
                        })
                        .catch(err => {
                            this.props.onLoading(false);
                            this.setState({
                                ...this.state,
                                addLoading: false,
                                addError: err.message,
                                loading: false
                            });
                        });
                }
            );
        }
    }

    onAddCarbonatationButtonClick() {
        this.setState({
            ...this.state,
            addVisible: true
        });
    }

    onAddCarbonatationCancel() {
        this.setState(
            {
                ...this.state,
                addVisible: false,
                addLoading: false,
                addError: undefined
            },
            () => {
                if (this.addCarbonatationForm) {
                    this.addCarbonatationForm.props.form.resetFields();
                }
            }
        );
    }

    onUpdatedMeasurement(updatedMeasurementId?: string, updatedMeasurement?: ICarbonatationMeasurement) {
        const treeCopy = cloneDeep(this.props.tree);
        const rootNode = treeCopy.tree;
        if (rootNode) {
            projectService.loop([rootNode], this.props.currentNode.uuid, (node, index, arr) => {
                node.carbonatation = updatedMeasurementId;
            });

            projectService
                .editNodes(treeCopy.projectId, treeCopy)
                .then(modifiedTree => {
                    this.setState(
                        {
                            ...this.state,
                            addLoading: false,
                            addVisible: false,
                            loading: false,
                            carbonatationMeasurement: updatedMeasurement
                        },
                        () => {
                            notification["success"]({
                                message: this.props.intl.formatMessage({
                                    id: "carbonatation.edit"
                                }),
                                description: this.props.intl.formatMessage({
                                    id: "carbonatation.edit.succeeded"
                                })
                            });
                            if (this.addCarbonatationForm) {
                                this.addCarbonatationForm.props.form.resetFields();
                            }
                        }
                    );

                    this.props.onLoading(false);
                    this.props.onEditNodes(modifiedTree);
                })
                .catch(err => {
                    this.props.onLoading(false);
                    this.setState({
                        ...this.state,
                        addLoading: false,
                        addError: err.message,
                        loading: false
                    });
                });
        } else {
            this.props.onLoading(false);
        }
    }

    onAddCarbonatationOK() {
        if (this.addCarbonatationForm) {
            const {
                form: { validateFields, getFieldsValue }
            } = this.addCarbonatationForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const fieldValues = getFieldsValue() as any;

            const splitValues = fieldValues.values.replace(/\s+/gi, ",").split(/,/);
            const nrValues: number[] = [];

            each(splitValues, splitValue => {
                const parsed = parseFloat(splitValue);
                if (!isNaN(parsed) && isFinite(parsed)) {
                    nrValues.push(parsed);
                }
            });

            if (this.state.carbonatationMeasurement) {
                // update
                const measurement = cloneDeep(this.state.carbonatationMeasurement);
                measurement.measurements = nrValues;

                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        addLoading: true,
                        loading: true
                    },
                    () => {
                        carbonatationService
                            .updateCarbonatationMeasurement(measurement)
                            .then(updatedMeasurement => {
                                this.onUpdatedMeasurement(updatedMeasurement._id, updatedMeasurement);
                            })
                            .catch(err => {
                                this.props.onLoading(false);
                                this.setState({
                                    ...this.state,
                                    addLoading: false,
                                    addError: err.message,
                                    loading: false
                                });
                            });
                    }
                );
            } else {
                // create
                const measurement = {
                    measurements: nrValues
                };

                this.props.onLoading(true);
                this.setState(
                    {
                        ...this.state,
                        addLoading: true,
                        loading: true
                    },
                    () => {
                        carbonatationService
                            .createCarbonatationMeasurement(measurement)
                            .then(updatedMeasurement => {
                                this.onUpdatedMeasurement(updatedMeasurement._id, updatedMeasurement);
                            })
                            .catch(err => {
                                this.props.onLoading(false);
                                this.setState({
                                    ...this.state,
                                    addLoading: false,
                                    addError: err.message,
                                    loading: false
                                });
                            });
                    }
                );
            }
        }
    }
}

export default injectIntl(Carbonatation);
