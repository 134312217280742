import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import "./carbonatation-messages";

import { ICarbonatationMeasurement } from "../../store/projects/types";

import { Modal, Form, Input, Alert, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

interface IOwnProps {
    measurement?: ICarbonatationMeasurement;
    visible: boolean;
    loading: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    error?: string;
}

type AllProps = IOwnProps & FormComponentProps & InjectedIntlProps;

class AddCarbonatation extends React.PureComponent<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    public render() {
        const {
            form: { getFieldDecorator },
            error,
            measurement
        } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 0 },
                sm: { span: 0 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 24,
                    offset: 0
                }
            }
        };

        return (
            <Modal
                title={this.props.intl.formatMessage({
                    id: "carbonatation.add"
                })}
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                okButtonProps={{
                    disabled: this.props.loading
                }}
                cancelButtonProps={{
                    disabled: this.props.loading
                }}
            >
                <Spin spinning={this.props.loading}>
                    <Form layout="horizontal">
                        <Form.Item {...formItemLayout}>
                            {getFieldDecorator("values", {
                                initialValue: measurement ? measurement.measurements.join(" ") : ""
                            })(<Input.TextArea rows={5} autoFocus />)}
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            {error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={error}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const addCarbonatationWrapped = injectIntl(Form.create<AllProps>()(AddCarbonatation));

export { addCarbonatationWrapped as AddCarbonatation, AllProps as AddCarbonatationProps };
