import { IOrganization } from "../../store/projects/types";
import api from "./api";

class OrganizationService {
    async getOrganizations(): Promise<IOrganization[]> {
        const response = await api.get("/organizations");

        if (response && response.data && response.data.response && response.data.response.items) {
            return response.data.response.items;
        }

        // TODO how to do server error handling?
        throw new Error("Error while fetching organizations");
    }
}

export default new OrganizationService();
