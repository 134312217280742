import * as React from "react";

import { Card, Icon } from "antd";
import authService from "../../services/API/auth-service";

class Profile extends React.PureComponent {
    render() {
        const user = authService.getUser();

        return (
            <div className="container" style={{ padding: "8px 16px 0px 16px" }}>
                <h1>{user.givenName || "" + user.name}</h1>
                <Card title="Profile">
                    <img src={user.picture} alt="profile" />
                    <div>
                        <label>
                            <Icon type="user" /> Nickname
                        </label>
                        <h3>{user.name}</h3>
                    </div>
                    <pre>{JSON.stringify(user, null, 2)}</pre>
                </Card>
            </div>
        );
    }
}

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default Profile;
