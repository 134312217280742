import { defineMessages } from "react-intl";

const messages = defineMessages({
    actions: {
        id: "tree.node.actions",
        description: "Node Popover Title",
        defaultMessage: "Actions"
    },
    edit: {
        id: "tree.node.edit",
        description: "Node Popover Action Edit",
        defaultMessage: "Edit Node"
    },
    enable: {
        id: "tree.node.enable",
        description: "Node Popover Action Enable",
        defaultMessage: "Enable Node"
    },
    disable: {
        id: "tree.node.disable",
        description: "Node Popover Action Disable",
        defaultMessage: "Disable Node"
    },
    create: {
        id: "tree.node.create",
        description: "Node Popover Action Create",
        defaultMessage: "Create Child Node"
    },
    createZone: {
        id: "tree.node.createZone",
        description: "Node Popover Action Create Zone",
        defaultMessage: "Create Zone"
    },
    createElement: {
        id: "tree.node.createElement",
        description: "Node Popover Action Create Element",
        defaultMessage: "Create Element"
    },
    createReinforcement: {
        id: "tree.node.createReinforcement",
        description: "Node Popover Action Create Reinforcement",
        defaultMessage: "Create Reinforcement"
    },
    clone: {
        id: "tree.node.clone",
        description: "Node Popover Action Clone",
        defaultMessage: "Clone Node"
    },
    cloneSuffix: {
        id: "tree.node.cloneSuffix",
        description: "Clone suffix - to be added when cloning",
        defaultMessage: "(new)"
    },
    delete: {
        id: "tree.node.delete",
        description: "Node Popover Action Delete",
        defaultMessage: "Delete Node"
    },
    deleteConfirm: {
        id: "tree.node.delete.confirm",
        description: "Node Popover Action Delete Confirmation",
        defaultMessage: "Are you sure you want to delete this node and all of its children?"
    },
    manualInfo: {
        id: "tree.node.manualInfo",
        description: "Node Popover On Manual Input of Covermeasurements",
        defaultMessage:
            "This node calculates with manual input ommitting all underlying covermeasurement files. It also does not affect parent nodes."
    },
    hasFiles: {
        id: "tree.node.hasFiles",
        description: "Node Popover Has File Icon",
        defaultMessage: "This node has files"
    },
    dragFolders: {
        id: "tree.node.dragFolders",
        description: "Drag Folders Button",
        defaultMessage: "Drag Folders"
    },
    dragFiles: {
        id: "tree.node.dragFiles",
        description: "Drag Files Button",
        defaultMessage: "Drag Files"
    },
    dragTypeErrorTitle: {
        id: "tree.node.dragTypeErrorTitle",
        description: "Title of error when dragging/dropping files of wrong type",
        defaultMessage: "Error while dragging folders"
    },
    dragTypeError: {
        id: "tree.node.dragTypeError",
        description: "Error when dragging/dropping files of wrong type",
        defaultMessage: "Cannot put {dragType} in {dropType}"
    }
});

export default messages;
