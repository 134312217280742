import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./covermeasurements-messages";

import { INode } from "../../store/projects/types";

import { Alert, Form, InputNumber, Modal, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { D_UNIT } from "./chlorides";

interface IOwnProps {
    node: INode;
    visible: boolean;
    loading: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    error?: string;
}

type AllProps = IOwnProps & FormComponentProps & InjectedIntlProps;

class AddChlorideParameters extends React.PureComponent<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    public render() {
        const {
            form: { getFieldDecorator },
            error,
            node
        } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 12 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 24,
                    offset: 0
                }
            }
        };

        return (
            <Modal
                title={this.props.intl.formatMessage({
                    id: "chlorides.addParameters.title"
                })}
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                okButtonProps={{ disabled: this.props.loading }}
                cancelButtonProps={{ disabled: this.props.loading }}
            >
                <Spin spinning={this.props.loading}>
                    <Form layout="horizontal" onKeyPress={this.onKeyPress.bind(this)}>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "chlorides.d"
                            })}
                        >
                            {getFieldDecorator("d", {
                                initialValue: node && node.D !== undefined ? node.D / D_UNIT : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "chlorides.d.required"
                                        })
                                    }
                                ]
                            })(<InputNumber autoFocus min={0} step={0.01} />)}
                            <span style={{ marginLeft: "4px" }}>
                                {this.props.intl.formatMessage({ id: "chlorides.d.unit" })}
                            </span>
                        </Form.Item>

                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "chlorides.cs"
                            })}
                        >
                            {getFieldDecorator("Cs", {
                                initialValue: node && node.Cs !== undefined ? node.Cs : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "chlorides.cs.required"
                                        })
                                    }
                                ]
                            })(<InputNumber min={0} max={100} step={1} />)}
                            <span style={{ marginLeft: "4px" }}>
                                {this.props.intl.formatMessage({ id: "chlorides.unit" })}
                            </span>
                        </Form.Item>

                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "chlorides.ci"
                            })}
                        >
                            {getFieldDecorator("Ci", {
                                initialValue: node && node.Ci !== undefined ? node.Ci : undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "chlorides.ci.required"
                                        })
                                    }
                                ]
                            })(<InputNumber min={0} max={100} step={1} />)}
                            <span style={{ marginLeft: "4px" }}>
                                {this.props.intl.formatMessage({ id: "chlorides.unit" })}
                            </span>
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            {error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={error}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }

    onKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            this.props.handleOk();
        }
    }
}

const addChlorideParametersWrapped = injectIntl(Form.create<AllProps>()(AddChlorideParameters));

export { addChlorideParametersWrapped as AddChlorideParameters, AllProps as AddChlorideParametersProps };
