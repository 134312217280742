/**
 * Auth actions
 */
export enum AuthActionTypes {
    LOGIN_FINISHED = "@@auth/LOGIN_FINISHED",
    LOGIN_SUCCESS = "@@auth/LOGIN_SUCCESS",
    LOGIN_ERROR = "@@auth/LOGIN_ERROR",
    LOGOUT_REQUEST = "@@auth/LOGOUT_REQUEST",
    LOGOUT_SUCCESS = "@@auth/LOGOUT_SUCCESS",
    LOGOUT_ERROR = "@@auth/LOGOUT_ERROR"
}

/**
 * Authentication State in the store
 */
export interface IAuthState {
    readonly authenticating: boolean;
    readonly authenticated: boolean;
    readonly errors?: string;
}
