import { cloneDeep, each, range, union } from "lodash-es";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { v4 as generateUUID } from "uuid";
import authService from "../../services/API/auth-service";
import projectService from "../../services/API/project-service";
import { INode, IProjectTree, NodeStatus, NodeType } from "../../store/projects/types";
import "./projecttree-messages";

import { Button, Radio, Tree, notification } from "antd";
import { AntTreeNodeDropEvent, AntTreeNodeExpandedEvent, AntTreeNodeSelectedEvent } from "antd/es/tree/Tree";
import { CreateNode, CreateNodeProps } from "./createnode";
import NodeName from "./nodename";

interface IOwnProps {
    onSelected: (node: INode) => void;
    onEditNodes: (tree: IProjectTree) => void;
    onLoading: (loading: boolean) => void;
    onEditNodeClick: (node: INode) => void;
    tree?: IProjectTree;
    dragFiles: boolean;
    setDragFiles: (drag: boolean) => void;
    expandedNodes: string[];
    setExpanded: (nodeIds: string[]) => void;
    onLevelClicked: (level: number) => void;
}

interface IState {
    createVisible: boolean;
    createLoading: boolean;
    createError?: string;
    createNodeParent?: INode; // the parent of the node we are creating
    createNodeType?: NodeType;
}

type AllProps = IOwnProps & InjectedIntlProps;

class ProjectTree extends React.PureComponent<AllProps, IState> {
    createNodeForm?: React.Component<CreateNodeProps>;
    constructor(props: AllProps) {
        super(props);
        this.state = {
            createVisible: false,
            createLoading: false,
            createError: undefined,
            createNodeParent: undefined,
            createNodeType: undefined
        };
    }

    onRightClick() {
        // block right click on the node name lines
        return;
    }

    render() {
        const { tree, dragFiles, expandedNodes } = this.props;
        const createTreeNodes = (parent: INode | null, node: INode) => {
            if (node.children && node.children.length > 0) {
                return (
                    <Tree.TreeNode
                        key={node.uuid}
                        title={
                            <NodeName
                                node={node}
                                onEditNodeClick={this.props.onEditNodeClick}
                                onCreateNodeClick={this.onCreateNodeClick.bind(this)}
                                onCloneNodeClick={this.onCloneNodeClick.bind(this)}
                                onDeleteNodeClick={this.onDeleteNodeClick.bind(this)}
                                onDisableNodeClick={this.onDisableNodeClick.bind(this)}
                                onEnableNodeClick={this.onEnableNodeClick.bind(this)}
                                canDelete={
                                    authService.hasScope("delete:projects") && tree !== undefined && node !== tree.tree
                                }
                                canClone={tree !== undefined && node !== tree.tree}
                                canEnable={this.canEnable(node)}
                                canDisable={this.canDisable(node)}
                                canDropFiles={this.canDropFiles(node)}
                            />
                        }
                    >
                        {node.children.map((child: INode) => {
                            return createTreeNodes(node, child);
                        })}
                    </Tree.TreeNode>
                );
            }

            // leafs
            return (
                <Tree.TreeNode
                    key={node.uuid}
                    title={
                        <NodeName
                            node={node}
                            onEditNodeClick={this.props.onEditNodeClick}
                            onCreateNodeClick={this.onCreateNodeClick.bind(this)}
                            onCloneNodeClick={this.onCloneNodeClick.bind(this)}
                            onDeleteNodeClick={this.onDeleteNodeClick.bind(this)}
                            onDisableNodeClick={this.onDisableNodeClick.bind(this)}
                            onEnableNodeClick={this.onEnableNodeClick.bind(this)}
                            canDelete={
                                authService.hasScope("delete:projects") && tree !== undefined && node !== tree.tree
                            }
                            canClone={tree !== undefined && node !== tree.tree}
                            canEnable={this.canEnable(node)}
                            canDisable={this.canDisable(node)}
                            canDropFiles={this.canDropFiles(node)}
                        />
                    }
                />
            );
        };

        return (
            <div>
                <Radio.Group
                    value={dragFiles ? "files" : "folders"}
                    onChange={e => {
                        if (e.target.value === "files") {
                            this.props.setDragFiles(true);
                        } else {
                            this.props.setDragFiles(false);
                        }
                    }}
                >
                    <Radio.Button value="folders">
                        {this.props.intl.formatMessage({ id: "tree.node.dragFolders" })}
                    </Radio.Button>
                    <Radio.Button value="files">
                        {this.props.intl.formatMessage({ id: "tree.node.dragFiles" })}
                    </Radio.Button>
                </Radio.Group>
                {this.getLevelButtons()}
                {tree &&
                    tree.tree && (
                        <Tree
                            defaultSelectedKeys={[tree.tree.uuid]}
                            expandedKeys={expandedNodes}
                            onExpand={this.onExpand.bind(this)}
                            onSelect={this.onSelect.bind(this)}
                            draggable={!dragFiles}
                            onDrop={dragFiles ? undefined : this.onDrop.bind(this)}
                            onRightClick={this.onRightClick}
                        >
                            {createTreeNodes(null, tree.tree)}
                        </Tree>
                    )}
                {this.state.createNodeParent &&
                    this.state.createVisible && (
                        // by only rendering on createVisible we unmount every time which makes autofocus work better
                        <CreateNode
                            visible={this.state.createVisible}
                            handleCancel={this.onCreateNodeCancel.bind(this)}
                            handleOk={this.onCreateNodeOK.bind(this)}
                            wrappedComponentRef={this.saveCreateNodeFormRef.bind(this)}
                            error={this.state.createError}
                            loading={this.state.createLoading}
                            parentNode={this.state.createNodeParent}
                            nodeType={this.state.createNodeType}
                        />
                    )}
            </div>
        );
    }

    canEnable(node: INode) {
        if (!node.status || node.status === NodeStatus.ENABLED) {
            // no status yet, or enabled -> cannot enable again
            return false;
        }

        if (this.props.tree && this.props.tree.tree) {
            const path: INode[] = [];
            const found = projectService.getParentsPath([this.props.tree.tree], node, path);
            if (found) {
                // should always be the case
                const anyDisabledParents = path.find(p => p.status === NodeStatus.DISABLED);

                // if any disabled parent -> you cannot enable
                return !anyDisabledParents;
            }
        }

        return true;
    }

    canDisable(node: INode) {
        if (!node.status) {
            // nodes that don't have a status yet can be disabled
            return true;
        }
        if (node.status === NodeStatus.DISABLED) {
            // disabled nodes cannot be disabled again
            return false;
        }

        return true;
    }

    canDropFiles(node: INode) {
        if (node.type) {
            if (node.type === "REINFORCEMENT") {
                // reinforcment level
                return true;
            }
        } else {
            if (this.props.tree && node === this.props.tree.tree) {
                // if no type, only if not root node
                return false;
            }

            return true;
        }

        return false;
    }

    getLevelButtons() {
        let maxLevel = 0;
        if (this.props.tree && this.props.tree.tree) {
            const checkLevel = (node: INode, level: number) => {
                if (level > maxLevel) {
                    maxLevel = level;
                }
                if (node.children) {
                    node.children.forEach(child => {
                        checkLevel(child, level + 1);
                    });
                }
            };
            checkLevel(this.props.tree.tree, 0);
        }
        if (maxLevel > 0) {
            return (
                <div style={{ marginTop: "8px" }}>
                    <Button.Group>
                        {range(maxLevel).map(level => {
                            const humanLevel = level + 1;

                            return (
                                <Button
                                    key={"levelBtn_" + humanLevel}
                                    onClick={() => this.props.onLevelClicked(humanLevel)}
                                >
                                    {humanLevel}
                                </Button>
                            );
                        })}
                    </Button.Group>
                </div>
            );
        }

        return null;
    }

    saveCreateNodeFormRef = (formRef: React.PureComponent<CreateNodeProps>) => {
        this.createNodeForm = formRef;
    };

    onCreateNodeClick(node: INode, nodeType?: NodeType) {
        this.setState({
            ...this.state,
            createVisible: true,
            createNodeParent: node,
            createNodeType: nodeType
        });
    }

    onCreateNodeCancel() {
        this.setState(
            {
                ...this.state,
                createVisible: false,
                createLoading: false,
                createError: undefined,
                createNodeParent: undefined
            },
            () => {
                if (this.createNodeForm) {
                    this.createNodeForm.props.form.resetFields();
                }
            }
        );
    }

    cloneNode(sourceNode: INode, root: boolean): INode {
        const createNode = {} as INode;
        createNode.uuid = generateUUID();
        createNode.failureProbability = sourceNode.failureProbability;
        createNode.failureProbabilityChlorides = sourceNode.failureProbability;
        createNode.threshold = sourceNode.threshold;
        createNode.coverdepthQualityThreshold = sourceNode.coverdepthQualityThreshold;
        createNode.coverdepthOverlay = sourceNode.coverdepthOverlay;
        createNode.name = sourceNode.name;
        createNode.type = sourceNode.type;
        if (root) {
            createNode.name += " " + this.props.intl.formatMessage({ id: "tree.node.cloneSuffix" });
        }
        createNode.children = sourceNode.children.map(c => this.cloneNode(c, false));
        createNode.files = [];

        return createNode;
    }

    onCloneNodeClick(sourceNode: INode) {
        const { tree } = this.props;

        if (!tree || !tree.tree) {
            console.log("Cannot clone");

            return;
        }

        const clonedNode = this.cloneNode(sourceNode, true);

        const treeCopy = cloneDeep(tree);
        const rootNode = treeCopy.tree;

        if (rootNode) {
            projectService.loopCondition(
                [rootNode],
                (node: INode) => node.children.map(n => n.uuid).includes(sourceNode.uuid),
                (node, index, arr) => {
                    node.children.push(clonedNode);
                }
            );

            this.saveTree(treeCopy, "create");
        }
    }

    _createEmptyNode(name: string, children: INode[], status: NodeStatus, type?: NodeType): INode {
        const createNode = {} as INode;
        createNode.uuid = generateUUID();
        createNode.name = name;
        createNode.type = type;
        createNode.children = children;
        createNode.files = [];
        createNode.carbonatation = undefined;
        createNode.chlorides = undefined;
        createNode.status = status;

        return createNode;
    }

    onCreateNodeOK() {
        const { tree } = this.props;
        const { createNodeParent, createNodeType } = this.state;
        if (this.createNodeForm && tree && tree.tree && createNodeParent) {
            const {
                form: { validateFields, getFieldsValue }
            } = this.createNodeForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const values = getFieldsValue() as any;

            let nodeStatus = NodeStatus.ENABLED;
            if (createNodeParent.status === NodeStatus.DISABLED) {
                nodeStatus = NodeStatus.DISABLED;
            }

            const children: INode[] = [];
            if (createNodeType === "ELEMENT") {
                if (values.reinforcement && values.reinforcement.length > 0) {
                    each(values.reinforcement, (reinforcementType: string) => {
                        const childNodeName = this.props.intl.formatMessage({
                            id: "node.reinforcement." + reinforcementType
                        });
                        children.push(this._createEmptyNode(childNodeName, [], nodeStatus, "REINFORCEMENT"));
                    });
                }
            }

            const nodeName = values.name;
            const createNode = this._createEmptyNode(nodeName, children, nodeStatus, createNodeType);

            const expandNodeIds = [createNodeParent.uuid];
            if (children.length > 0) {
                // expand the node if it has children
                expandNodeIds.push(createNode.uuid);
            }

            const treeCopy = cloneDeep(tree);
            const rootNode = treeCopy.tree;
            if (rootNode) {
                projectService.loop([rootNode], createNodeParent.uuid, (node, index, arr) => {
                    node.children.push(createNode);
                });
                this.saveTree(treeCopy, "create", expandNodeIds);
            }
        }
    }

    saveTree(tree: IProjectTree, action: "create" | "update" | "delete", expandNodeIds?: string[]) {
        this.props.onLoading(true);
        this.setState(
            {
                ...this.state,
                createLoading: true
            },
            () => {
                projectService
                    .editNodes(tree.projectId, tree)
                    .then(modifiedTree => {
                        this.props.onLoading(false);
                        this.setState(
                            {
                                ...this.state,
                                createLoading: false,
                                createVisible: false
                            },
                            () => {
                                notification["success"]({
                                    message: this.props.intl.formatMessage({ id: "project.details.edit.tree" }),
                                    description: this.props.intl.formatMessage({
                                        id: "project.details.edit.tree.succeeded"
                                    })
                                });
                                this.props.onEditNodes(modifiedTree);
                                if (action === "create" && this.createNodeForm) {
                                    this.createNodeForm.props.form.resetFields();
                                }
                                if (expandNodeIds) {
                                    const newExpandedNodes = union(this.props.expandedNodes, expandNodeIds);
                                    this.props.setExpanded(newExpandedNodes);
                                }
                            }
                        );
                    })
                    .catch(err => {
                        this.props.onLoading(false);
                        if (action === "create") {
                            this.setState({
                                ...this.state,
                                createLoading: false,
                                createError: err.message
                            });
                        } else {
                            notification["error"]({
                                message: this.props.intl.formatMessage({ id: "project.details.edit.tree" }),
                                description: this.props.intl.formatMessage({
                                    id: "project.details.edit.tree.failed"
                                })
                            });
                        }
                    });
            }
        );
    }

    onSelect(selectedKeys: string[], e: AntTreeNodeSelectedEvent) {
        const { tree } = this.props;
        if (selectedKeys && selectedKeys[0] && tree && tree.tree) {
            projectService.loop([tree.tree], selectedKeys[0], node => {
                this.props.onSelected(node);
            });
        }
    }

    checkType(rootNode: INode, dragNode: INode, dropNode: INode) {
        if (dragNode.type) {
            if (dropNode.type) {
                if (dragNode.type === "ZONE") {
                    if (dropNode.type === "ZONE") {
                        return true;
                    }
                } else if (dragNode.type === "ELEMENT") {
                    if (dropNode.type === "ZONE") {
                        return true;
                    }
                } else if (dragNode.type === "REINFORCEMENT") {
                    if (dropNode.type === "ELEMENT") {
                        return true;
                    }
                }

                return false;
            } else {
                if (dropNode === rootNode) {
                    // only zone on rootNode
                    if (dragNode.type === "ZONE") {
                        return true;
                    } else {
                        return false;
                    }
                }

                // no type -> ok
                return true;
            }
        } else {
            // no type -> ok
            return true;
        }
    }

    sendErrorMessage(dragType?: NodeType, dropType?: NodeType) {
        if (dragType && dropType) {
            notification["error"]({
                message: this.props.intl.formatMessage({ id: "tree.node.dragTypeErrorTitle" }),
                description: this.props.intl.formatMessage(
                    {
                        id: "tree.node.dragTypeError"
                    },
                    {
                        dragType: this.props.intl.formatMessage({ id: "node.type." + dragType }),
                        dropType: this.props.intl.formatMessage({ id: "node.type." + dropType })
                    }
                )
            });
        }
    }

    onDrop(info: AntTreeNodeDropEvent) {
        const { tree } = this.props;
        if (tree && tree.tree) {
            const copy = cloneDeep(tree);
            const rootNode = copy.tree;

            const dropKey = info.node.props.eventKey;
            const dragKey = info.dragNode.props.eventKey;
            const dropPos = info.node.props.pos.split("-");
            const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

            if (rootNode && dropKey && dragKey) {
                let dropNode: INode | undefined;
                let parentDropNode: INode | undefined;
                let dropArray: INode[] | undefined;
                let dropIndex: number | undefined;
                projectService.loop([rootNode], dropKey, (node, index, arr, level, parentNode) => {
                    dropNode = node;
                    dropIndex = index;
                    dropArray = arr;
                    parentDropNode = parentNode;
                });
                if (dropNode === rootNode && info.dropToGap) {
                    // no operations around root node
                    return;
                }

                let dragNode: INode | undefined;
                projectService.loop([rootNode], dragKey, (node, index, arr, parent) => {
                    arr.splice(index, 1); // remove from current parent
                    dragNode = node;
                });

                // console.log(dragNode?.name, dropNode?.name, parentDropNode?.name);

                if (dropNode && dragNode && dropIndex !== undefined && dropArray && parentDropNode) {
                    if (!info.dropToGap) {
                        // dropped on the node
                        // console.log("drop on node");
                        if (this.checkType(rootNode, dragNode, dropNode)) {
                            dropNode.children.push(dragNode); // add to end of new
                            this.setNodeAndChildrenStatus(dragNode, dropNode.status); // take over status of parent
                        } else {
                            this.sendErrorMessage(dragNode.type, dropNode.type);

                            return;
                        }
                    } else if (
                        React.Children.count(info.node.props.children) > 0 && // Has children
                        info.node.props.expanded && // Is expanded
                        dropPosition === 1 // On the bottom gap
                    ) {
                        // drop right after the node -> add to the beginning of that node
                        // console.log("drop on bottom gap");

                        if (this.checkType(rootNode, dragNode, dropNode)) {
                            dropNode.children.unshift(dragNode); // add to beginning of new
                            this.setNodeAndChildrenStatus(dragNode, dropNode.status); // take over status of parent
                        } else {
                            this.sendErrorMessage(dragNode.type, dropNode.type);

                            return;
                        }
                    } else {
                        // add drag on right pos
                        if (dropPosition === -1) {
                            // console.log("drop dropPosition -1");
                            if (this.checkType(rootNode, dragNode, parentDropNode)) {
                                dropArray.splice(dropIndex, 0, dragNode);
                                this.setNodeAndChildrenStatus(dragNode, parentDropNode.status); // take over status of parent
                            } else {
                                this.sendErrorMessage(dragNode.type, parentDropNode.type);

                                return;
                            }
                        } else {
                            // console.log("drop dropPosition NOT -1");
                            if (this.checkType(rootNode, dragNode, parentDropNode)) {
                                dropArray.splice(dropIndex + 1, 0, dragNode);
                                this.setNodeAndChildrenStatus(dragNode, parentDropNode.status); // take over status of parent
                            } else {
                                this.sendErrorMessage(dragNode.type, parentDropNode.type);

                                return;
                            }
                        }
                    }

                    this.saveTree(copy, "update");
                }
            }
        }
    }

    onDeleteNodeClick(nodeToDelete: INode) {
        const { tree } = this.props;
        if (tree && tree.tree) {
            const treeCopy = cloneDeep(tree);
            const rootNode = treeCopy.tree;
            const nodeId = nodeToDelete.uuid;
            if (rootNode) {
                projectService.loop([rootNode], nodeId, (node, index, arr) => {
                    arr.splice(index, 1);
                });

                this.saveTree(treeCopy, "delete");
            }
        }
    }

    setNodeAndChildrenStatus(node: INode, status?: NodeStatus) {
        node.status = status;
        if (node.children) {
            node.children.forEach(child => {
                this.setNodeAndChildrenStatus(child, status);
            });
        }
    }

    setNodeStatusInTree(nodeToSet: INode, status: NodeStatus) {
        const { tree } = this.props;
        if (tree && tree.tree) {
            const treeCopy = cloneDeep(tree);
            const rootNode = treeCopy.tree;
            const nodeId = nodeToSet.uuid;
            if (rootNode) {
                projectService.loop([rootNode], nodeId, (node, index, arr) => {
                    this.setNodeAndChildrenStatus(node, status);
                });

                this.saveTree(treeCopy, "update");
            }
        }
    }

    onDisableNodeClick(nodeToDisable: INode) {
        this.setNodeStatusInTree(nodeToDisable, NodeStatus.DISABLED);
    }

    onEnableNodeClick(nodeToEnable: INode) {
        this.setNodeStatusInTree(nodeToEnable, NodeStatus.ENABLED);
    }

    onExpand(expandedKeys: string[], info: AntTreeNodeExpandedEvent) {
        this.props.setExpanded(expandedKeys);
    }
}

export default injectIntl(ProjectTree);
