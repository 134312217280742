import { cloneDeep, union } from "lodash-es";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import covermeasurementService from "../../services/API/covermeasurement-service";
import projectService from "../../services/API/project-service";
import { INode, IProject, IProjectTree, ProjectStatus, PropagationLevel } from "../../store/projects/types";

import { InjectedIntlProps, injectIntl } from "react-intl";
import "./node-messages";
import "./projects-messages";

import { Alert, Spin, notification } from "antd";
import { EditNode, EditNodeProps } from "./editnode";
import { EditProject, EditProjectProps } from "./editproject";
import NodeDetails from "./nodedetails";
import ProjectHeader from "./projectheader";
import ProjectTree from "./projecttree";
import Utils from "./utils";

interface IState {
    loading: boolean;
    calculationTriggerRequested: boolean;
    project?: IProject;
    error?: string;
    warning?: string;

    tree?: IProjectTree;
    expandedNodes: string[];
    currentNode?: INode;
    dragFiles: boolean;

    editProjectVisible: boolean;
    editProjectLoading: boolean;
    editProjectError?: string;
    editProjectSinceLoad: boolean;

    editNodeVisible: boolean;
    editNodeLoading: boolean;
    editNodeError?: string;
}

interface IRouteParams {
    id: string;
}

type AllProps = RouteComponentProps<IRouteParams> & InjectedIntlProps;

class Project extends React.PureComponent<AllProps, IState> {
    editProjectForm?: React.PureComponent<EditProjectProps>;
    editNodeForm?: React.Component<EditNodeProps>;

    constructor(props: AllProps) {
        super(props);
        this.state = {
            loading: false,
            calculationTriggerRequested: false,
            project: undefined,
            error: undefined,
            warning: undefined,
            tree: undefined,
            expandedNodes: [],
            currentNode: undefined,
            dragFiles: false,

            editProjectVisible: false,
            editProjectLoading: false,
            editProjectError: undefined,
            editProjectSinceLoad: false,

            editNodeVisible: false,
            editNodeLoading: false,
            editNodeError: undefined
        };
        this.editProjectForm = undefined;
    }

    checkCalculation(projectId: string) {
        projectService
            .getProject(projectId)
            .then(project => {
                const projectStatus = project.status;
                if (projectStatus === ProjectStatus.Calculating) {
                    // still busy processing, try again in 2s
                    this.setState({
                        ...this.state,
                        project
                    });
                    setTimeout(() => {
                        this.checkCalculation(projectId);
                    }, 2000);
                } else {
                    this.setState({
                        ...this.state,
                        project,
                        loading: false,
                        editProjectSinceLoad: false
                    });
                    this.refetchTree(projectId);

                    if (project.status === ProjectStatus.Done) {
                        notification["success"]({
                            message: this.props.intl.formatMessage({
                                id: "project.calculation.finished.title"
                            }),
                            description: this.props.intl.formatMessage({
                                id: "project.calculation.finished.description"
                            })
                        });
                    } else {
                        notification["error"]({
                            message: this.props.intl.formatMessage({
                                id: "project.calculation.failed.title"
                            }),
                            description: this.props.intl.formatMessage({
                                id: "project.calculation.failed.description"
                            })
                        });
                    }
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    project: undefined,
                    tree: undefined,
                    currentNode: undefined,
                    loading: false,
                    error: err.message
                });
            });
    }

    fetchProject(projectId: string) {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                projectService
                    .getProject(projectId)
                    .then(project => {
                        const projectStatus = project.status;
                        if (
                            projectStatus === ProjectStatus.FileUploaded ||
                            projectStatus === ProjectStatus.FileProcessing
                        ) {
                            // still busy processing, try again in 2s
                            setTimeout(() => {
                                this.fetchProject(projectId);
                            }, 2000);
                        } else {
                            this.setState({ ...this.state, project, loading: false });
                            this.fetchTree(projectId);
                            if (projectStatus === ProjectStatus.Calculating) {
                                setTimeout(() => {
                                    this.checkCalculation(projectId);
                                }, 2000);
                            }
                        }
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            project: undefined,
                            tree: undefined,
                            currentNode: undefined,
                            loading: false,
                            error: err.message
                        });
                    });
            }
        );
    }

    fetchTree(projectId: string, callback?: (tree: IProjectTree) => void) {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                projectService
                    .getNodes(projectId)
                    .then(tree => {
                        const rootNode = tree.tree;
                        if (rootNode) {
                            const wasUndefined = !this.state.tree;
                            this.setState({
                                ...this.state,
                                tree,
                                currentNode: this.state.currentNode || rootNode,
                                loading: false,
                                error: undefined
                            });
                            if (wasUndefined) {
                                // arbitrary high number to expand all
                                this.onLevelClicked(999);
                            }
                        } else {
                            // should not happen
                            this.setState({
                                ...this.state,
                                tree: undefined,
                                currentNode: undefined,
                                loading: false,
                                error: "Rootnode not found"
                            });
                        }

                        return tree;
                    })
                    .then(tree => {
                        if (callback) {
                            callback(tree);
                        }
                    });
            }
        );
    }

    componentDidMount() {
        const { match } = this.props;
        const projectId = match.params.id;

        this.fetchProject(projectId);
    }

    render() {
        const { loading, error, warning, project, tree, currentNode, dragFiles, expandedNodes } = this.state;

        return (
            <div
                style={{ height: "100%", padding: "8px 16px 0px 16px", display: "flex", flexDirection: "column" }}
                className="project"
            >
                <ProjectHeader
                    project={project}
                    tree={tree}
                    calculationOngoing={
                        (project && project.status === ProjectStatus.Calculating) ||
                        this.state.calculationTriggerRequested
                    }
                    loading={loading}
                    editSinceLoad={this.state.editProjectSinceLoad}
                    onEditProjectButtonClick={this.onEditProjectButtonClick.bind(this)}
                    onRecalculateButtonClick={this.onRecalculateButtonClick.bind(this)}
                    onDeleteProjectButtonClick={this.onDeleteProjectButtonClick.bind(this)}
                    onRecoverProjectButtonClick={this.onRecoverProjectButtonClick.bind(this)}
                    onDownloadZipButtonClick={this.onDownloadZipButtonClick.bind(this)}
                />
                {project &&
                    this.state.editProjectVisible && (
                        <EditProject
                            visible={this.state.editProjectVisible}
                            handleCancel={this.onEditProjectCancel.bind(this)}
                            handleOk={this.onEditProjectOK.bind(this)}
                            project={project}
                            wrappedComponentRef={this.saveEditProjectFormRef.bind(this)}
                            error={this.state.editProjectError}
                            loading={this.state.editProjectLoading}
                        />
                    )}
                <Spin
                    tip={this.props.intl.formatMessage({
                        id: "loading"
                    })}
                    spinning={loading}
                    wrapperClassName="projectSpinWrapper"
                >
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "4px",
                            flexDirection: "row",
                            alignItems: "stretch",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        {project && (
                            <div
                                style={
                                    { paddingTop: "8px", paddingRight: "8px", flex: "0 0 auto", overflowY: "auto" }
                                    // height: "calc(100vh - 377px)"
                                }
                            >
                                <ProjectTree
                                    tree={tree}
                                    onSelected={this.onNodeSelected.bind(this)}
                                    onEditNodes={this.onEditNodes.bind(this)}
                                    onLoading={this.onLoading.bind(this)}
                                    onEditNodeClick={this.onEditNodeClick.bind(this)}
                                    dragFiles={dragFiles}
                                    setDragFiles={this.setDragFiles.bind(this)}
                                    expandedNodes={expandedNodes}
                                    setExpanded={this.setExpanded.bind(this)}
                                    onLevelClicked={this.onLevelClicked.bind(this)}
                                />
                            </div>
                        )}
                        <div
                            style={
                                { flex: "1 1 auto", overflowY: "auto", padding: "0px 8px 0px 24px" } // height: "calc(100vh - 377px)",
                            }
                        >
                            {error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={error}
                                    type="error"
                                    showIcon
                                />
                            )}
                            {warning && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "warning"
                                    })}
                                    description={warning}
                                    type="warning"
                                    showIcon
                                    style={{ whiteSpace: "pre-line" }}
                                />
                            )}
                            {!error &&
                                project &&
                                currentNode &&
                                tree && (
                                    <div>
                                        <NodeDetails
                                            node={currentNode}
                                            project={project}
                                            tree={tree}
                                            expandedNodes={expandedNodes}
                                            dragFiles={dragFiles}
                                            onLoading={this.onLoading.bind(this)}
                                            onChange={this.onChange.bind(this)}
                                            onEditNodes={this.onEditNodes.bind(this)}
                                            onEditNodeClick={this.onEditNodeClick.bind(this)}
                                            onErrorFilesFound={this.onErrorFilesFound.bind(this)}
                                        />
                                        {this.state.editNodeVisible && (
                                            <EditNode
                                                visible={this.state.editNodeVisible}
                                                handleCancel={this.onEditNodeCancel.bind(this)}
                                                handleOk={this.onEditNodeOK.bind(this)}
                                                wrappedComponentRef={this.saveEditNodeFormRef.bind(this)}
                                                error={this.state.editNodeError}
                                                loading={this.state.editNodeLoading}
                                                node={{ ...currentNode }}
                                            />
                                        ) // take copy
                                        }
                                    </div>
                                )}
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }

    refetchTree(projectId: string) {
        this.fetchTree(projectId, tree => {
            if (tree.tree && this.state.currentNode) {
                projectService.loop([tree.tree], this.state.currentNode.uuid, node => {
                    this.setState({ ...this.state, currentNode: node });
                });
            }
        });
    }

    onChange() {
        this.setState({
            ...this.state,
            editProjectSinceLoad: true
        });
        if (this.state.project) {
            this.state.project.status = ProjectStatus.Dirty;
            projectService.editProject(this.state.project).then(updatedProject => this.refetchTree(updatedProject._id));
        }
    }

    onNodeSelected(node: INode) {
        this.setState({
            ...this.state,
            currentNode: node
        });
    }

    onRecoverProjectButtonClick() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                if (this.state.project) {
                    const projectToUpdate = cloneDeep(this.state.project);
                    projectToUpdate.status = ProjectStatus.Dirty;

                    projectService
                        .editProject(projectToUpdate)
                        .then(project => {
                            this.setState(
                                {
                                    ...this.state,
                                    project,
                                    loading: false,
                                    editProjectVisible: false,
                                    editProjectLoading: false,
                                    editProjectSinceLoad: true
                                },
                                () => {
                                    notification["success"]({
                                        message: this.props.intl.formatMessage({
                                            id: "project.details.edit"
                                        }),
                                        description: this.props.intl.formatMessage({
                                            id: "project.details.edit.succeeded"
                                        })
                                    });
                                }
                            );
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                loading: false,
                                editProjectLoading: false,
                                editProjectError: err.message
                            });
                        });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
        );
    }

    onDeleteProjectButtonClick() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                if (this.state.project) {
                    projectService
                        .deleteProject(this.state.project._id)
                        .then(response => {
                            this.setState(
                                {
                                    ...this.state,
                                    loading: false,
                                    editProjectSinceLoad: true
                                },
                                () => {
                                    this.props.history.push("/projects");
                                }
                            );
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                loading: false,
                                error: err.message
                            });
                        });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
        );
    }

    onDownloadZipButtonClick() {
        if (this.state.tree && this.state.tree.archiveURL) {
            window.open(Utils.getDownloadURL(this.state.tree.archiveURL), "_blank");
        }
    }

    onErrorFilesFound(fileNames: string[]) {
        let warning;

        if (fileNames && fileNames.length) {
            warning =
                this.props.intl.formatMessage({
                    id: "project.measurements.error"
                }) +
                "\n- " +
                fileNames.join("\n- ");
        }
        this.setState({
            ...this.state,
            warning
        });
    }

    onEditNodes(tree: IProjectTree, dontSetDirty?: boolean) {
        if (!dontSetDirty) {
            this.onChange();
        }

        let newCurrentNode = this.state.currentNode;
        if (tree.tree && this.state.currentNode) {
            projectService.loop([tree.tree], this.state.currentNode.uuid, node => {
                newCurrentNode = node;
            });
        }

        this.setState({
            ...this.state,
            tree,
            currentNode: newCurrentNode,
            editProjectSinceLoad: !dontSetDirty
        });
    }

    onLoading(loading: boolean) {
        this.setState({
            ...this.state,
            loading
        });
    }

    onRecalculateButtonClick(fast: boolean) {
        if (!this.state.project) {
            return;
        }
        const projectToCalculate = this.state.project._id;

        this.setState(
            {
                ...this.state,
                calculationTriggerRequested: true,
                project: { ...this.state.project, calculationProgress: 0 }
            },
            () => {
                projectService
                    .calculate(projectToCalculate, fast)
                    .then(calculationResponse => {
                        this.checkCalculation(projectToCalculate);
                        this.setState({
                            ...this.state,
                            // TODO BAMT: this can probably be removed later
                            calculationTriggerRequested: false
                        });
                        /*setTimeout(() => {
                        }, 3000);*/
                    })
                    .catch(err => {
                        this.setState({ ...this.state, calculationTriggerRequested: false, error: err.message });
                    });
            }
        );
    }

    onEditProjectButtonClick() {
        this.setState({
            ...this.state,
            editProjectVisible: true
        });
    }

    onEditProjectCancel() {
        this.setState(
            {
                ...this.state,
                editProjectVisible: false,
                editProjectLoading: false,
                editProjectError: undefined
            },
            () => {
                if (this.editProjectForm) {
                    this.editProjectForm.props.form.resetFields();
                }
            }
        );
    }

    onEditProjectOK(ageInYears: boolean) {
        if (this.editProjectForm && this.state.project) {
            const projectId = this.state.project._id;
            const {
                form: { validateFields, getFieldsValue }
            } = this.editProjectForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const values = getFieldsValue() as any;
            if (values.calculationMethod === "deterministic") {
                values["chloridesBetaStd"] = 0;
            }

            if (ageInYears) {
                values["age"] = values["ageInYears"];
            } else {
                values["age"] = values["ageInDays"] / 365;
            }
            delete values["ageInYears"];
            delete values["ageInDays"];

            delete values["calculationMethod"];

            const updateProject = values as IProject;
            updateProject._id = projectId;

            const thresholdChanged = updateProject.coverdepthThreshold !== this.state.project.coverdepthThreshold;
            const qualityThresholdChanged =
                updateProject.coverdepthQualityThreshold !== this.state.project.coverdepthQualityThreshold;
            const overlayChanged = updateProject.coverdepthOverlay !== this.state.project.coverdepthOverlay;

            console.log("threshold changes", thresholdChanged, qualityThresholdChanged, overlayChanged);

            this.setState(
                {
                    ...this.state,
                    loading: true,
                    editProjectLoading: true
                },
                () => {
                    projectService
                        .editProject(updateProject)
                        .then(async project => {
                            // Regenerate graphs if needed
                            if (
                                (thresholdChanged || qualityThresholdChanged || overlayChanged) &&
                                this.state.tree &&
                                this.state.tree.tree
                            ) {
                                console.log("Regenerating graphs");
                                const measurements = await covermeasurementService.getCovermeasurements(
                                    projectId,
                                    this.state.tree.tree.uuid
                                );

                                const updateGraphs = measurements.filter(m => {
                                    // no graphs yet or propagated node
                                    return (
                                        !m.coverPlotSmall ||
                                        (thresholdChanged &&
                                            m.propagatedThreshold.sourceLevel === PropagationLevel.PROJECT) ||
                                        (qualityThresholdChanged &&
                                            m.propagatedCoverdepthQualityThreshold.sourceLevel ===
                                                PropagationLevel.PROJECT) ||
                                        (overlayChanged &&
                                            m.propagatedCoverdepthOverlay.sourceLevel === PropagationLevel.PROJECT)
                                    );
                                });
                                if (updateGraphs && updateGraphs.length > 0) {
                                    console.log(updateGraphs);
                                    const allGraphs = updateGraphs.map(measurement =>
                                        covermeasurementService.generateCoverMeasurementGraph(
                                            projectId,
                                            measurement._id
                                        )
                                    );
                                    await Promise.all(allGraphs);
                                    console.log("All relevant graphs updated");
                                }
                            }

                            this.setState(
                                {
                                    ...this.state,
                                    project,
                                    loading: true,
                                    editProjectVisible: false,
                                    editProjectLoading: false,
                                    editProjectSinceLoad: true
                                },
                                () => {
                                    notification["success"]({
                                        message: this.props.intl.formatMessage({
                                            id: "project.details.edit"
                                        }),
                                        description: this.props.intl.formatMessage({
                                            id: "project.details.edit.succeeded"
                                        })
                                    });
                                    this.refetchTree(projectId);
                                }
                            );
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                loading: false,
                                editProjectLoading: false,
                                editProjectError: err.message
                            });
                        });
                }
            );
        }
    }

    saveEditProjectFormRef = (formRef: React.PureComponent<EditProjectProps>) => {
        this.editProjectForm = formRef;
    };

    onEditNodeClick(node: INode) {
        this.setState({
            ...this.state,
            editNodeVisible: true,
            currentNode: node
        });
    }

    onEditNodeCancel() {
        this.setState(
            {
                ...this.state,
                editNodeVisible: false,
                editNodeLoading: false,
                editNodeError: undefined
            },
            () => {
                if (this.editNodeForm) {
                    this.editNodeForm.props.form.resetFields();
                }
            }
        );
    }

    onEditNodeOK() {
        const { currentNode, tree } = this.state;
        if (this.editNodeForm && currentNode && tree && tree.tree) {
            const nodeId = currentNode.uuid;
            const {
                form: { validateFields, getFieldsValue }
            } = this.editNodeForm.props;

            let isValidForm = true;
            validateFields(err => {
                if (err) {
                    isValidForm = false;
                }
            });

            if (!isValidForm) {
                return;
            }

            const values = getFieldsValue() as any;
            const updateNode = values as INode;

            const treeCopy = cloneDeep(tree);
            const rootNode = treeCopy.tree;
            if (rootNode) {
                let thresholdChanged = false;
                let thresholdCleared = false;
                let qualityThresholdChanged = false;
                let qualityThresholdCleared = false;
                let overlayChanged = false;
                let overlayCleared = false;
                projectService.loop([rootNode], nodeId, (node, index, arr) => {
                    node.name = updateNode.name;
                    thresholdChanged = node.threshold !== updateNode.threshold;
                    thresholdCleared = updateNode.threshold === null;
                    node.threshold = updateNode.threshold;
                    qualityThresholdChanged = node.coverdepthQualityThreshold !== updateNode.coverdepthQualityThreshold;
                    qualityThresholdCleared = updateNode.coverdepthQualityThreshold === null;
                    node.coverdepthQualityThreshold = updateNode.coverdepthQualityThreshold;
                    overlayChanged = node.coverdepthOverlay !== updateNode.coverdepthOverlay;
                    overlayCleared = updateNode.coverdepthOverlay === null;
                    node.coverdepthOverlay = updateNode.coverdepthOverlay;
                    node.failureProbability = updateNode.failureProbability;
                    node.failureProbabilityChlorides = updateNode.failureProbabilityChlorides;
                    node.chloridesAgingFactor = updateNode.chloridesAgingFactor;
                });

                this.saveNodeDetails(
                    treeCopy,
                    thresholdChanged,
                    thresholdCleared,
                    qualityThresholdChanged,
                    qualityThresholdCleared,
                    overlayChanged,
                    overlayCleared
                );
            }
        }
    }

    saveEditNodeFormRef = (formRef: React.PureComponent<EditNodeProps>) => {
        this.editNodeForm = formRef;
    };

    saveNodeDetails(
        tree: IProjectTree,
        thresholdChanged: boolean,
        thresholdCleared: boolean,
        qualityThresholdChanged: boolean,
        qualityThresholdCleared: boolean,
        overlayChanged: boolean,
        overlayCleared: boolean
    ) {
        this.setState(
            {
                ...this.state,
                loading: true,
                editNodeLoading: true
            },
            () => {
                projectService
                    .editNodes(tree.projectId, tree)
                    .then(async modifiedTree => {
                        let newNode = this.state.currentNode;
                        if (this.state.currentNode) {
                            projectService.loop([modifiedTree.tree], this.state.currentNode.uuid, node => {
                                newNode = node; // updated node
                            });

                            // Regenerate graphs if needed
                            if (thresholdChanged || qualityThresholdChanged || overlayChanged) {
                                console.log("Regenerating graphs");
                                const measurements = await covermeasurementService.getCovermeasurements(
                                    tree.projectId,
                                    this.state.currentNode.uuid
                                );

                                const updateGraphs = measurements.filter(m => {
                                    // no graphs yet or propagated node = node or propagated became project after clearing
                                    return (
                                        !m.coverPlotSmall ||
                                        (thresholdChanged &&
                                            m.propagatedThreshold.sourceLevel === PropagationLevel.NODE) ||
                                        (qualityThresholdChanged &&
                                            m.propagatedCoverdepthQualityThreshold.sourceLevel ===
                                                PropagationLevel.NODE) ||
                                        (overlayChanged &&
                                            m.propagatedCoverdepthOverlay.sourceLevel === PropagationLevel.NODE) ||
                                        (thresholdChanged &&
                                            thresholdCleared &&
                                            m.propagatedThreshold.sourceLevel === PropagationLevel.PROJECT) ||
                                        (qualityThresholdChanged &&
                                            qualityThresholdCleared &&
                                            m.propagatedCoverdepthQualityThreshold.sourceLevel ===
                                                PropagationLevel.PROJECT) ||
                                        (overlayChanged &&
                                            overlayCleared &&
                                            m.propagatedCoverdepthOverlay.sourceLevel === PropagationLevel.PROJECT)
                                    );
                                });
                                if (updateGraphs && updateGraphs.length > 0) {
                                    console.log(updateGraphs);
                                    const allGraphs = updateGraphs.map(measurement =>
                                        covermeasurementService.generateCoverMeasurementGraph(
                                            tree.projectId,
                                            measurement._id
                                        )
                                    );
                                    await Promise.all(allGraphs);
                                    console.log("All relevant graphs updated");
                                }
                            }
                        }
                        this.onChange();
                        this.setState(
                            {
                                ...this.state,
                                editNodeVisible: false,
                                editNodeLoading: false,
                                loading: false,
                                tree: modifiedTree,
                                currentNode: newNode,
                                editProjectSinceLoad: true
                            },
                            () => {
                                notification["success"]({
                                    message: this.props.intl.formatMessage(
                                        {
                                            id: "node.edit.title"
                                        },
                                        { name: this.state.currentNode ? this.state.currentNode.name : "" }
                                    ),
                                    description: this.props.intl.formatMessage({
                                        id: "node.edit.succeeded"
                                    })
                                });
                                if (this.editNodeForm) {
                                    this.editNodeForm.props.form.resetFields();
                                }
                            }
                        );
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            editNodeLoading: false,
                            editNodeError: err.message
                        });
                    });
            }
        );
    }

    setDragFiles(drag: boolean) {
        this.setState({
            ...this.state,
            dragFiles: drag
        });
    }

    setExpanded(nodeIds: string[]) {
        this.setState({
            ...this.state,
            expandedNodes: nodeIds
        });
    }

    onLevelClicked(level: number) {
        const { tree } = this.state;
        if (tree && tree.tree) {
            let expandedKeys: string[] = [];
            const checkLevel = (node: INode, path: string[]) => {
                if (path.length <= level) {
                    expandedKeys = union(expandedKeys, path);

                    if (node.children) {
                        const newPath = path.concat([node.uuid]);
                        node.children.forEach(child => {
                            checkLevel(child, newPath);
                        });
                    }
                }
            };
            checkLevel(tree.tree, []);
            this.setExpanded(expandedKeys);
        }
    }
}

export default injectIntl(Project);
