import { defineMessages } from "react-intl";

const messages = defineMessages({
    overview: {
        id: "node.overview",
        description: "Node Overview Tab",
        defaultMessage: "Overview"
    },
    info: {
        id: "node.info",
        description: "Node Info",
        defaultMessage: "Node Info"
    },
    concreteCover: {
        id: "node.concreteCover",
        description: "Node Concrete Cover Title",
        defaultMessage: "Concrete Cover"
    },
    project: {
        id: "project",
        description: "Project (the word project - title of the object)",
        defaultMessage: "Project"
    },
    chlorides: {
        id: "node.chlorides",
        description: "Node Chlorides Tab",
        defaultMessage: "Chlorides"
    },
    covermeasurements: {
        id: "node.covermeasurements",
        description: "Node Covermeasurements Tab",
        defaultMessage: "Concrete Cover"
    },
    separationSpace: {
        id: "node.separationSpace",
        description: "Node Separation Space Tab",
        defaultMessage: "Rebar Spacing"
    },
    carbonatation: {
        id: "node.carbonatation",
        description: "Node Carbonation Tab",
        defaultMessage: "Carbonation"
    },
    coverdepthThreshold: {
        id: "node.coverdepthThreshold",
        description: "Node Default Coverdepth Threshold",
        defaultMessage: "Maximum Concrete Cover Threshold"
    },
    coverdepthQualityThreshold: {
        id: "node.coverdepthQualityThreshold",
        description: "Node Default Coverdepth Quality Threshold",
        defaultMessage: "Minimum Quality Threshold"
    },
    coverdepthOverlay: {
        id: "node.coverdepthOverlay",
        description: "Node Default Coverdepth Overlay",
        defaultMessage: "Overlay"
    },
    failureProbability: {
        id: "node.failureProbability",
        description: "Node Carbonation Failure Probability",
        defaultMessage: "Critical Probability of Failure"
    },
    failureProbabilityChlorides: {
        id: "node.failureProbabilityChlorides",
        description: "Node Chlorides Failure Probability",
        defaultMessage: "Critical Probability of Failure"
    },
    chloridesAgingFactor: {
        id: "node.chloridesAgingFactor",
        description: "Node Chlorides Aging Factor",
        defaultMessage: "Aging Exponent α"
    },
    name: {
        id: "node.name",
        description: "Node Name",
        defaultMessage: "Node Name"
    },
    nameRequired: {
        id: "node.name.required",
        description: "Node Name Required",
        defaultMessage: "Please enter a Node Name"
    },
    type: {
        id: "node.type",
        description: "Node Type",
        defaultMessage: "Node Type"
    },
    zone: {
        id: "node.type.ZONE",
        description: "Node Type Zone",
        defaultMessage: "Zone"
    },
    element: {
        id: "node.type.ELEMENT",
        description: "Node Type Element",
        defaultMessage: "Element"
    },
    reinforcement: {
        id: "node.type.REINFORCEMENT",
        description: "Node Type Reinforcement",
        defaultMessage: "Reinforcement"
    },
    editTitle: {
        id: "node.edit.title",
        description: "Node Popover Title Edit",
        defaultMessage: "Edit Node {name}"
    },
    editSucceeded: {
        id: "node.edit.succeeded",
        description: "Node Details Edit Succeeded Notification",
        defaultMessage: "Node Parameters are successfully changed"
    },
    createChildTitle: {
        id: "node.create.title",
        description: "Node Popover Title Create Child",
        defaultMessage: "Create Child Node of {name}"
    },
    min: {
        id: "node.min",
        description: "Minimum Coverdepth",
        defaultMessage: "Concrete Cover - Minimum [mm]"
    },
    minShort: {
        id: "node.min.short",
        description: "Minimum",
        defaultMessage: "Min"
    },
    max: {
        id: "node.max",
        description: "Maximum Coverdepth",
        defaultMessage: "Concrete Cover - Maximum [mm]"
    },
    maxShort: {
        id: "node.max.short",
        description: "Maximum",
        defaultMessage: "Max"
    },
    count: {
        id: "node.count",
        description: "Number of Covermeasurements",
        defaultMessage: "Concrete Cover - Number of Measurements"
    },
    countShort: {
        id: "node.count.short",
        description: "count",
        defaultMessage: "#"
    },
    avg: {
        id: "node.avg",
        description: "Average Coverdepth",
        defaultMessage: "Concrete Cover - Mean Value [mm]"
    },
    avgShort: {
        id: "node.avg.short",
        description: "average",
        defaultMessage: "μ"
    },
    std: {
        id: "node.sd",
        description: "Standard Deviation of Coverdepth",
        defaultMessage: "Concrete Cover - Standard Deviation [mm]"
    },
    stdShort: {
        id: "node.sd.short",
        description: "Standard Deviation",
        defaultMessage: "σ"
    },
    pf: {
        id: "node.pf",
        description: "Failure probability used to calculate lifetime",
        defaultMessage: "Carbonation - Critical Probability of Failure [%]"
    },
    calculatedFailureProbabilityCarbonation: {
        id: "node.calculatedFailureProbabilityCarbonation",
        description: "Failure Probability Carbonation",
        defaultMessage: "Carbonation - Probability of Failure [%]"
    },
    calculatedLifeExpectancyCarbonation: {
        id: "node.calculatedLifetimeExpectancyCarbonation",
        description: "Life Expectancy Carbonation",
        defaultMessage: "Carbonation - Service Life [year]"
    },
    calculatedRemainingLifeExpectancyCarbonation: {
        id: "node.calculatedRemainingLifetimeExpectancyCarbonation",
        description: "Remaining Life Expectancy Carbonation",
        defaultMessage: "Carbonation - Remaining Service Life [year]"
    },
    pfChlorides: {
        id: "node.pfChlorides",
        description: "Failure probability used to calculate lifetime",
        defaultMessage: "Chlorides - Critical Probability of Failure [%]"
    },
    calculatedFailureProbabilityChlorides: {
        id: "node.calculatedFailureProbabilityChlorides",
        description: "Failure Probability Chlorides",
        defaultMessage: "Chlorides - Probability of Failure [%]"
    },
    calculatedLifeExpectancyChlorides: {
        id: "node.calculatedLifetimeExpectancyChlorides",
        description: "Life Expectancy Chlorides",
        defaultMessage: "Chlorides - Service Life [year]"
    },
    calculatedRemainingLifeExpectancyChlorides: {
        id: "node.calculatedRemainingLifetimeExpectancyChlorides",
        description: "Remaining Life Expectancy Chlorides",
        defaultMessage: "Chlorides - Remaining Service Life [year]"
    },
    d: {
        id: "node.d",
        description: "Node Chlorides D",
        defaultMessage: "Chlorides - Diffusion Coefficient [mm²/s]"
    },
    cs: {
        id: "node.cs",
        description: "Node Chlorides Cs",
        defaultMessage: "Chlorides - Chloride Content at Surface [wt.-%/c]"
    },
    unitYear: {
        id: "node.unit.year",
        description: "The unit 'year'",
        defaultMessage: "[yr]"
    },
    unitMM: {
        id: "node.unit.mm",
        description: "The unit 'mm'",
        defaultMessage: "[mm]"
    },
    unitD: {
        id: "node.unit.d",
        description: "The unit for D",
        defaultMessage: "[mm²/s]"
    },
    unitCs: {
        id: "node.unit.cs",
        description: "The unit for Cs",
        defaultMessage: "[wt.‑%/c]"
    },
    nameShort: {
        id: "node.name.short",
        description: "Node Name - short string",
        defaultMessage: "Name"
    },
    nameFilter: {
        id: "node.name.filter",
        description: "Node Name Filter",
        defaultMessage: "Name Filter"
    },
    nameFilterAction: {
        id: "node.name.filter.confirm",
        description: "Node Name Filter Action",
        defaultMessage: "Filter"
    },
    nameFilterReset: {
        id: "node.name.filter.reset",
        description: "Node Name Filter Reset",
        defaultMessage: "Reset"
    },
    nodeError: {
        id: "node.error",
        description: "Node Error Title",
        defaultMessage: "Node Calculation Error"
    },
    nodeWarning: {
        id: "node.warning",
        description: "Node Warning Title",
        defaultMessage: "Node Calculation Warning"
    },
    overviewCalculationNeeded: {
        id: "overview.calculationNeeded",
        description: "Calculation needed before new data is shown",
        defaultMessage: "No overview or graph will be shown until the project has been calculated."
    },
    overviewSummaryLine: {
        id: "overview.summaryLine",
        description: "Summary Line - top line of the summary table",
        defaultMessage: "SUMMARY"
    },
    downloadChloridesSCurveCSV: {
        id: "overview.downloadChloridesSCurveCSV",
        description: "Button to download Chlorides S-Curve as CSV",
        defaultMessage: "Download Chlorides S-Curve as CSV"
    },
    downloadCarbonatationSCurveCSV: {
        id: "overview.downloadCarbonatationSCurveCSV",
        description: "Button to download Carbonation S-Curve as CSV",
        defaultMessage: "Download Carbonation S-Curve as CSV"
    },
    nodeReinforcement: {
        id: "node.reinforcement",
        description: "Node Reinforcement",
        defaultMessage: "Reinforcement Nodes"
    },
    nodeReinforcementHorizontal: {
        id: "node.reinforcement.horizontal",
        description: "Node Reinforcement Horizontal",
        defaultMessage: "Horizontal"
    },
    nodeReinforcementVertical: {
        id: "node.reinforcement.vertical",
        description: "Node Reinforcement Vertical",
        defaultMessage: "Vertical"
    },
    nodeReinforcementStirrups: {
        id: "node.reinforcement.stirrups",
        description: "Node Reinforcement Stirrups",
        defaultMessage: "Stirrups"
    },
    nodeReinforcementLongitudinal: {
        id: "node.reinforcement.longitudinal",
        description: "Node Reinforcement Longitudinal",
        defaultMessage: "Longitudinal"
    },
    nodeReinforcementTransverse: {
        id: "node.reinforcement.transverse",
        description: "Node Reinforcement Transverse",
        defaultMessage: "Transverse"
    }
});

export default messages;
