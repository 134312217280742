import { defineMessages } from "react-intl";

const messages = defineMessages({
    add: {
        id: "carbonatation.add",
        description: "Carbonation Add",
        defaultMessage: "Add Carbonation Measurements"
    },
    addManual: {
        id: "carbonatation.addManual",
        description: "Carbonation Add Manual",
        defaultMessage: "Manual Input"
    },
    addManualInput: {
        id: "carbonatation.addManualInput",
        description: "Carbonation Add Manual Input",
        defaultMessage: "Override and Input Manually"
    },
    nodeName: {
        id: "carbonatation.nodeName",
        description: "Node Name",
        defaultMessage: "Name"
    },
    values: {
        id: "carbonatation.values",
        description: "Carbonation Values",
        defaultMessage: "Carbonation - Measurement Values"
    },
    valuesShort: {
        id: "carbonatation.values.short",
        description: "Carbonation Values Short",
        defaultMessage: "Values"
    },
    count: {
        id: "carbonatation.count",
        description: "Carbonation Count",
        defaultMessage: "Carbonation - Number of Measurements"
    },
    countShort: {
        id: "carbonatation.count.short",
        description: "Carbonation Count Short",
        defaultMessage: "#Measurements"
    },
    minShort: {
        id: "carbonatation.min.short",
        description: "Carbonation Minimum",
        defaultMessage: "Min"
    },
    min: {
        id: "carbonatation.min",
        description: "Carbonation Minimum",
        defaultMessage: "Carbonation - Minimum [mm]"
    },
    maxShort: {
        id: "carbonatation.max.short",
        description: "Carbonation Maximum",
        defaultMessage: "Max"
    },
    max: {
        id: "carbonatation.max",
        description: "Carbonation Maximum",
        defaultMessage: "Carbonation - Maximum [mm]"
    },
    avgShort: {
        id: "carbonatation.avg.short",
        description: "Carbonation Average",
        defaultMessage: "μ"
    },
    avg: {
        id: "carbonatation.avg",
        description: "Carbonation Average",
        defaultMessage: "Carbonation - Mean Value [mm]"
    },
    avgRequired: {
        id: "carbonatation.avg.required",
        description: "Carbonation Average Required",
        defaultMessage: "Carbonation - Mean Value [mm] is required"
    },
    sdShort: {
        id: "carbonatation.sd.short",
        description: "Carbonation Standard Deviation",
        defaultMessage: "σ"
    },
    sd: {
        id: "carbonatation.sd",
        description: "Carbonation Standard Deviation",
        defaultMessage: "Carbonation - Standard Deviation [mm]"
    },
    sdRequired: {
        id: "carbonatation.sd.required",
        description: "Carbonation Standard Deviation Required",
        defaultMessage: "Carbonation - Standard Deviation [mm] is required"
    },
    unit: {
        id: "carbonatation.unit",
        description: "Carbonation unit",
        defaultMessage: "[mm]"
    },
    actions: {
        id: "carbonatation.actions",
        description: "Carbonation Actions",
        defaultMessage: "Actions"
    },
    edit: {
        id: "carbonatation.edit",
        description: "Carbonation Edit",
        defaultMessage: "Edit Carbonation Measurements"
    },
    editSucceeded: {
        id: "carbonatation.edit.succeeded",
        description: "Carbonation Edit Succeeded Notification",
        defaultMessage: "Carbonation Measurements are successfully changed"
    },
    deleteConfirm: {
        id: "carbonatation.delete.confirm",
        description: "Carbonation Delete Confirmation Message",
        defaultMessage: "Are you sure you want to delete all Carbonation values?"
    },
    deleteManualConfirm: {
        id: "carbonatation.deleteManual.confirm",
        description: "Manual Carbonatation Measurement Delete Confirmation",
        defaultMessage: "Are you sure you want to delete the manual carbonatation measurement?"
    }
});

export default messages;
