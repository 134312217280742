import { Dispatch } from "redux";
import { connect } from "react-redux";

import { IApplicationState } from "../../store";
import { loginFinished, loginError, logoutRequest } from "../../store/auth/actions";

import Callback from "./callback";

const mapStateToProps = ({ auth }: IApplicationState) => ({
    authenticating: auth.authenticating,
    authenticated: auth.authenticated,
    errors: auth.errors
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loginFinished: () => dispatch(loginFinished()),
    loginError: (reason: string) => dispatch(loginError(reason)),
    logoutRequest: () => dispatch(logoutRequest())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Callback);
