import { defineMessages } from "react-intl";

const messages = defineMessages({
    name: {
        id: "separation.name",
        description: "Separation Space Name Column",
        defaultMessage: "File"
    },
    count: {
        id: "separation.count",
        description: "Separation Space Count Column",
        defaultMessage: "Number of Spacings"
    },
    countShort: {
        id: "separation.count.short",
        description: "Separation Space Count Column Short",
        defaultMessage: "#Spacings"
    },
    min: {
        id: "separation.min",
        description: "Separation Space Minimum Column",
        defaultMessage: "Spacing - Minimum [mm]"
    },
    minShort: {
        id: "separation.min.short",
        description: "Separation Space Minimum Column Short",
        defaultMessage: "Min [mm]"
    },
    max: {
        id: "separation.max",
        description: "Separation Space Maximum Column",
        defaultMessage: "Spacing - Maximum [mm]"
    },
    maxShort: {
        id: "separation.max.short",
        description: "Separation Space Maximum Column Short",
        defaultMessage: "Max [mm]"
    },
    avg: {
        id: "separation.avg",
        description: "Separation Space Average Column",
        defaultMessage: "Spacing - Mean Value [mm]"
    },
    avgShort: {
        id: "separation.avg.short",
        description: "Separation Space Average Column Short",
        defaultMessage: "μ [mm]"
    },
    sd: {
        id: "separation.sd",
        description: "Separation Space Standard Deviation Column",
        defaultMessage: "Spacing - Standard Deviation [mm]"
    },
    sdShort: {
        id: "separation.sd.short",
        description: "Separation Space Standard Deviation Column",
        defaultMessage: "σ [mm]"
    },
    cov: {
        id: "separation.cov",
        description: "Coefficient of Variation",
        defaultMessage: "Spacing - Coefficient of Variation [-]"
    },
    covShort: {
        id: "separation.cov.short",
        description: "Coefficient of Variation",
        defaultMessage: "COV [-]"
    }
});

export default messages;
