import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { AuthActionTypes } from "./types";
import { loginSuccess, loginError, logoutSuccess, logoutError } from "./actions";
import authService from "../../services/API/auth-service";

/**
 * Handle login request
 * @param action
 */
function* handleLoginFinished() {
    try {
        yield put(loginSuccess());
    } catch (err) {
        if (err instanceof Error) {
            yield put(loginError(err.stack!));
        } else {
            yield put(loginError("An unknown error occured. " + err));
        }
    }
}

/**
 * Handle logout
 * @param action
 */
function* handleLogout() {
    try {
        authService.logout();
        yield put(logoutSuccess());
    } catch (err) {
        if (err instanceof Error) {
            yield put(logoutError(err.stack!));
        } else {
            yield put(logoutError("An unknown error occured." + err));
        }
    }
}

function* watchLoginFinished() {
    yield takeEvery(AuthActionTypes.LOGIN_FINISHED, handleLoginFinished);
}

function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_REQUEST, handleLogout);
}

function* authSaga() {
    yield all([fork(watchLoginFinished), fork(watchLogout)]);
}

export default authSaga;
