import { Dispatch } from "redux";
import { connect } from "react-redux";

import { IApplicationState } from "../../store";

import Login from "./login";

const mapStateToProps = ({ auth }: IApplicationState) => ({
    authenticating: auth.authenticating,
    authenticated: auth.authenticated,
    errors: auth.errors
});

export default connect(mapStateToProps)(Login);
