import { Alert, Icon, Popconfirm, Popover, Tag } from "antd";
import * as React from "react";
import { ConnectDropTarget, DropTarget } from "react-dnd";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { ChlorideStatus, INode, NodeStatus, NodeType } from "../../store/projects/types";
import "./chlorides-messages";
import "./projecttree-messages";
import Utils from "./utils";

interface IOwnProps {
    node: INode;
    onEditNodeClick: (node: INode) => void;
    onCreateNodeClick: (node: INode, nodeType?: NodeType) => void;
    onCloneNodeClick: (node: INode) => void;
    onDeleteNodeClick: (node: INode) => void;
    onDisableNodeClick: (node: INode) => void;
    onEnableNodeClick: (node: INode) => void;
    canDelete: boolean;
    canClone: boolean;
    canDisable: boolean;
    canEnable: boolean;
    connectDropTarget: ConnectDropTarget;
    isOver: boolean;
    canDropFiles: boolean;
}

type AllProps = IOwnProps & InjectedIntlProps;

interface IState {
    popoverVisible: boolean;
}

class NodeName extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            popoverVisible: false
        };
    }

    popoverVisibleChange = (visible: boolean) => {
        this.setState({
            ...this.state,
            popoverVisible: visible
        });
    };

    onCloneNodeClick() {
        this.setState(
            {
                ...this.state,
                popoverVisible: false
            },
            () => {
                this.props.onCloneNodeClick(this.props.node);
            }
        );
    }

    onCreateNodeClick(nodeType?: NodeType) {
        this.setState(
            {
                ...this.state,
                popoverVisible: false
            },
            () => {
                this.props.onCreateNodeClick(this.props.node, nodeType);
            }
        );
    }

    onEditNodeClick() {
        this.setState(
            {
                ...this.state,
                popoverVisible: false
            },
            () => {
                this.props.onEditNodeClick(this.props.node);
            }
        );
    }

    onDisableNodeClick() {
        this.setState(
            {
                ...this.state,
                popoverVisible: false
            },
            () => {
                this.props.onDisableNodeClick(this.props.node);
            }
        );
    }

    onEnableNodeClick() {
        this.setState(
            {
                ...this.state,
                popoverVisible: false
            },
            () => {
                this.props.onEnableNodeClick(this.props.node);
            }
        );
    }

    onDeleteNodeClick() {
        this.setState(
            {
                ...this.state,
                popoverVisible: false
            },
            () => {
                this.props.onDeleteNodeClick(this.props.node);
            }
        );
    }

    // This code is duplicated
    getManualChlorideWarning(node: INode) {
        if (node.useManualChloridesInput) {
            return this.props.intl.formatMessage({ id: "chlorides.warningManualPropagation" });
        }

        return undefined;
    }

    getNodeWarning(node: INode) {
        if (node.chlorideCalculationStatus) {
            if (
                node.chlorideCalculationStatus === ChlorideStatus.ACCEPTED_D_TIME_ANALYSIS ||
                node.chlorideCalculationStatus === ChlorideStatus.NO_CHLORIDE_CALCULATION_NO_DATA
            ) {
                // OK or no data => no warning
                return this.getManualChlorideWarning(node);
            } else {
                return this.props.intl.formatMessage({ id: "chlorides." + node.chlorideCalculationStatus });
            }
        }

        return this.getManualChlorideWarning(node);
    }

    render() {
        const {
            node,
            canDelete,
            canClone,
            canEnable,
            canDisable,
            connectDropTarget,
            isOver,
            canDropFiles
        } = this.props;
        const nodeWarning = this.getNodeWarning(node);

        let wrapperStyle = {};
        if (canDropFiles && isOver) {
            wrapperStyle = {
                ...wrapperStyle,
                border: "1px dashed #40a9ff",
                borderRadius: "2px",
                background: "rgb(250, 250, 250)"
            };
        }
        if (!canDropFiles && isOver) {
            wrapperStyle = { ...wrapperStyle, cursor: "not-allowed" };
        }
        if (node.status === NodeStatus.DISABLED) {
            wrapperStyle = { ...wrapperStyle, opacity: "0.4" };
        }

        const nodeError = Utils.getNodeError(node);

        return connectDropTarget(
            <span style={wrapperStyle} className="dblClick" onDoubleClick={this.onEditNodeClick.bind(this)}>
                <Popover
                    title={this.props.intl.formatMessage({
                        id: "tree.node.actions"
                    })}
                    trigger="contextMenu"
                    content={
                        <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
                            {!node.type && (
                                <div>
                                    <a style={{ color: "grey" }} onClick={() => this.onCreateNodeClick()}>
                                        {this.props.intl.formatMessage({ id: "tree.node.create" })}
                                    </a>
                                </div>
                            )}
                            {(!node.type || node.type === "ZONE") && (
                                <div>
                                    <a onClick={() => this.onCreateNodeClick("ZONE")}>
                                        {this.props.intl.formatMessage({ id: "tree.node.createZone" })}
                                    </a>
                                </div>
                            )}
                            {node.type === "ZONE" && (
                                <div>
                                    <a onClick={() => this.onCreateNodeClick("ELEMENT")}>
                                        {this.props.intl.formatMessage({ id: "tree.node.createElement" })}
                                    </a>
                                </div>
                            )}
                            {node.type === "ELEMENT" && (
                                <div>
                                    <a onClick={() => this.onCreateNodeClick("REINFORCEMENT")}>
                                        {this.props.intl.formatMessage({ id: "tree.node.createReinforcement" })}
                                    </a>
                                </div>
                            )}
                            {canClone && (
                                <div>
                                    <a onClick={this.onCloneNodeClick.bind(this)}>
                                        {this.props.intl.formatMessage({ id: "tree.node.clone" })}
                                    </a>
                                </div>
                            )}
                            <div>
                                <a onClick={this.onEditNodeClick.bind(this)}>
                                    {this.props.intl.formatMessage({ id: "tree.node.edit" })}
                                </a>
                            </div>
                            {canDelete && (
                                <div>
                                    <Popconfirm
                                        icon={<Icon style={{ color: "#a94442" }} type="warning" />}
                                        title={this.props.intl.formatMessage({ id: "tree.node.delete.confirm" })}
                                        onConfirm={this.onDeleteNodeClick.bind(this)}
                                        okText={this.props.intl.formatMessage({ id: "yes" })}
                                        cancelText={this.props.intl.formatMessage({ id: "no" })}
                                    >
                                        <a>{this.props.intl.formatMessage({ id: "tree.node.delete" })}</a>
                                    </Popconfirm>
                                </div>
                            )}
                            {canDisable && (
                                <div>
                                    <a onClick={this.onDisableNodeClick.bind(this)}>
                                        {this.props.intl.formatMessage({ id: "tree.node.disable" })}
                                    </a>
                                </div>
                            )}
                            {canEnable && (
                                <div>
                                    <a onClick={this.onEnableNodeClick.bind(this)}>
                                        {this.props.intl.formatMessage({ id: "tree.node.enable" })}
                                    </a>
                                </div>
                            )}
                        </div>
                    }
                    placement={"right"}
                    visible={this.state.popoverVisible}
                    onVisibleChange={this.popoverVisibleChange}
                >
                    {node.type && (
                        <Icon
                            type={
                                node.type === "ZONE"
                                    ? "bank"
                                    : node.type === "ELEMENT"
                                        ? "build"
                                        : node.type === "REINFORCEMENT"
                                            ? "number"
                                            : undefined
                            }
                        />
                    )}
                    <span style={{ padding: "6px", verticalAlign: "middle" }}>{node.name}</span>
                    {(node.carbonatation || node.carbonatationMeasurementsManualInput) && (
                        <Tag color="green">
                            {node.carbonatationMeasurementsManualInput ? (
                                <Icon type="user" style={{ marginRight: "2px" }} />
                            ) : (
                                undefined
                            )}
                            CO<sub>2</sub>
                        </Tag>
                    )}
                    {(node.chlorides || node.useManualChloridesInput) && (
                        <Tag color="purple">
                            {node.useManualChloridesInput ? (
                                <Icon type="user" style={{ marginRight: "2px" }} />
                            ) : (
                                undefined
                            )}
                            {"Cl"}
                        </Tag>
                    )}
                    {nodeWarning && (
                        <Popover
                            content={
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "warning"
                                    })}
                                    description={nodeWarning}
                                    type="warning"
                                    showIcon
                                />
                            }
                            overlayClassName="nodeWarning"
                        >
                            <Tag color="gold">!</Tag>
                        </Popover>
                    )}
                    {node.useManualInput &&
                        node.covermeasurementsManualInput && (
                            <Popover
                                content={
                                    <Alert
                                        message={this.props.intl.formatMessage({ id: "info" })}
                                        description={this.props.intl.formatMessage({
                                            id: "tree.node.manualInfo"
                                        })}
                                        type="info"
                                        showIcon
                                    />
                                }
                                overlayClassName="nodeWarning"
                            >
                                <Tag color="blue">
                                    <Icon type="user" />
                                </Tag>
                            </Popover>
                        )}
                    {node.files.length > 0 && (
                        <Tag color="cyan">
                            <Icon type="file" />
                        </Tag>
                    )}
                    {nodeError &&
                        nodeError.type === "error" && (
                            <Tag color="red">
                                <Icon type="exclamation-circle" />
                            </Tag>
                        )}
                    {nodeError &&
                        nodeError.type === "warning" && (
                            <Tag color="gold">
                                <Icon type="exclamation-circle" />
                            </Tag>
                        )}
                </Popover>
            </span>
        );
    }
}

export default DropTarget(
    "covermeasurements",
    {
        drop(props: AllProps, monitor, component) {
            return {
                nodeId: props.node.uuid
            };
        },
        canDrop(props, monitor) {
            return props.canDropFiles;
        }
    },
    (connect, monitor) => {
        return {
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver()
        };
    }
)(injectIntl(NodeName));
