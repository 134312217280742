// Interface for creating a new project
export interface ICreateProject {
    organization: string;
    name: string;
    description: string;
    customer: string;
    location: string;
    created_by: string;
    updated_by: string;
    lifecycle: ProjectLifecycle;
    age: number;
    maxCalculationAge?: number;
    coverdepthThreshold: number;
    coverdepthQualityThreshold?: number;
    coverdepthOverlay?: number;
    chloridesThreshold: number;
    chloridesBetaStd: number;
    chloridesAgingFactor: number;
    failureProbabilityChlorides?: number;
    failureProbability: number;
}

// Same as ICreateProject but with some properties filled in server side
export interface IProject extends ICreateProject {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
    zipFile?: string;
    status: ProjectStatus;
    errorMessage?: string;
    calculationSteps?: number;
    calculationProgress?: number;
}

export enum ProjectStatus {
    FileUploaded = "FILE_UPLOADED",
    FileProcessing = "FILE_PROCESSING",
    Dirty = "DIRTY",
    Calculating = "CALCULATING",
    Done = "DONE",
    Failed = "FAILED"
}

export enum ProjectLifecycle {
    Draft = "DRAFT",
    Published = "PUBLISHED",
    Deleted = "DELETED"
}

export enum ChlorideStatus {
    NO_CHLORIDE_CALCULATION_NO_DATA = "NO_CHLORIDE_CALCULATION_NO_DATA",
    MAX_TOO_LOW = "MAX_TOO_LOW",
    ACCEPTED_D_TIME_ANALYSIS = "ACCEPTED_D_TIME_ANALYSIS",
    D_NOT_ACCEPTED_NO_TIME_ANALYSIS_LIN_INTERPOLATION = "D_NOT_ACCEPTED_NO_TIME_ANALYSIS_LIN_INTERPOLATION"
}

export enum NodeStatus {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
}

export type NodeType = "ZONE" | "ELEMENT" | "REINFORCEMENT";

export type CalculationResult = "FAILURE" | "NO_FAILURE";

export interface INode {
    // check cloneNode if this is extended
    name: string;
    uuid: string;

    type?: NodeType;

    children: INode[];
    files: string[];
    covermeasurementsManualInput?: string;
    useManualInput: boolean;

    failureProbability?: number;
    failureProbabilityChlorides?: number;
    threshold?: number;
    coverdepthQualityThreshold?: number;
    coverdepthOverlay?: number;
    chloridesAgingFactor?: number;

    propagatedThreshold?: IPropagatedValue;
    propagatedCoverdepthQualityThreshold?: IPropagatedValue;
    propagatedCoverdepthOverlay?: IPropagatedValue;
    propagatedFailureProbability?: IPropagatedValue;
    propagatedFailureProbabilityChlorides?: IPropagatedValue;
    propagatedAge?: IPropagatedValue;
    propagatedChloridesAgingFactor?: IPropagatedValue;

    calculatedChlorideFailureProbability?: number;
    calculatedCarbonatationFailureProbability?: number;
    calculatedChlorideLifetimeExpectancy?: number;
    calculatedLifetimeExpectancy?: number;

    carbonationCalculationResultMessage?: CalculationResult;
    chlorideCalculationResultMessage?: CalculationResult;

    nodeGraphURL?: string;
    chlorideGraphURL?: string;
    chlorideSCurveCSVURL?: string;
    chlorideSCurveGraphURL?: string;
    chlorideCalculationStatus?: ChlorideStatus;

    chlorides?: string;
    Cs?: number;
    D?: number;
    Ci?: number;
    useManualChloridesInput?: boolean;

    carbonatation?: string;
    carbonatationMeasurementsManualInput?: string;

    carbonationSCurveCSVURL?: string;
    carbonationSCurveGraphURL?: string;

    nodeStatistics?: IMeasurement;
    carbonatationStatistics?: ICarbonatationMeasurement;

    nodeErrorMsg?: string;

    status?: NodeStatus;
}

export interface IProjectTree {
    _id?: string | null;
    projectId: string;
    tree?: INode | null;
    archiveURL?: string;
}

export interface ICreateMeasurement {
    measurements: number[];
}

export interface IMeasurement extends ICreateMeasurement, IStatistics {
    _id: string;
}

interface IStatistics {
    count: number;
    min: number;
    max: number;
    average: number;
    standardDeviation: number;
}

export interface ICreateCoverMeasurementManualInput {
    average: number;
    standardDeviation: number;
}
export interface ICoverMeasurementManualInput extends ICreateCoverMeasurementManualInput {
    _id: string;
}

export interface ICoverMeasurement extends IMeasurement {
    fileName: string;
    filePath: string;
    positions: number[];
    deviceType?: string;
    threshold?: number;
    coverdepthQualityThreshold?: number;
    coverdepthOverlay?: number;
    thresholdStatistics: IStatistics;
    minSeparation: number;
    maxSeparation: number;
    averageSeparation: number;
    standardDeviationSeparation: number;
    propagatedThreshold: IPropagatedValue;
    propagatedCoverdepthQualityThreshold: IPropagatedValue;
    propagatedCoverdepthOverlay: IPropagatedValue;
    status: CoverMeasurementLifecycle;
    coverPlotLarge?: string;
    coverPlotSmall?: string;
    errorMessage?: string;
    nodeStatus?: NodeStatus;
}

export enum CoverMeasurementLifecycle {
    Draft = "DRAFT",
    Published = "PUBLISHED",
    Error = "ERROR"
}

export type ICarbonatationMeasurement = IMeasurement;

export interface ICreateCarbonatationMeasurementManualInput {
    average: number;
    standardDeviation: number;
}
export interface ICarbonatationMeasurementManualInput extends ICreateCarbonatationMeasurementManualInput {
    _id: string;
}

export enum ChloridesStatus {
    GraphProcessing = "GRAPH_PROCESSING",
    Dirty = "DIRTY",
    Done = "DONE",
    Failed = "FAILED"
}
export interface ICreateChlorideMeasurement extends ICreateMeasurement {
    positions: number[];
    active: number[];
    graphURL?: string;
    status: ChloridesStatus;
}
export interface IChlorideMeasurement extends IMeasurement, ICreateChlorideMeasurement {}

export interface IPropagatedValue {
    sourceLevel: PropagationLevel;
    sourceName: string;
    value: number;
}

export enum PropagationLevel {
    FILE = "FILE",
    NODE = "NODE",
    PROJECT = "PROJECT"
}

export interface ICreateOrganization {
    name: string;
}

export interface IOrganization extends ICreateOrganization {
    _id: string;
    settings: IOrganizationSettings;
}

export interface IOrganizationSettings {
    projectNameInitialValue: string;
    projectNamePattern: string;
    projectNamePatternDescription: string;
    projectNamePatternExample: string;
}
