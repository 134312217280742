import { defineMessages } from "react-intl";

const messages = defineMessages({
    info: {
        id: "project.info",
        description: "Project Info",
        defaultMessage: "Project Info"
    },
    project: {
        id: "project",
        description: "Project",
        defaultMessage: "Project"
    },
    name: {
        id: "project.name",
        description: "Project Name",
        defaultMessage: "Project Name"
    },
    nameRequired: {
        id: "project.name.required",
        description: "Project Name Required",
        defaultMessage: "Please enter a Project Name"
    },
    namePattern: {
        id: "project.name.pattern",
        description: "Project Name Pattern",
        defaultMessage: 'Name should be in the format "{format}". For example: "{example}"'
    },
    location: {
        id: "project.location",
        description: "Project Location",
        defaultMessage: "Location"
    },
    projectError: {
        id: "project.error",
        description: "Project Error (e.g. calculation / file processing)",
        defaultMessage: "Project error"
    },
    locationRequired: {
        id: "project.location.required",
        description: "Project Location Required",
        defaultMessage: "Please enter a Location"
    },
    customer: {
        id: "project.customer",
        description: "Project Customer",
        defaultMessage: "Customer"
    },
    customerRequired: {
        id: "project.customer.required",
        description: "Project Customer Required",
        defaultMessage: "Please enter a Customer"
    },
    created: {
        id: "project.createdAt",
        description: "Project Created",
        defaultMessage: "Created"
    },
    createdBy: {
        id: "project.created_by",
        description: "Project Created By",
        defaultMessage: "Created By"
    },
    updated: {
        id: "project.updatedAt",
        description: "Project Updated",
        defaultMessage: "Last Updated"
    },
    by: {
        id: "project.by",
        description: "Project Updated/Created By",
        defaultMessage: "by"
    },
    updatedBy: {
        id: "project.updated_by",
        description: "Project Updated By",
        defaultMessage: "Last Updated By"
    },
    create: {
        id: "project.create",
        description: "Create Project Button",
        defaultMessage: "Create New Project"
    },
    delete: {
        id: "project.delete",
        description: "Delete Project Button",
        defaultMessage: "Delete Project"
    },
    deleteConfirm: {
        id: "project.delete.confirm",
        description: "Delete Project Confirm",
        defaultMessage: "Are you sure you want to delete this Project?"
    },
    recover: {
        id: "project.recover",
        description: "Recover Project",
        defaultMessage: "Recover Project"
    },
    recoverConfirm: {
        id: "project.recover.confirm",
        description: "Recover Project Confirm",
        defaultMessage: "Are you sure you want to recover this Project by resetting its status?"
    },
    recoverTooltip: {
        id: "project.recover.tooltip",
        description: "Recover Project Tooltip",
        defaultMessage: "Use this button to reset the Project status after it got stuck e.g. during calculation"
    },
    more: {
        id: "project.more",
        description: "More Project Actions Dropdown Button ",
        defaultMessage: "More Actions"
    },
    description: {
        id: "project.description",
        description: "Project Description",
        defaultMessage: "Description"
    },
    descriptionRequired: {
        id: "project.description.required",
        description: "Project Description Required",
        defaultMessage: "Please enter a Description"
    },
    age: {
        id: "project.age",
        description: "Project Age",
        defaultMessage: "Project Age"
    },
    ageInYears: {
        id: "project.ageInYears",
        description: "Project Age in Years",
        defaultMessage: "Years"
    },
    ageInDays: {
        id: "project.ageInDays",
        description: "Project Age in Days",
        defaultMessage: "Days"
    },
    ageRequired: {
        id: "project.age.required",
        description: "Project Age Required",
        defaultMessage: "Please enter a Project Age"
    },
    agePattern: {
        id: "project.age.pattern",
        description: "Project Age Pattern",
        defaultMessage: "Please enter a Project Age > 0"
    },
    ageSwitchTooltipYears: {
        id: "project.age.switchYearsTooltip",
        description: "Project Age Switch Years Tooltip",
        defaultMessage: "Toggle to enter age in days"
    },
    ageSwitchTooltipDays: {
        id: "project.age.switchDaysTooltip",
        description: "Project Age Switch Days Tooltip",
        defaultMessage: "Toggle to enter age in years"
    },
    maxCalculationAge: {
        id: "project.maxCalculationAge",
        description: "Maximum Calculation Age",
        defaultMessage: "Maximum Calculation Age"
    },
    maxCalculationRequired: {
        id: "project.maxCalculationAge.required",
        description: "Maximum Calculation Age Required",
        defaultMessage: "Please enter a Maximum Calculation Age"
    },
    maxCalculationPattern: {
        id: "project.maxCalculationAge.pattern",
        description: "Maximum Calculation Age Pattern",
        defaultMessage: "Please enter a Maximum Calculation Age > 0"
    },
    concreteCover: {
        id: "project.concreteCover",
        description: "Project Concrete Cover Title",
        defaultMessage: "Concrete Cover"
    },
    coverdepthThreshold: {
        id: "project.coverdepthThreshold",
        description: "Project Coverdepth Threshold",
        defaultMessage: "Maximum Concrete Cover Threshold"
    },
    coverdepthThresholdRequired: {
        id: "project.coverdepthThreshold.required",
        description: "Project Coverdepth Threshold Required",
        defaultMessage: "Please enter a Maximum Concrete Cover Threshold"
    },
    coverdepthThresholdExplanation: {
        id: "project.coverdepthThreshold.explanation",
        description: "Project Coverdepth Threshold Explanation",
        defaultMessage:
            "All the concrete cover measurements higher than or equal to this threshold value will be eliminated for statistical analysis."
    },
    coverdepthQualityThreshold: {
        id: "project.coverdepthQualityThreshold",
        description: "Project Coverdepth Quality Threshold",
        defaultMessage: "Minimum Quality Threshold"
    },
    coverdepthQualityThresholdRequired: {
        id: "project.coverdepthQualityThreshold.required",
        description: "Project Coverdepth Quality Threshold Required",
        defaultMessage: "Please enter a Minimum Quality Threshold"
    },
    coverdepthQualityThresholdExplanation: {
        id: "project.coverdepthQualityThreshold.explanation",
        description: "Project Coverdepth Quality Threshold Explanation",
        defaultMessage:
            "All the concrete cover measurements with a quality lower than this threshold value " +
            "will be eliminated for statistical analysis. " +
            "The quality is a parameter that can be found in the CSV files of the Hilti PS300. " +
            "For scans made with the Hilti PS200 and the Proceq Profometer, the quality is set to 100% by default."
    },
    coverdepthOverlay: {
        id: "project.coverdepthOverlay",
        description: "Project Coverdepth Overlay",
        defaultMessage: "Overlay"
    },
    coverdepthOverlayRequired: {
        id: "project.coverdepthOverlay.required",
        description: "Project Coverdepth Overlay Required",
        defaultMessage: "Please enter an Overlay"
    },
    coverdepthOverlayExplanation: {
        id: "project.coverdepthOverlay.explanation",
        description: "Project Coverdepth Overlay Explanation",
        defaultMessage:
            "The thickness of the overlay in the case an overlay was used or was present on the concrete surface " +
            "(for example in the case of rough surfaces, a layer of screed, ...). " +
            "The entered overlay thickness is then automatically subtracted from the measured concrete cover " +
            "to show the actual concrete cover."
    },
    carbonation: {
        id: "project.carbonation",
        description: "Project Carbonation Title",
        defaultMessage: "Carbonation"
    },
    failureProbability: {
        id: "project.failureProbability",
        description: "Project Carbonation Failure Probablity",
        defaultMessage: "Critical Probability of Failure"
    },
    failureProbabilityRequired: {
        id: "project.failureProbability.required",
        description: "Project Carbonation Failure Probablity Required",
        defaultMessage: "Please enter a Critical Probability of Failure"
    },
    chlorides: {
        id: "project.chlorides",
        description: "Project Chlorides Title",
        defaultMessage: "Chlorides"
    },
    failureProbabilityChlorides: {
        id: "project.failureProbabilityChlorides",
        description: "Project Failure Probablity Chlorides",
        defaultMessage: "Critical Probability of Failure"
    },
    failureProbabilityChloridesRequired: {
        id: "project.failureProbabilityChlorides.required",
        description: "Project Failure Probablity Chlorides Required",
        defaultMessage: "Please enter a Critical Probability of Failure"
    },
    chloridesThreshold: {
        id: "project.chloridesThreshold",
        description: "Project Chlorides Threshold",
        defaultMessage: "Critical Chloride Content"
    },
    chloridesThresholdRequired: {
        id: "project.chloridesThreshold.required",
        description: "Project Chlorides Threshold Required",
        defaultMessage: "Please enter a Critical Chloride Content"
    },
    calculationMethod: {
        id: "project.calculationMethod",
        description: "Project Chlorides Calculation Method",
        defaultMessage: "Calculation Approach"
    },
    stochastic: {
        id: "project.stochastic",
        description: "Project Chlorides Calculation Method Stochastic",
        defaultMessage: "Stochastic"
    },
    deterministic: {
        id: "project.deterministic",
        description: "Project Chlorides Calculation Method Deterministic",
        defaultMessage: "Deterministic"
    },
    chloridesBeta: {
        id: "project.chloridesBeta",
        description: "Project Chlorides Beta Distribution Avg",
        defaultMessage: "Critical Chloride Content - Mean Value (Beta Distribution)"
    },
    chloridesBetaRequired: {
        id: "project.chloridesBeta.required",
        description: "Project Chlorides Threshold Required",
        defaultMessage: "Please enter a Critical Chloride Content - Mean Value (Beta Distribution)"
    },
    chloridesBetaStd: {
        id: "project.chloridesBetaStd",
        description: "Project Chlorides Threshold Beta Distribution Standard Deviation",
        defaultMessage: "Critical Chloride Content - Standard Deviation (Beta Distribution)"
    },
    chloridesBetaStdRequired: {
        id: "project.chloridesBetaStd.required",
        description: "Project Chlorides Threshold Beta Distribution Standard Deviation Required",
        defaultMessage: "Please enter a Critical Chloride Content - Standard Deviation (Beta Distribution)"
    },
    chloridesAgingFactor: {
        id: "project.chloridesAgingFactor",
        description: "Project Chlorides Aging Factor",
        defaultMessage: "Aging Exponent α"
    },
    chloridesAgingFactorRequired: {
        id: "project.chloridesAgingFactor.required",
        description: "Project Chlorides Aging Factor Required",
        defaultMessage: "Please enter a Chlorides Aging Factor"
    },
    file: {
        id: "project.file",
        description: "Project File",
        defaultMessage: "Project File"
    },
    fileTip: {
        id: "project.file.tip",
        description: "Project File Tip",
        defaultMessage: "Click or drag file to this area to upload"
    },
    detailsHeader: {
        id: "project.details.header",
        description: "Project Details Header Title",
        defaultMessage: "Depassivation Due To Carbonation"
    },
    edit: {
        id: "project.details.edit",
        description: "Project Details Edit Button",
        defaultMessage: "Edit Parameters"
    },
    editSucceeded: {
        id: "project.details.edit.succeeded",
        description: "Project Details Edit Succeeded Notification",
        defaultMessage: "Parameters are successfully changed"
    },
    editTree: {
        id: "project.details.edit.tree",
        description: "Edit Tree Notification Title",
        defaultMessage: "Edit Tree"
    },
    editTreeSucceeded: {
        id: "project.details.edit.tree.succeeded",
        description: "Edit Tree Succeeded Notification",
        defaultMessage: "Tree is successfully updated"
    },
    editTreeFailed: {
        id: "project.details.edit.tree.failed",
        description: "Edit Tree Failed Notification",
        defaultMessage: "Tree could not be updated"
    },
    recalculateFast: {
        id: "project.details.recalculateFast",
        description: "Project Details Recalculate Fast Button",
        defaultMessage: "Recalculate"
    },
    general: {
        id: "project.details.general",
        description: "Project Details General Title",
        defaultMessage: "General"
    },
    depassivationProb: {
        id: "project.details.depassivation.prob",
        description: "Project Details Depassivation Probablity Title",
        defaultMessage: "Current Depassivation Probablity"
    },
    depassivationTime: {
        id: "project.details.depassivation.time",
        description: "Project Details Depassivation Time Title",
        defaultMessage: "Time To Depassivation"
    },
    average: {
        id: "project.details.avg",
        description: "Project Details Average",
        defaultMessage: "avg [mm]"
    },
    sd: {
        id: "project.details.sd",
        description: "Project Details Standard Deviation",
        defaultMessage: "sd"
    },
    calculationOngoing: {
        id: "project.calculate.ongoing",
        description: "Calculation Button disabled - Calculation ongoing",
        defaultMessage: "Calculation is already ongoing"
    },
    calculationUnnecessary: {
        id: "project.calculate.unnecessary",
        description: "Calculation Button enabled - Calculation unnecessary because it's still valid",
        defaultMessage:
            "Calculation not necessary - project didn't change since last calculation. Click to force a recalculation anyhow."
    },
    calculationFast: {
        id: "project.calculateFast",
        description: "Calculation Fast Button enabled - trigger calculation",
        defaultMessage: "Trigger the recalculation of the whole project"
    },
    calculationFinishedTitle: {
        id: "project.calculation.finished.title",
        description: "popup message title - calculation is finished",
        defaultMessage: "Calculation finished"
    },
    calculationFinishedDescription: {
        id: "project.calculation.finished.description",
        description: "popup message description (details) - calculation is failed",
        defaultMessage: "The calculation has succesfully finished. The project has been reloaded."
    },
    calculationFailedTitle: {
        id: "project.calculation.failed.title",
        description: "popup message title - calculation is finished",
        defaultMessage: "Calculation failed"
    },
    calculationFailedDescription: {
        id: "project.calculation.failed.description",
        description: "popup message description (details) - calculation is failed",
        defaultMessage: "The calculation has failed. Please contact the developers."
    },
    calculationTriggeredTitle: {
        id: "project.calculation.triggered",
        description: "popup message title - calculation triggered (started)",
        defaultMessage: "Calculation started"
    },
    calculationOngoingButton: {
        id: "project.details.calculationOngoing",
        description: "Button title when calculation is ongoing",
        defaultMessage: "Calculating"
    },
    downloadZip: {
        id: "project.details.downloadZip",
        description: "Project Details Download Zip Button",
        defaultMessage: "Download All Overview Graphs as Zip"
    },
    measurementListWarning: {
        id: "project.measurements.error",
        description: "Warning on top indicating list of problems with files",
        defaultMessage:
            "There is an issue with one or more measurements. " +
            "Please check in the list of measurements for details before calculating the project. List of files:"
    },
    visibleColumns: {
        id: "project.overview.visibleColumns",
        description: "Button title for selecting available columns in overview mode",
        defaultMessage: "Select Visible Columns"
    }
});

export default messages;
