import {
    ICarbonatationMeasurement,
    ICarbonatationMeasurementManualInput,
    ICreateCarbonatationMeasurementManualInput,
    ICreateMeasurement
} from "../../store/projects/types";
import api from "./api";

class CarbonatationService {
    async getCarbonatationMeasurement(carbonatationId: string): Promise<ICarbonatationMeasurement> {
        const response = await api.get("/carbonatationmeasurement/" + carbonatationId);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while getting carbonation measurements with id " + carbonatationId);
    }

    async getCarbonatationMeasurements(projectId: string, nodeId: string): Promise<ICarbonatationMeasurement[]> {
        const response = await api.get("/carbonatationmeasurements?project=" + projectId + "&nodeId=" + nodeId);

        if (response && response.data) {
            if (response.data.response && response.data.response.items) {
                return response.data.response.items;
            } else {
                return [];
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while getting carbonations for project " + projectId + " and node id " + nodeId);
    }

    async getCarbonatationMeasurementsManualInput(
        manualInputID: string
    ): Promise<ICarbonatationMeasurementManualInput> {
        const response = await api.get("/carbonatationmeasurements_manual_input/" + manualInputID);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while getting manual input carbonatation measurements for id " + manualInputID);
    }

    async createCarbonatationMeasurementsManualInput(
        carbonatationMeasurementManualInput: ICreateCarbonatationMeasurementManualInput
    ): Promise<ICarbonatationMeasurementManualInput> {
        const response = await api.post("/carbonatationmeasurements_manual_input", carbonatationMeasurementManualInput);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating manual input carbonatation measurements");
    }

    async deleteCarbonatationMeasurementsManualInput(
        manualInputID: string
    ): Promise<ICarbonatationMeasurementManualInput> {
        const response = await api.delete("/carbonatationmeasurements_manual_input/" + manualInputID);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while deleting manual input carbonatation measurements for id " + manualInputID);
    }

    async updateCarbonatationMeasurementsManualInput(
        carbonatationMeasurementManualInput: ICarbonatationMeasurementManualInput
    ): Promise<ICarbonatationMeasurementManualInput> {
        const response = await api.put(
            "/carbonatationmeasurements_manual_input/" + carbonatationMeasurementManualInput._id,
            carbonatationMeasurementManualInput
        );

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error(
            "Error while updating manual input carbonatation measurements for id " +
                carbonatationMeasurementManualInput._id
        );
    }

    async createCarbonatationMeasurement(measurement: ICreateMeasurement): Promise<ICarbonatationMeasurement> {
        const response = await api.post("/carbonatationmeasurements", measurement);

        if (response && response.data) {
            if (response.data.response && response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating carbonation measurement");
    }

    async updateCarbonatationMeasurement(measurement: ICarbonatationMeasurement): Promise<ICarbonatationMeasurement> {
        const response = await api.put("/carbonatationmeasurement/" + measurement._id, measurement);

        if (response && response.data) {
            if (response.data.response && response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating Carbonation measurement");
    }

    async deleteCarbonatationMeasurement(measurementId: string): Promise<void> {
        const response = await api.delete("/carbonatationmeasurement/" + measurementId);

        if (response && response.data) {
            if (response.data.response && response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while deleting carbonation measurement");
    }
}

export default new CarbonatationService();
