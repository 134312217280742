import * as React from "react";
import * as ReactDOM from "react-dom";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./separationspace-messages";
import Utils from "./utils";

import covermeasurementService from "../../services/API/covermeasurement-service";
import { ICoverMeasurement, INode } from "../../store/projects/types";

import { Alert, Button, Table, Tooltip, notification } from "antd";

interface IState {
    error?: string;
    loading: boolean;
    measurements: ICoverMeasurement[];
}

interface IOwnProps {
    projectId: string;
    currentNode: INode;
}

type AllProps = IOwnProps & InjectedIntlProps;

interface IRowEntry {
    key: string;
    name: string;
    count: number;
    min: number;
    max: number;
    average: number;
    standardDeviation: number;
    cov: number;
}

class SeparationSpace extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            error: undefined,
            loading: false,
            measurements: []
        };
    }

    getBackgroundColor(value: string | number) {
        if (typeof value === "string") {
            return "";
        } else if (Number(value) > 50) {
            return "rgb(255,0,0)"; // red
        } else if (Number(value) > 30) {
            return "rgb(255,192,0)"; // orange
        } else if (Number(value) > 10) {
            return "rgb(255,255,0)"; // yellow
        } else {
            return "rgb(146,208,80)"; // green
        }
    }

    fetchMeasurements() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                covermeasurementService
                    .getCovermeasurements(this.props.projectId, this.props.currentNode.uuid)
                    .then(measurements => {
                        this.setState({
                            ...this.state,
                            measurements,
                            loading: false,
                            error: undefined
                        });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            error: err.message,
                            measurements: [],
                            loading: false
                        });
                    });
            }
        );
    }

    componentDidMount() {
        this.fetchMeasurements();
    }

    componentDidUpdate(prevProps: AllProps) {
        if (prevProps.currentNode !== this.props.currentNode || prevProps.projectId !== this.props.projectId) {
            this.fetchMeasurements();
        }
    }

    getData() {
        const { measurements } = this.state;

        return measurements.map(measurement => {
            return {
                key: measurement._id,
                name: measurement.fileName,
                count: measurement.positions.length - 1,
                min: Utils.formatNumber(measurement.minSeparation, 3),
                max: Utils.formatNumber(measurement.maxSeparation, 3),
                average: Utils.formatNumber(measurement.averageSeparation, 3),
                standardDeviation: Utils.formatNumber(measurement.standardDeviationSeparation, 3),
                cov: measurement.standardDeviationSeparation / measurement.averageSeparation
            } as IRowEntry;
        });
    }

    getColumns() {
        return [
            {
                title: this.props.intl.formatMessage({
                    id: "separation.name"
                }),
                dataIndex: "name",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.name.localeCompare(b.name);
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "separation.count" })}>
                        {this.props.intl.formatMessage({ id: "separation.count.short" })}
                    </Tooltip>
                ),
                dataIndex: "count",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.count - b.count;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "separation.min" })}>
                        {this.props.intl.formatMessage({ id: "separation.min.short" })}
                    </Tooltip>
                ),
                dataIndex: "min",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.min - b.min;
                },
                render: (value: string | number) => {
                    return Utils.formatNumberUnit(value, undefined, 1000, 3);
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "separation.max" })}>
                        {this.props.intl.formatMessage({ id: "separation.max.short" })}
                    </Tooltip>
                ),
                dataIndex: "max",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.max - b.max;
                },
                render: (value: string | number) => {
                    return Utils.formatNumberUnit(value, undefined, 1000, 3);
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "separation.avg" })}>
                        {this.props.intl.formatMessage({ id: "separation.avg.short" })}
                    </Tooltip>
                ),
                dataIndex: "average",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.average - b.average;
                },
                className: "average-highlight",
                render: (value: string | number) => {
                    return <b>{Utils.formatNumberUnit(value, undefined, 1000, 3)}</b>;
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "separation.sd" })}>
                        {this.props.intl.formatMessage({ id: "separation.sd.short" })}
                    </Tooltip>
                ),
                dataIndex: "standardDeviation",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.standardDeviation - b.standardDeviation;
                },
                render: (value: string | number) => {
                    return Utils.formatNumberUnit(value, undefined, 1000, 3);
                }
            },
            {
                title: (
                    <Tooltip title={this.props.intl.formatMessage({ id: "separation.cov" })}>
                        {this.props.intl.formatMessage({ id: "separation.cov.short" })}
                    </Tooltip>
                ),
                dataIndex: "cov",
                sorter: (a: IRowEntry, b: IRowEntry) => {
                    return a.cov - b.cov;
                },
                render: (value: string | number) => {
                    return {
                        props: {
                            style: {
                                backgroundColor: this.getBackgroundColor((value as number) * 100),
                                color: (value as number) * 100 > 50 ? "#fff" : undefined
                            }
                        },
                        children: (
                            <span>
                                <b>{Utils.formatNumberUnit(value, "%", 100, 0)}</b>
                            </span>
                        )
                    };
                }
            }
        ];
    }

    render() {
        const { error, loading } = this.state;

        return (
            <div>
                {error && (
                    <Alert
                        style={{ marginBottom: "10px" }}
                        message={this.props.intl.formatMessage({ id: "error" })}
                        description={error}
                        type="error"
                        showIcon
                    />
                )}
                <div style={{ marginBottom: "16px" }}>
                    <Button icon="export" onClick={this.handleExport.bind(this)}>
                        {this.props.intl.formatMessage({ id: "export" })}
                    </Button>
                </div>
                <Table
                    loading={loading}
                    dataSource={this.getData()}
                    columns={this.getColumns()}
                    size="small"
                    style={{ width: "100%" }}
                    pagination={false}
                />
            </div>
        );
    }

    handleExport() {
        const div = document.createElement("div");
        ReactDOM.render(
            <Table
                dataSource={this.getData()}
                columns={this.getColumns()}
                size="small"
                style={{ width: "100%" }}
                pagination={false}
            />,
            div
        );
        Utils.copyToClip(div.innerHTML);
        notification["success"]({
            message: this.props.intl.formatMessage({
                id: "export"
            }),
            description: this.props.intl.formatMessage({
                id: "export.succeeded"
            })
        });
    }
}

export default injectIntl(SeparationSpace);
