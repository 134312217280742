import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./node-messages";

import { Alert, Checkbox, Form, Input, Modal, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { INode, NodeType } from "../../store/projects/types";

interface IOwnProps {
    parentNode: INode;
    nodeType?: NodeType;
    visible: boolean;
    loading: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    error?: string;
}

type AllProps = IOwnProps & FormComponentProps & InjectedIntlProps;

class CreateNode extends React.PureComponent<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    render() {
        const {
            form: { getFieldDecorator },
            error,
            parentNode,
            nodeType
        } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };

        return (
            <Modal
                title={this.props.intl.formatMessage({ id: "node.create.title" }, { name: parentNode.name })}
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                okButtonProps={{ disabled: this.props.loading }}
                cancelButtonProps={{ disabled: this.props.loading }}
            >
                <Spin spinning={this.props.loading}>
                    <Form layout="horizontal">
                        {nodeType && (
                            <Form.Item
                                {...formItemLayout}
                                label={this.props.intl.formatMessage({
                                    id: "node.type"
                                })}
                            >
                                <span className="ant-form-text">
                                    {this.props.intl.formatMessage({ id: "node.type." + nodeType })}
                                </span>
                            </Form.Item>
                        )}
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "node.name"
                            })}
                        >
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "node.name.required"
                                        })
                                    }
                                ]
                            })(<Input autoFocus onKeyPress={this.onKeyPress.bind(this)} />)}
                        </Form.Item>
                        {nodeType &&
                            nodeType === "ELEMENT" && (
                                <Form.Item
                                    {...formItemLayout}
                                    label={this.props.intl.formatMessage({
                                        id: "node.reinforcement"
                                    })}
                                >
                                    {getFieldDecorator("reinforcement", { initialValue: [] })(
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value="horizontal">
                                                    {this.props.intl.formatMessage({
                                                        id: "node.reinforcement.horizontal"
                                                    })}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="vertical">
                                                    {this.props.intl.formatMessage({
                                                        id: "node.reinforcement.vertical"
                                                    })}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="stirrups">
                                                    {this.props.intl.formatMessage({
                                                        id: "node.reinforcement.stirrups"
                                                    })}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="longitudinal">
                                                    {this.props.intl.formatMessage({
                                                        id: "node.reinforcement.longitudinal"
                                                    })}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="transverse">
                                                    {this.props.intl.formatMessage({
                                                        id: "node.reinforcement.transverse"
                                                    })}
                                                </Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            )}
                        <Form.Item {...tailFormItemLayout}>
                            {error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={error}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }

    onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            this.props.handleOk();
        }
    }
}

const createNodeWrapped = injectIntl(Form.create<AllProps>()(CreateNode));

export { createNodeWrapped as CreateNode, AllProps as CreateNodeProps };
