import { defineMessages } from "react-intl";

const messages = defineMessages({
    required: {
        id: "required",
        description: "Required field",
        defaultMessage: "Please input {title}."
    },
    positive: {
        id: "positive",
        description: "Positive field",
        defaultMessage: "Please input value > 0."
    }
});

export default messages;
