import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./projects-messages";
import Utils from "./utils";

import { Alert, Form, Icon, Input, InputNumber, Modal, Radio, Spin, Switch, Tooltip } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { RadioChangeEvent } from "antd/lib/radio";
import organizationService from "../../services/API/organization-service";

import { assign } from "lodash-es";
import { IOrganization, IProject } from "../../store/projects/types";

interface IOwnProps {
    project: IProject;
    visible: boolean;
    loading: boolean;
    handleOk: (ageInYears: boolean) => void;
    handleCancel: () => void;
    error?: string;
}

interface IOwnState {
    buttonsDisabled: boolean;
    stochastic: boolean;
    ageInYears: boolean;
    organizations: IOrganization[];
    loading: boolean;
    error?: string;
}

type AllProps = IOwnProps & FormComponentProps & InjectedIntlProps;

class EditProject extends React.PureComponent<AllProps, IOwnState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            buttonsDisabled: false,
            stochastic: props.project.chloridesBetaStd !== 0,
            ageInYears: !Utils.isDecimal(props.project.age), // decimal number = days, integer = years
            loading: false,
            organizations: [],
            error: undefined
        };
    }

    componentDidMount(): void {
        this.fetchOrganizations();
    }

    fetchOrganizations() {
        this.setState(
            {
                ...this.state,
                loading: true
            },
            () => {
                organizationService
                    .getOrganizations()
                    .then(organizations => {
                        this.setState({ ...this.state, organizations, loading: false });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            organizations: [],
                            error: err.message
                        });
                    });
            }
        );
    }

    onCalculationMethodChange = (e: RadioChangeEvent) => {
        if (e && e.target) {
            const value = e.target.value;
            let stochastic = true;
            if (value === "deterministic") {
                stochastic = false;
            } else if (value === "stochastic") {
                stochastic = true;
            }
            this.setState({
                ...this.state,
                stochastic
            });
        }
    };

    onYearsChange = (checked: boolean, e: Event) => {
        this.setState({ ...this.state, ageInYears: checked });
    };

    public render() {
        const {
            form: { getFieldDecorator, getFieldValue },
            project
        } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const titleFormItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 24, offset: 0 } } };

        const organizationSettings = assign(
            {
                projectNameInitialValue: "DC",
                projectNamePattern: "^DC[0-9]{2}[0-9]{3}$",
                projectNamePatternDescription: "DCYYXXX",
                projectNamePatternExample: "DC23001"
            },
            this.state.organizations[0] && this.state.organizations[0].settings
        );

        const loading = this.props.loading || this.state.loading;
        const error = this.props.error || this.state.error;

        return (
            <Modal
                title={this.props.intl.formatMessage({
                    id: "project.details.edit"
                })}
                visible={this.props.visible}
                width={"80%"}
                onOk={this.props.handleOk.bind(this, this.state.ageInYears)}
                onCancel={this.props.handleCancel}
                okButtonProps={{ disabled: this.state.buttonsDisabled || loading }}
                cancelButtonProps={{ disabled: this.state.buttonsDisabled || loading }}
            >
                <Spin spinning={loading}>
                    <Form layout="horizontal" onKeyPress={this.onKeyPress.bind(this)}>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "project.info" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.name"
                            })}
                        >
                            {getFieldDecorator("name", {
                                initialValue: project.name,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.name.required"
                                        })
                                    },
                                    {
                                        pattern: new RegExp(organizationSettings.projectNamePattern),
                                        message: this.props.intl.formatMessage(
                                            {
                                                id: "project.name.pattern"
                                            },
                                            {
                                                format: organizationSettings.projectNamePatternDescription,
                                                example: organizationSettings.projectNamePatternExample
                                            }
                                        )
                                    }
                                ]
                            })(<Input autoFocus />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.description"
                            })}
                        >
                            {getFieldDecorator("description", {
                                initialValue: project.description,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.description.required"
                                        })
                                    }
                                ]
                            })(<Input.TextArea rows={3} />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.customer"
                            })}
                        >
                            {getFieldDecorator("customer", {
                                initialValue: project.customer,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.customer.required"
                                        })
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.location"
                            })}
                        >
                            {getFieldDecorator("location", {
                                initialValue: project.location,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.location.required"
                                        })
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.age"
                            })}
                        >
                            <Tooltip
                                title={this.props.intl.formatMessage({
                                    id: this.state.ageInYears
                                        ? "project.age.switchYearsTooltip"
                                        : "project.age.switchDaysTooltip"
                                })}
                            >
                                <Switch
                                    checkedChildren={this.props.intl.formatMessage({
                                        id: "project.ageInYears"
                                    })}
                                    unCheckedChildren={this.props.intl.formatMessage({
                                        id: "project.ageInDays"
                                    })}
                                    style={{ marginRight: "8px" }}
                                    defaultChecked={this.state.ageInYears}
                                    onChange={this.onYearsChange}
                                />
                            </Tooltip>
                            {this.state.ageInYears &&
                                getFieldDecorator("ageInYears", {
                                    initialValue: project.age,
                                    rules: [
                                        {
                                            required: true,
                                            message: this.props.intl.formatMessage({
                                                id: "project.age.required"
                                            })
                                        },
                                        {
                                            pattern: /^[1-9]/,
                                            message: this.props.intl.formatMessage({
                                                id: "project.age.pattern"
                                            })
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        min={0}
                                        formatter={value =>
                                            Utils.formatNumberUnit(
                                                value,
                                                this.props.intl.formatMessage({
                                                    id: "years"
                                                })
                                            )
                                        }
                                        parser={value =>
                                            Utils.unFormatNumberUnit(
                                                value,
                                                this.props.intl.formatMessage({
                                                    id: "years"
                                                })
                                            )
                                        }
                                    />
                                )}

                            {!this.state.ageInYears &&
                                getFieldDecorator("ageInDays", {
                                    initialValue: Math.round(project.age * 365),
                                    rules: [
                                        {
                                            required: true,
                                            message: this.props.intl.formatMessage({
                                                id: "project.age.required"
                                            })
                                        },
                                        {
                                            pattern: /^[1-9]/,
                                            message: this.props.intl.formatMessage({
                                                id: "project.age.pattern"
                                            })
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        min={0}
                                        formatter={value =>
                                            Utils.formatNumberUnit(
                                                value,
                                                this.props.intl.formatMessage({
                                                    id: "days"
                                                })
                                            )
                                        }
                                        parser={value =>
                                            Utils.unFormatNumberUnit(
                                                value,
                                                this.props.intl.formatMessage({
                                                    id: "days"
                                                })
                                            )
                                        }
                                    />
                                )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.maxCalculationAge"
                            })}
                        >
                            {getFieldDecorator("maxCalculationAge", {
                                initialValue: project.maxCalculationAge || 100,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.maxCalculationAge.required"
                                        })
                                    },
                                    {
                                        pattern: /^[1-9]/,
                                        message: this.props.intl.formatMessage({
                                            id: "project.maxCalculationAge.pattern"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    max={200}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "years"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "years"
                                            })
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        {this.props.project.zipFile && (
                            <Form.Item
                                {...formItemLayout}
                                label={this.props.intl.formatMessage({
                                    id: "project.file"
                                })}
                            >
                                <span>
                                    {this.props.project.zipFile.substring(
                                        this.props.project.zipFile.lastIndexOf("/") + 1
                                    )}
                                </span>
                            </Form.Item>
                        )}
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "project.concreteCover" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "project.coverdepthThreshold" })}
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "project.coverdepthThreshold.explanation"
                                        })}
                                    >
                                        <Icon type="question-circle" className="formIcon" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("coverdepthThreshold", {
                                initialValue: project.coverdepthThreshold,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.coverdepthThreshold.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "mm"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "mm"
                                            })
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: "project.coverdepthQualityThreshold"
                                    })}
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "project.coverdepthQualityThreshold.explanation"
                                        })}
                                    >
                                        <Icon type="question-circle" className="formIcon" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("coverdepthQualityThreshold", {
                                initialValue: project.coverdepthQualityThreshold,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.coverdepthQualityThreshold.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    max={100}
                                    step={1}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            })
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "project.coverdepthOverlay" })}
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "project.coverdepthOverlay.explanation"
                                        })}
                                    >
                                        <Icon type="question-circle" className="formIcon" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("coverdepthOverlay", {
                                initialValue: project.coverdepthOverlay,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.coverdepthOverlay.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "mm"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "mm"
                                            })
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "project.carbonation" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "project.failureProbability" })}
                                    {" P"}
                                    <sub>f crit CO₂</sub>
                                </span>
                            }
                        >
                            {getFieldDecorator("failureProbability", {
                                initialValue: project.failureProbability,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.failureProbability.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "project.chlorides" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.calculationMethod"
                            })}
                        >
                            {getFieldDecorator("calculationMethod", {
                                initialValue: this.state.stochastic ? "stochastic" : "deterministic"
                            })(
                                <Radio.Group onChange={this.onCalculationMethodChange}>
                                    <Radio value="stochastic">
                                        {this.props.intl.formatMessage({ id: "project.stochastic" })}
                                    </Radio>
                                    <Radio value="deterministic">
                                        {this.props.intl.formatMessage({ id: "project.deterministic" })}
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        {this.state.stochastic && (
                            <Form.Item
                                {...formItemLayout}
                                label={this.props.intl.formatMessage({
                                    id: "project.chloridesBetaStd"
                                })}
                            >
                                {getFieldDecorator("chloridesBetaStd", {
                                    initialValue: project.chloridesBetaStd,
                                    rules: [
                                        {
                                            required: true,
                                            message: this.props.intl.formatMessage({
                                                id: "project.chloridesBetaStd.required"
                                            })
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        min={0}
                                        step={0.01}
                                        formatter={value =>
                                            Utils.formatNumberUnit(
                                                value,
                                                this.props.intl.formatMessage({
                                                    id: "procent"
                                                })
                                            )
                                        }
                                        parser={value =>
                                            Utils.unFormatNumberUnit(
                                                value,
                                                this.props.intl.formatMessage({
                                                    id: "procent"
                                                })
                                            )
                                        }
                                    />
                                )}
                            </Form.Item>
                        )}
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: this.state.stochastic ? "project.chloridesBeta" : "project.chloridesThreshold"
                            })}
                        >
                            {getFieldDecorator("chloridesThreshold", {
                                initialValue: project.chloridesThreshold,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: this.state.stochastic
                                                ? "project.chloridesBeta.required"
                                                : "project.chloridesThreshold.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    max={100}
                                    step={0.1}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            })
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            })
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "project.chloridesAgingFactor"
                            })}
                        >
                            {getFieldDecorator("chloridesAgingFactor", {
                                initialValue: project.chloridesAgingFactor,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.chloridesAgingFactor.required"
                                        })
                                    }
                                ]
                            })(<InputNumber min={0} max={1} step={0.01} />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({
                                        id: "project.failureProbabilityChlorides"
                                    })}
                                    {" P"}
                                    <sub>f crit Cl</sub>
                                </span>
                            }
                        >
                            {getFieldDecorator("failureProbabilityChlorides", {
                                initialValue: project.failureProbabilityChlorides || 0.1,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "project.failureProbabilityChlorides.required"
                                        })
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            {error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={error}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }

    onKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            this.props.handleOk(this.state.ageInYears);
        }
    }
}

const editProjectWrapped = injectIntl(Form.create<AllProps>()(EditProject));

export { editProjectWrapped as EditProject, AllProps as EditProjectProps };
