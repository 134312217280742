import * as React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "../layout/main-layout";

import LoginPage from "../pages/login";
import NotFoundPage from "../pages/notfound";
import Callback from "../pages/callback";

// If your app is big + you have routes with a lot of components, you should consider
// code-splitting your routes! If you bundle stuff up with Webpack, I recommend `react-loadable`.
//
// $ yarn add react-loadable
// $ yarn add --dev @types/react-loadable
//
// The given `pages/` directory provides an example of a directory structure that's easily
// code-splittable.
const routes: React.SFC = () => (
    <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/callback" component={Callback} />
        <Route path="/" component={MainLayout} />
        <Route path="*" component={NotFoundPage} />
    </Switch>
);

export default routes;
