import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import "./editableCell-messages";
import { each, cloneDeep } from "lodash-es";

import { Form, Input } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";

interface IOwnProps {
    editable: boolean;
    dataIndex: string;
    autoFocus: boolean;
    title: string;
    record: any;
    handleCellSave: (record: any) => void;
}

type AllProps = IOwnProps & InjectedIntlProps;

const editableContext = React.createContext<Partial<WrappedFormUtils>>({});

class EditableCell extends React.Component<AllProps> {
    inputRef = React.createRef<Input>();
    form: Partial<WrappedFormUtils> | undefined = undefined;

    constructor(props: AllProps) {
        super(props);
    }

    renderCell = (form: Partial<WrappedFormUtils>) => {
        this.form = form;
        const {
            editable,
            dataIndex,
            autoFocus,
            title,
            record,
            handleCellSave,
            intl,
            children,
            ...restProps
        } = this.props;
        if (editable && dataIndex && this.form && this.form.getFieldDecorator) {
            return (
                <td {...restProps}>
                    <Form.Item style={{ margin: 0 }}>
                        {this.form.getFieldDecorator<any>(record.key + "_" + dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage(
                                        {
                                            id: "required"
                                        },
                                        { title }
                                    )
                                },
                                {
                                    validator: async (rule, value) => {
                                        if (value <= 0) {
                                            throw new Error(
                                                this.props.intl.formatMessage({
                                                    id: "positive"
                                                })
                                            );
                                        }
                                    }
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(
                            <Input
                                ref={this.inputRef}
                                type="number"
                                onPressEnter={this.save.bind(this)}
                                onBlur={this.save.bind(this)}
                                autoFocus={autoFocus && record.key === 0} // focus first cell
                                onFocus={e => e.target.select()}
                            />
                        )}
                    </Form.Item>
                </td>
            );
        } else {
            return <td {...restProps}>{children}</td>;
        }
    };

    save(e: React.SyntheticEvent<HTMLInputElement>) {
        const { record, handleCellSave } = this.props;
        if (this.form && this.form.validateFields) {
            this.form.validateFields((error, values) => {
                if (error && error[e.currentTarget.id]) {
                    // error for current cell, don't handle
                    return;
                }

                const prefix = record.key + "_";

                const updatedRecord = cloneDeep(record);
                each(values, (v, k) => {
                    if (k.startsWith(prefix)) {
                        updatedRecord[k.substr(prefix.length)] = v;
                    }
                });

                // per row
                handleCellSave(updatedRecord);
            });
        }
    }

    render() {
        return <editableContext.Consumer>{this.renderCell}</editableContext.Consumer>;
    }
}

const injectedEditableCell = injectIntl(EditableCell);

export { injectedEditableCell as EditableCell, editableContext as EditableContext };
