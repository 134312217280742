import { isNumber } from "lodash-es";
import * as React from "react";
import Utils from "./utils";

import { InjectedIntlProps, injectIntl } from "react-intl";
import "./node-messages";

import {
    ChlorideStatus,
    INode,
    IProject,
    IProjectTree,
    NodeStatus,
    PropagationLevel
} from "../../store/projects/types";

import { Alert, Col, Icon, Row, Statistic, Tabs, Tag, Tooltip } from "antd";
import Carbonatation from "./carbonatation";
import Chlorides from "./chlorides";
import CoverMeasurements from "./covermeasurements";
import Overview from "./overview";
import SeparationSpace from "./separationspace";

interface IOwnProps {
    node: INode;
    project: IProject;
    tree: IProjectTree;
    expandedNodes: string[];
    dragFiles: boolean;
    onLoading: (loading: boolean) => void;
    onChange: () => void;
    onEditNodes: (tree: IProjectTree) => void;
    onEditNodeClick: (node: INode) => void;
    onErrorFilesFound: (fileNames: string[]) => void;
}

type AllProps = IOwnProps & InjectedIntlProps;

interface IOwnState {
    activeKey: string;
}

class NodeDetails extends React.PureComponent<AllProps, IOwnState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            activeKey: "2"
        };
    }

    componentDidUpdate(prevProps: AllProps) {
        if (prevProps.node.uuid !== this.props.node.uuid) {
            if (this.disableChloridesCarbonatation()) {
                if (this.state.activeKey === "4" || this.state.activeKey === "5") {
                    this.setState({ activeKey: "2" });
                }
            }
        }
    }

    onEditNodeClick() {
        this.props.onEditNodeClick(this.props.node);
    }

    onTabChanged(activeKey: string) {
        this.setState({ activeKey });
    }

    // This code is duplicated
    getManualChlorideWarning(node: INode) {
        if (node.useManualChloridesInput) {
            return this.props.intl.formatMessage({ id: "chlorides.warningManualPropagation" });
        }

        return undefined;
    }

    getNodeWarning(node: INode) {
        if (node.chlorideCalculationStatus) {
            if (
                node.chlorideCalculationStatus === ChlorideStatus.ACCEPTED_D_TIME_ANALYSIS ||
                node.chlorideCalculationStatus === ChlorideStatus.NO_CHLORIDE_CALCULATION_NO_DATA
            ) {
                // OK or no data => no warning
                return this.getManualChlorideWarning(node);
            } else {
                return this.props.intl.formatMessage({ id: "chlorides." + node.chlorideCalculationStatus });
            }
        }

        return this.getManualChlorideWarning(node);
    }

    ColorCodeStyleByValue(value: string | number) {
        if (Number(value) < 0) {
            return { color: "#f00" };
        } else {
            return {};
        }
    }

    disableChloridesCarbonatation() {
        const type = this.props.node.type;
        if (type) {
            // disable if reinforcement
            return this.props.node.type === "REINFORCEMENT";
        } else {
            // don't disable if no type
            return false;
        }
    }

    /*
    selected node:

    node default threshold - aanpasbaar
    failure probability (10%)  (aanpasbaar)
    failure probability chlorides (10%)  (aanpasbaar)
    age (aanpasbaar)

    CALCULATED VALUES
    Estimate
    failure probability (carb)
    failure probability (chloride)
    */
    render() {
        const { node, project, tree, expandedNodes } = this.props;
        const { activeKey } = this.state;

        const nodeWarning = this.getNodeWarning(node);

        // if D is not accepted (-1) show also dash
        const calculatedChlorideLifetimeExpectancy =
            node.calculatedChlorideLifetimeExpectancy === undefined || node.calculatedChlorideLifetimeExpectancy === -1
                ? undefined
                : node.calculatedChlorideLifetimeExpectancy;

        const calculatedRemainingLifetimeExpectancy = isNumber(node.calculatedLifetimeExpectancy)
            ? node.calculatedLifetimeExpectancy - node.propagatedAge!.value
            : "-";

        const calculatedRemainingChlorideLifetimeExpectancy = isNumber(calculatedChlorideLifetimeExpectancy)
            ? calculatedChlorideLifetimeExpectancy - node.propagatedAge!.value
            : "-";

        let labelStyle: React.CSSProperties;
        labelStyle = { fontWeight: 500, paddingLeft: "20px", paddingRight: "4px" };

        let calculatedStyle: React.CSSProperties;
        calculatedStyle = { paddingLeft: "16px", paddingRight: "4px" };

        const propagatedThreshold = node.threshold ? (
            <div>{Utils.formatNumberUnit(node.threshold, this.props.intl.formatMessage({ id: "mm" }))}</div>
        ) : node.propagatedThreshold ? (
            <div style={{ color: "#bbb" }}>
                {Utils.formatNumberUnit(
                    node.propagatedThreshold.value,
                    `${this.props.intl.formatMessage({
                        id: "mm"
                    })} (${
                        node.propagatedThreshold.sourceLevel === PropagationLevel.PROJECT
                            ? this.props.intl.formatMessage({ id: "project" })
                            : node.propagatedThreshold.sourceName
                    })`
                )}
            </div>
        ) : (
            <div />
        );

        const propagatedCoverdepthQualityThreshold = node.coverdepthQualityThreshold ? (
            <div>
                {Utils.formatNumberUnit(
                    node.coverdepthQualityThreshold,
                    this.props.intl.formatMessage({ id: "procent" })
                )}
            </div>
        ) : node.propagatedCoverdepthQualityThreshold ? (
            <div style={{ color: "#bbb" }}>
                {Utils.formatNumberUnit(
                    node.propagatedCoverdepthQualityThreshold.value,
                    `${this.props.intl.formatMessage({
                        id: "procent"
                    })} (${
                        node.propagatedCoverdepthQualityThreshold.sourceLevel === PropagationLevel.PROJECT
                            ? this.props.intl.formatMessage({ id: "project" })
                            : node.propagatedCoverdepthQualityThreshold.sourceName
                    })`
                )}
            </div>
        ) : (
            <div />
        );

        const propagatedCoverdepthOverlay = node.coverdepthOverlay ? (
            <div>{Utils.formatNumberUnit(node.coverdepthOverlay, this.props.intl.formatMessage({ id: "mm" }))}</div>
        ) : node.propagatedCoverdepthOverlay ? (
            <div style={{ color: "#bbb" }}>
                {Utils.formatNumberUnit(
                    node.propagatedCoverdepthOverlay.value,
                    `${this.props.intl.formatMessage({
                        id: "mm"
                    })} (${
                        node.propagatedCoverdepthOverlay.sourceLevel === PropagationLevel.PROJECT
                            ? this.props.intl.formatMessage({ id: "project" })
                            : node.propagatedCoverdepthOverlay.sourceName
                    })`
                )}
            </div>
        ) : (
            <div />
        );

        const propagatedFailureProbability = node.failureProbability ? (
            <div>
                {Utils.formatNumberUnit(node.failureProbability, this.props.intl.formatMessage({ id: "procent" }), 100)}
            </div>
        ) : node.propagatedFailureProbability ? (
            <div style={{ color: "#bbb" }}>
                {Utils.formatNumberUnit(
                    node.propagatedFailureProbability.value,
                    `${this.props.intl.formatMessage({
                        id: "procent"
                    })} (${
                        node.propagatedFailureProbability.sourceLevel === PropagationLevel.PROJECT
                            ? this.props.intl.formatMessage({ id: "project" })
                            : node.propagatedFailureProbability.sourceName
                    })`,
                    100
                )}
            </div>
        ) : (
            <div />
        );

        const propagatedFailureProbabilityChlorides = node.failureProbabilityChlorides ? (
            <div>
                {Utils.formatNumberUnit(
                    node.failureProbabilityChlorides,
                    this.props.intl.formatMessage({ id: "procent" }),
                    100
                )}
            </div>
        ) : node.propagatedFailureProbabilityChlorides ? (
            <div style={{ color: "#bbb" }}>
                {Utils.formatNumberUnit(
                    node.propagatedFailureProbabilityChlorides.value,
                    `${this.props.intl.formatMessage({
                        id: "procent"
                    })} (${
                        node.propagatedFailureProbabilityChlorides.sourceLevel === PropagationLevel.PROJECT
                            ? this.props.intl.formatMessage({ id: "project" })
                            : node.propagatedFailureProbabilityChlorides.sourceName
                    })`,
                    100
                )}
            </div>
        ) : (
            <div />
        );

        const propagatedChloridesAgingFactor = node.chloridesAgingFactor ? (
            <div>{node.chloridesAgingFactor}</div>
        ) : node.propagatedChloridesAgingFactor ? (
            <div style={{ color: "#bbb" }}>
                {node.propagatedChloridesAgingFactor.value} ({node.propagatedChloridesAgingFactor.sourceLevel ===
                PropagationLevel.PROJECT
                    ? this.props.intl.formatMessage({ id: "project" })
                    : node.propagatedChloridesAgingFactor.sourceName})
            </div>
        ) : (
            <div />
        );

        let nodeDetailsStyle: React.CSSProperties = {};
        if (node.status === NodeStatus.DISABLED) {
            nodeDetailsStyle = { ...nodeDetailsStyle, opacity: "0.4" };
        }

        const nodeError = Utils.getNodeError(node);

        return (
            <div style={nodeDetailsStyle}>
                <h2 style={{ display: "inline-block", marginBottom: "4px" }}>{node.name}</h2>
                {node.status !== NodeStatus.DISABLED && (
                    <Icon
                        type="edit"
                        onClick={this.onEditNodeClick.bind(this)}
                        style={{ marginLeft: "10px", fontSize: "1.3em" }}
                    />
                )}
                <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1 }}>
                        <div style={{ float: "left" }}>
                            <Col>
                                <Row style={labelStyle}>
                                    <span>
                                        {this.props.intl.formatMessage({ id: "node.failureProbability" })}
                                        {" P"}
                                        <sub>f crit CO₂</sub>
                                    </span>:
                                </Row>
                                <Row style={labelStyle}>
                                    <span>
                                        {this.props.intl.formatMessage({
                                            id: "node.failureProbabilityChlorides"
                                        })}
                                        {" P"}
                                        <sub>f crit Cl</sub>
                                    </span>:
                                </Row>
                                <Row style={labelStyle}>
                                    {this.props.intl.formatMessage({ id: "node.chloridesAgingFactor" })}:
                                </Row>
                            </Col>
                        </div>
                        <div style={{ float: "left" }}>
                            <Col>
                                <Row>{propagatedFailureProbability}</Row>
                                <Row>{propagatedFailureProbabilityChlorides}</Row>
                                <Row>{propagatedChloridesAgingFactor}</Row>
                            </Col>
                        </div>
                        <div style={{ float: "left" }}>
                            <Col>
                                <Row style={labelStyle}>
                                    {this.props.intl.formatMessage({ id: "node.coverdepthThreshold" })}:
                                </Row>
                                <Row style={labelStyle}>
                                    {this.props.intl.formatMessage({ id: "node.coverdepthQualityThreshold" })}:
                                </Row>
                                <Row style={labelStyle}>
                                    {this.props.intl.formatMessage({ id: "node.coverdepthOverlay" })}:
                                </Row>
                            </Col>
                        </div>
                        <div style={{ float: "left" }}>
                            <Col>
                                <Row>{propagatedThreshold}</Row>
                                <Row>{propagatedCoverdepthQualityThreshold}</Row>
                                <Row>{propagatedCoverdepthOverlay}</Row>
                            </Col>
                        </div>
                    </div>
                    <Row type="flex" style={{ textAlign: "right" }}>
                        <Col style={calculatedStyle}>
                            <Statistic
                                title={
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "node.calculatedLifetimeExpectancyCarbonation"
                                        })}
                                    >
                                        <span>
                                            t<sub>SL,CO₂</sub>
                                        </span>
                                    </Tooltip>
                                }
                                suffix={Utils.formatUnit(
                                    node.calculatedLifetimeExpectancy,
                                    this.props.intl.formatMessage({ id: "years" })
                                )}
                                value={
                                    node.status !== NodeStatus.DISABLED
                                        ? Utils.renderCalculatedLifetimeExpectancy(node, project, "—")
                                        : "—" // special character to not show -0
                                }
                                valueStyle={Utils.isDefined(node.calculatedLifetimeExpectancy) ? {} : { color: "#bbb" }}
                            />
                        </Col>
                        <Col style={calculatedStyle}>
                            <Statistic
                                title={
                                    <Tooltip
                                        title={this.props.intl.formatHTMLMessage({
                                            id: "node.calculatedRemainingLifetimeExpectancyCarbonation"
                                        })}
                                    >
                                        <span>
                                            t<sub>SL,REM,CO₂</sub>
                                        </span>
                                    </Tooltip>
                                }
                                suffix={Utils.formatUnit(
                                    node.calculatedLifetimeExpectancy,
                                    this.props.intl.formatMessage({ id: "years" })
                                )}
                                value={
                                    node.status !== NodeStatus.DISABLED
                                        ? Utils.renderCalculatedRemainingLifetimeExpectancy(
                                              node,
                                              this.props.project,
                                              "—"
                                          )
                                        : "—"
                                }
                                valueStyle={
                                    Utils.isDefined(node.calculatedLifetimeExpectancy)
                                        ? this.ColorCodeStyleByValue(calculatedRemainingLifetimeExpectancy)
                                        : { color: "#bbb" }
                                }
                            />
                        </Col>
                        <Col style={calculatedStyle}>
                            <Statistic
                                title={
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "node.calculatedFailureProbabilityCarbonation"
                                        })}
                                    >
                                        <span>
                                            P<sub>f CO₂</sub>
                                        </span>
                                    </Tooltip>
                                }
                                suffix={Utils.formatUnit(
                                    node.calculatedCarbonatationFailureProbability,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    })
                                )}
                                value={
                                    node.status !== NodeStatus.DISABLED &&
                                    node.calculatedCarbonatationFailureProbability !== null &&
                                    node.calculatedCarbonatationFailureProbability !== undefined
                                        ? Utils.formatNumber(100 * node.calculatedCarbonatationFailureProbability, 0)
                                        : "—"
                                }
                                valueStyle={
                                    Utils.isDefined(node.calculatedCarbonatationFailureProbability)
                                        ? {}
                                        : { color: "#bbb" }
                                }
                            />
                        </Col>
                        <Col style={{ width: 30 }} />
                        <Col style={calculatedStyle}>
                            <Statistic
                                title={
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "node.calculatedLifetimeExpectancyChlorides"
                                        })}
                                    >
                                        <span>
                                            t<sub>SL,Cl</sub>
                                        </span>
                                    </Tooltip>
                                }
                                suffix={Utils.formatUnit(
                                    calculatedChlorideLifetimeExpectancy,
                                    this.props.intl.formatMessage({ id: "years" })
                                )}
                                value={
                                    node.status !== NodeStatus.DISABLED
                                        ? Utils.renderCalculatedChlorideLifetimeExpectancy(
                                              node,
                                              this.props.project,
                                              "—"
                                          )
                                        : "—"
                                }
                                valueStyle={
                                    Utils.isDefined(calculatedChlorideLifetimeExpectancy) ? {} : { color: "#bbb" }
                                }
                            />
                        </Col>
                        <Col style={calculatedStyle}>
                            <Statistic
                                title={
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "node.calculatedRemainingLifetimeExpectancyChlorides"
                                        })}
                                    >
                                        <span>
                                            t<sub>SL,REM,Cl</sub>
                                        </span>
                                    </Tooltip>
                                }
                                suffix={Utils.formatUnit(
                                    calculatedChlorideLifetimeExpectancy,
                                    this.props.intl.formatMessage({ id: "years" })
                                )}
                                value={
                                    node.status !== NodeStatus.DISABLED
                                        ? Utils.renderCalculatedRemainingChlorideLifetimeExpectancy(
                                              node,
                                              this.props.project,
                                              "—"
                                          )
                                        : "—"
                                }
                                valueStyle={
                                    Utils.isDefined(calculatedChlorideLifetimeExpectancy)
                                        ? this.ColorCodeStyleByValue(calculatedRemainingChlorideLifetimeExpectancy)
                                        : { color: "#bbb" }
                                }
                            />
                        </Col>
                        <Col style={calculatedStyle}>
                            <Statistic
                                title={
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "node.calculatedFailureProbabilityChlorides"
                                        })}
                                    >
                                        <span>
                                            P<sub>f Cl</sub>
                                        </span>
                                    </Tooltip>
                                }
                                suffix={Utils.formatUnit(
                                    node.calculatedChlorideFailureProbability,
                                    this.props.intl.formatMessage({
                                        id: "procent"
                                    })
                                )}
                                value={
                                    node.status !== NodeStatus.DISABLED &&
                                    node.calculatedChlorideFailureProbability !== null &&
                                    node.calculatedChlorideFailureProbability !== undefined
                                        ? Utils.formatNumber(100 * node.calculatedChlorideFailureProbability, 0)
                                        : "—"
                                }
                                valueStyle={
                                    Utils.isDefined(node.calculatedChlorideFailureProbability) ? {} : { color: "#bbb" }
                                }
                            />
                        </Col>
                    </Row>
                </div>
                {nodeError && (
                    <div style={{ marginTop: "8px" }}>
                        <Alert
                            message={this.props.intl.formatMessage({
                                id: "node." + nodeError.type
                            })}
                            description={nodeError.message}
                            type={nodeError.type}
                            showIcon
                        />
                    </div>
                )}
                <Tabs activeKey={activeKey} animated={false} onChange={this.onTabChanged.bind(this)}>
                    <Tabs.TabPane tab={this.props.intl.formatMessage({ id: "node.overview" })} key="1">
                        <Overview project={project} currentNode={node} expandedNodes={expandedNodes} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <div>
                                {this.props.intl.formatMessage({ id: "node.covermeasurements" })}
                                {node.useManualInput &&
                                    node.covermeasurementsManualInput && (
                                        <Icon type="user" style={{ marginLeft: "4px" }} />
                                    )}
                            </div>
                        }
                        key="2"
                    >
                        <CoverMeasurements
                            projectId={project._id}
                            currentNode={node}
                            tree={tree}
                            dragFiles={this.props.dragFiles}
                            onEditNodes={this.props.onEditNodes.bind(this)}
                            onErrorFilesFound={this.props.onErrorFilesFound}
                            onChange={this.props.onChange.bind(this)}
                            onLoading={this.props.onLoading.bind(this)}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={this.props.intl.formatMessage({ id: "node.separationSpace" })} key="3">
                        <SeparationSpace projectId={project._id} currentNode={node} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={this.props.intl.formatMessage({
                            id: "node.carbonatation"
                        })}
                        key="4"
                        disabled={this.disableChloridesCarbonatation()}
                    >
                        {
                            <Carbonatation
                                projectId={project._id}
                                tree={tree}
                                currentNode={node}
                                onEditNodes={this.props.onEditNodes.bind(this)}
                                onLoading={this.props.onLoading.bind(this)}
                            />
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <div>
                                {this.props.intl.formatMessage({ id: "node.chlorides" })}
                                {nodeWarning && (
                                    <Tag style={{ marginLeft: "4px" }} color="gold">
                                        !
                                    </Tag>
                                )}
                            </div>
                        }
                        key="5"
                        disabled={this.disableChloridesCarbonatation()}
                    >
                        <Chlorides
                            projectId={project._id}
                            tree={tree}
                            currentNode={node}
                            onEditNodes={this.props.onEditNodes.bind(this)}
                            onLoading={this.props.onLoading.bind(this)}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}

export default injectIntl(NodeDetails);
