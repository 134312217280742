import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import "./node-messages";
import Utils from "./utils";

import { Alert, Form, Icon, Input, InputNumber, Modal, Spin, Tooltip } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

import { INode } from "../../store/projects/types";

interface IOwnProps {
    node: INode;
    visible: boolean;
    loading: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    error?: string;
}

type AllProps = IOwnProps & FormComponentProps & InjectedIntlProps;

class EditNode extends React.PureComponent<AllProps> {
    constructor(props: AllProps) {
        super(props);
    }

    render() {
        const {
            form: { getFieldDecorator },
            node
        } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const titleFormItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 24, offset: 0 } } };

        return (
            <Modal
                title={this.props.intl.formatMessage({ id: "node.edit.title" }, { name: node.name })}
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                okButtonProps={{ disabled: this.props.loading }}
                cancelButtonProps={{ disabled: this.props.loading }}
                width={"50%"}
            >
                <Spin spinning={this.props.loading}>
                    <Form layout="horizontal" onKeyPress={this.onKeyPress.bind(this)}>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "node.info" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "node.name"
                            })}
                        >
                            {getFieldDecorator("name", {
                                initialValue: node.name,
                                rules: [
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage({
                                            id: "node.name.required"
                                        })
                                    }
                                ]
                            })(<Input autoFocus onFocus={e => e.target.select()} />)}
                        </Form.Item>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "node.concreteCover" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "node.coverdepthThreshold" })}
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "project.coverdepthThreshold.explanation"
                                        })}
                                    >
                                        <Icon type="question-circle" className="formIcon" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("threshold", {
                                initialValue: node.threshold
                            })(<InputNumber min={0} />)}
                            <span style={{ marginLeft: "4px" }}>{this.props.intl.formatMessage({ id: "mm" })}</span>
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "node.coverdepthQualityThreshold" })}
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "project.coverdepthQualityThreshold.explanation"
                                        })}
                                    >
                                        <Icon type="question-circle" className="formIcon" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("coverdepthQualityThreshold", {
                                initialValue: node.coverdepthQualityThreshold
                            })(<InputNumber min={0} max={100} step={1} />)}
                            <span style={{ marginLeft: "4px" }}>
                                {this.props.intl.formatMessage({ id: "procent" })}
                            </span>
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "node.coverdepthOverlay" })}
                                    <Tooltip
                                        title={this.props.intl.formatMessage({
                                            id: "project.coverdepthOverlay.explanation"
                                        })}
                                    >
                                        <Icon type="question-circle" className="formIcon" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator("coverdepthOverlay", {
                                initialValue: node.coverdepthOverlay
                            })(<InputNumber min={0} />)}
                            <span style={{ marginLeft: "4px" }}>{this.props.intl.formatMessage({ id: "mm" })}</span>
                        </Form.Item>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "node.carbonatation" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "node.failureProbability" })}
                                    {" P"}
                                    <sub>f crit CO₂</sub>
                                </span>
                            }
                        >
                            {getFieldDecorator("failureProbability", {
                                initialValue: node.failureProbability
                            })(
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            className="formTitle"
                            {...titleFormItemLayout}
                            label={
                                <span className="formTitleLabel">
                                    {this.props.intl.formatMessage({ id: "node.chlorides" })}
                                </span>
                            }
                            colon={false}
                        />
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    {this.props.intl.formatMessage({ id: "node.failureProbabilityChlorides" })}
                                    {" P"}
                                    <sub>f crit Cl</sub>
                                </span>
                            }
                        >
                            {getFieldDecorator("failureProbabilityChlorides", {
                                initialValue: node.failureProbabilityChlorides
                            })(
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    formatter={value =>
                                        Utils.formatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                    parser={value =>
                                        Utils.unFormatNumberUnit(
                                            value,
                                            this.props.intl.formatMessage({
                                                id: "procent"
                                            }),
                                            100
                                        )
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: "node.chloridesAgingFactor"
                            })}
                        >
                            {getFieldDecorator("chloridesAgingFactor", {
                                initialValue: node.chloridesAgingFactor
                            })(<InputNumber min={0} max={1} step={0.01} />)}
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            {this.props.error && (
                                <Alert
                                    message={this.props.intl.formatMessage({
                                        id: "error"
                                    })}
                                    description={this.props.error}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }

    onKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            this.props.handleOk();
        }
    }
}

const editNodeWrapped = injectIntl(Form.create<AllProps>()(EditNode));

export { editNodeWrapped as EditNode, AllProps as EditNodeProps };
