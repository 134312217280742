import {
    ICoverMeasurement,
    ICoverMeasurementManualInput,
    ICreateCoverMeasurementManualInput
} from "../../store/projects/types";
import api from "./api";

class CovermeasurementService {
    async updateCovermeasurement(coverMeasurement: ICoverMeasurement, projectId: string): Promise<ICoverMeasurement[]> {
        const response = await api.put(
            "/covermeasurement/" + coverMeasurement._id + "?project=" + projectId,
            coverMeasurement
        );

        if (response && response.data) {
            if (response.data.response && response.data.response.items) {
                return response.data.response.items;
            } else {
                return [];
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while updating covermeasurement with id " + coverMeasurement._id);
    }
    async getCovermeasurements(projectId: string, nodeId: string): Promise<ICoverMeasurement[]> {
        const response = await api.get(
            "/covermeasurements?project=" + projectId + "&nodeId=" + nodeId + "&includeOwn=true"
        );

        if (response && response.data) {
            if (response.data.response && response.data.response.items) {
                return response.data.response.items;
            } else {
                return [];
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while getting covermeasurements for project and  id " + projectId + ", " + nodeId);
    }

    async generateCoverMeasurementGraph(projectId: string, coverMeasurementId: string): Promise<ICoverMeasurement> {
        const response = await api.get("/covermeasurement/" + coverMeasurementId + "/graph?project=" + projectId);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while generating graph for covermeasurement " + coverMeasurementId);
    }

    async getManualInputCovermeasurement(manualInputID: string): Promise<ICoverMeasurementManualInput> {
        const response = await api.get("/covermeasurements_manual_input/" + manualInputID);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while getting manual input covermeasurements for id " + manualInputID);
    }

    async createManualInputCovermeasurement(
        coverMeasurementManualInput: ICreateCoverMeasurementManualInput
    ): Promise<ICoverMeasurementManualInput> {
        const response = await api.post("/covermeasurements_manual_input", coverMeasurementManualInput);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while creating manual input covermeasurement");
    }

    async deleteManualInputCovermeasurement(manualInputID: string): Promise<ICoverMeasurementManualInput> {
        const response = await api.delete("/covermeasurements_manual_input/" + manualInputID);

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error("Error while deleting manual input covermeasurements for id " + manualInputID);
    }

    async updateManualInputCovermeasurement(
        coverMeasurementManualInput: ICoverMeasurementManualInput
    ): Promise<ICoverMeasurementManualInput> {
        const response = await api.put(
            "/covermeasurements_manual_input/" + coverMeasurementManualInput._id,
            coverMeasurementManualInput
        );

        if (response && response.data) {
            if (response.data.response) {
                return response.data.response;
            }
        }

        // TODO how to do server error handling?
        throw new Error(
            "Error while updating manual input covermeasurements for id " + coverMeasurementManualInput._id
        );
    }
}

export default new CovermeasurementService();
