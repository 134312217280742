import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider, connect } from "react-redux";
import { Store } from "redux";

import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import "./main-messages";

import translations from "./translations/locales";

import Routes from "./routes/routes";
import { IApplicationState } from "./store";

interface IOwnProps {
    store: Store<IApplicationState>;
    history: History;
}
interface IOwnState {
    locale: "en" | "nl";
}

function getInitialLocale(): "en" | "nl" {
    /*switch (getBrowserLang()) {
        case "nl-BE":
        case "nl-NL":
        case "nl": {
            return "nl";
        }
        case "en-US":
        case "en-GB":
        case "en":
        default: {
            return "en";
        }
    }*/
    return "en";
}

// Any additional component props go here.

function getBrowserLang() {
    if (navigator.languages !== undefined) {
        return navigator.languages[0];
    } else {
        return navigator.language;
    }
}

// const locale = getInitialLocale();

// Create an intersection type of the component props and our Redux props.
type AllProps = IOwnProps;

class Main extends React.PureComponent<AllProps, IOwnState> {
    constructor(props: AllProps) {
        super(props);
        const initialLocale = getInitialLocale();
        this.state = {
            locale: initialLocale
        };
    }

    public render() {
        const { store, history } = this.props;
        const { locale } = this.state;

        const messages = (translations.messages as any)[locale];
        const antdlocale = (translations.antdlocale as any)[locale];

        return (
            <Provider store={store}>
                <LocaleProvider locale={antdlocale}>
                    <IntlProvider locale={locale} key={locale} messages={messages}>
                        <DndProvider backend={HTML5Backend}>
                            <ConnectedRouter history={history}>
                                <Routes />
                            </ConnectedRouter>
                        </DndProvider>
                    </IntlProvider>
                </LocaleProvider>
            </Provider>
        );
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = (state: IApplicationState) => ({});

// Normally you wouldn't need any generics here (since types infer from the passed functions).
// But since we pass some props from the `index.js` file, we have to include them.
// For an example of a `connect` function without generics, see `./containers/LayoutContainer`.
export default connect(mapStateToProps)(Main);
